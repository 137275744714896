import './style.css'

type Props = {
  container: string
  className: string
  className1: string
  className2: string
  className3: string
  description: string
  number: string
  url: string
}

const DashboardSmallCards = ({
  container,
  className,
  className1,
  className2,
  className3,
  description,
  number,
  url,
}: Props) => (
  <div
    className={`card card-md-stretch mb-5 shadow-custom  ${className1}`}
    style={{fontSize: '24px'}}
  >
    <div className='card-body px-4 py-4 rounded'>
      <div className='d-flex justify-content-start align-items-center'>
        <div className='d-flex me-4 '>
          <div className='symbol symbol-40px symbol-circle'>
            <div className='symbol-label'>
              {' '}
              <i className={`${url} ${className}`} style={{fontSize: '25px'}}></i>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column'>
          <span className={`pt-1 fw-semibold fs-7  ${className2}`}>{description}</span>
          <span className={`fw-bold me-2 fs-1  ${className3}`}>{number}</span>
        </div>
      </div>
    </div>
  </div>
)
export {DashboardSmallCards}
