import axios from 'axios'
import {useEffect, useState} from 'react'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {useNavigate, useParams} from 'react-router-dom'

export function DeactivateServiceTypes() {
  const navigate = useNavigate()
  const {id} = useParams()
  const [serviceTypesUsages, setServiceTypesUsages] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  console.log('id', id)

  useEffect(() => {
    if (id) {
      fetchDeactivateServiceTypes()
    }
  }, [id])

  const fetchDeactivateServiceTypes = async () => {
    try {
      setIsLoading(true)
      const checkServiceTypeInUsed = await axios.get(
        `${process.env.REACT_APP_API_URL}/master/check-serviceType-disabled?code=${id}`
      )
      if (checkServiceTypeInUsed.data) {
        setServiceTypesUsages(checkServiceTypeInUsed.data)
      } else {
        navigate('/apps/service-type/all')
      }
    } catch (e) {
      console.log('error', e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Deactivation Failed
        </h1>

        <p className='text-gray-500 fs-5'>
          The following list displays entities linked to the selected service type, which prevents
          successful deactivation
        </p>
      </div>
      <div className='card mb-5'>
        <div className='mt-4 mb-4 py-5 px-8'>
          <div className='card mb-5'>
            <div className='accordion' id='kt_accordion_1'>
              {serviceTypesUsages?.appoinments?.length > 0 && (
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                    <button
                      className='accordion-button fs-6 fw-semibold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_1_body_1'
                      aria-expanded='false'
                      aria-controls='kt_accordion_1_body_1'
                    >
                      <div className='editable-title-container'>
                        <div className='d-flex align-items-center '>
                          <div className='d-flex'>
                            {' '}
                            <h1 className='fs-6 fw-semibold me-3 mb-0'>
                              Appoinments ({serviceTypesUsages?.appoinments?.length})
                            </h1>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id='kt_accordion_1_body_1'
                    className='accordion-collapse collapse'
                    aria-labelledby='kt_accordion_1_header_1'
                    data-bs-parent='#kt_accordion_1'
                  >
                    <div className='accordion-body'>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                              <thead>
                                <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Appoinment No</th>
                                  <th className='min-w-100px'>Requester Name</th>
                                  <th className='min-w-100px text-end'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {serviceTypesUsages?.appoinments?.map(
                                  (item: any, index: number) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-start flex-column'>
                                            <a
                                              href='#'
                                              className='text-gray-800 text-hover-primary mb-1'
                                            >
                                              {item?.code ?? 'N/A'}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-center align-items-center flex-row'>
                                            {item.requeterImage ? (
                                              <div className='symbol symbol-25px me-5 rounded-circle'>
                                                <img
                                                  src={`${process.env.REACT_APP_IMG_URL}/profile/${item.requeterImage}`}
                                                  alt=''
                                                  className='rounded-circle'
                                                />
                                              </div>
                                            ) : (
                                              <div className='symbol symbol-circle symbol-25px overflow-hidden me-5'>
                                                <div className='symbol-label fs-4 badge-light-success bg-light-success'>
                                                  <span style={{fontSize: '12px'}}>
                                                    {(item?.requesterName ?? 'N/A')
                                                      .slice(0, 2)
                                                      .toUpperCase()}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                            <a
                                              href='#'
                                              className='text-gray-800 text-hover-primary mb-1'
                                            >
                                              {item?.requesterName ?? 'N/A'}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <i
                                          className='bi bi-box-arrow-up-right'
                                          onClick={() => {
                                            navigate(`/appointmentViewer/${item?.code}`)
                                          }}
                                          style={{cursor: 'pointer'}}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {serviceTypesUsages?.requesters?.length > 0 && (
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                    <button
                      className='accordion-button fs-6 fw-semibold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_1_body_2'
                      aria-expanded='false'
                      aria-controls='kt_accordion_1_body_2'
                    >
                      <div className='editable-title-container'>
                        <div className='d-flex align-items-center '>
                          <div className='d-flex'>
                            {' '}
                            <h1 className='fs-6 fw-semibold me-3 mb-0'>
                              Requesters ({serviceTypesUsages?.requesters?.length})
                            </h1>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id='kt_accordion_1_body_2'
                    className='accordion-collapse collapse'
                    aria-labelledby='kt_accordion_1_header_2'
                    data-bs-parent='#kt_accordion_1'
                  >
                    <div className='accordion-body'>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                              <thead>
                                <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Name</th>
                                  <th className='min-w-100px text-end'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {serviceTypesUsages?.requesters?.map((item: any, index: number) => (
                                  <tr>
                                    <td>
                                      <div className='d-flex align-items-center'>
                                        <div className='d-flex justify-content-center align-items-center flex-row'>
                                          {item.profileImage ? (
                                            <div className='symbol symbol-25px me-5 rounded-circle'>
                                              <img
                                                src={`${process.env.REACT_APP_IMG_URL}/profile/${item.profileImage}`}
                                                alt=''
                                                className='rounded-circle'
                                              />
                                            </div>
                                          ) : (
                                            <div className='symbol symbol-circle symbol-25px overflow-hidden me-5'>
                                              <div className='symbol-label fs-4 badge-light-success bg-light-success'>
                                                <span style={{fontSize: '12px'}}>
                                                  {(item?.name ?? 'N/A').slice(0, 2).toUpperCase()}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          <a
                                            href='#'
                                            className='text-gray-800 text-hover-primary mb-1'
                                          >
                                            {item?.name ?? 'N/A'}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td className='text-end'>
                                      <i
                                        className='bi bi-box-arrow-up-right'
                                        onClick={() => {
                                          navigate(`/profile/${item?.code}`)
                                        }}
                                        style={{cursor: 'pointer'}}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {serviceTypesUsages?.customers?.length > 0 && (
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                    <button
                      className='accordion-button fs-6 fw-semibold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_1_body_3'
                      aria-expanded='false'
                      aria-controls='kt_accordion_1_body_3'
                    >
                      <div className='editable-title-container'>
                        <div className='d-flex align-items-center '>
                          <div className='d-flex'>
                            {' '}
                            <h1 className='fs-6 fw-semibold me-3 mb-0'>
                              Customers ({serviceTypesUsages?.customers?.length})
                            </h1>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id='kt_accordion_1_body_3'
                    className='accordion-collapse collapse'
                    aria-labelledby='kt_accordion_1_header_3'
                    data-bs-parent='#kt_accordion_1'
                  >
                    <div className='accordion-body'>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                              <thead>
                                <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Name</th>
                                  <th className='min-w-100px text-end'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {serviceTypesUsages?.customers?.map((item: any, index: number) => (
                                  <tr>
                                    <td>
                                      <div className='d-flex align-items-center'>
                                        <div className='d-flex justify-content-center align-items-center flex-row'>
                                          {item.profileImage ? (
                                            <div className='symbol symbol-25px me-5 rounded-circle'>
                                              <img
                                                src={`${process.env.REACT_APP_IMG_URL}/customer/${item.profileImage}`}
                                                alt=''
                                                className='rounded-circle'
                                              />
                                            </div>
                                          ) : (
                                            <div className='symbol symbol-circle symbol-25px overflow-hidden me-5'>
                                              <div className='symbol-label fs-4 badge-light-success bg-light-success'>
                                                <span style={{fontSize: '12px'}}>
                                                  {(item?.name ?? 'N/A').slice(0, 2).toUpperCase()}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          <a
                                            href='#'
                                            className='text-gray-800 text-hover-primary mb-1'
                                          >
                                            {item?.name ?? 'N/A'}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td className='text-end'>
                                      <i
                                        className='bi bi-box-arrow-up-right'
                                        onClick={() => {
                                          navigate(`/customers/${item?.code}`)
                                        }}
                                        style={{cursor: 'pointer'}}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {serviceTypesUsages?.interpreters?.length > 0 && (
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='kt_accordion_1_header_4'>
                    <button
                      className='accordion-button fs-6 fw-semibold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_accordion_1_body_4'
                      aria-expanded='false'
                      aria-controls='kt_accordion_1_body_4'
                    >
                      <div className='editable-title-container'>
                        <div className='d-flex align-items-center '>
                          <div className='d-flex'>
                            {' '}
                            <h1 className='fs-6 fw-semibold me-3 mb-0'>
                              Interpreters ({serviceTypesUsages?.interpreters?.length})
                            </h1>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id='kt_accordion_1_body_4'
                    className='accordion-collapse collapse'
                    aria-labelledby='kt_accordion_1_header_4'
                    data-bs-parent='#kt_accordion_1'
                  >
                    <div className='accordion-body'>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                              <thead>
                                <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Name</th>
                                  <th className='min-w-100px text-end'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {serviceTypesUsages?.interpreters?.map(
                                  (item: any, index: number) => (
                                    <tr>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex justify-content-center align-items-center flex-row'>
                                            {item.profileImage ? (
                                              <div className='symbol symbol-25px me-5 rounded-circle'>
                                                <img
                                                  src={`${process.env.REACT_APP_IMG_URL}/profile/${item.profileImage}`}
                                                  alt=''
                                                  className='rounded-circle'
                                                />
                                              </div>
                                            ) : (
                                              <div className='symbol symbol-circle symbol-25px overflow-hidden me-5'>
                                                <div className='symbol-label fs-4 badge-light-success bg-light-success'>
                                                  <span style={{fontSize: '12px'}}>
                                                    {(item?.name ?? 'N/A')
                                                      .slice(0, 2)
                                                      .toUpperCase()}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                            <a
                                              href='#'
                                              className='text-gray-800 text-hover-primary mb-1'
                                            >
                                              {item?.name ?? 'N/A'}
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <i
                                          className='bi bi-box-arrow-up-right'
                                          onClick={() => {
                                            navigate(`/profile/${item?.code}`)
                                          }}
                                          style={{cursor: 'pointer'}}
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!serviceTypesUsages && (
          <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
            <div className='text-center'>
              <div className='symbol symbol-200px '>
                <img src='/media/other/nodata.png' alt='' />
              </div>
            </div>
            <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
              No matching records found
            </div>
          </div>
        )}
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}
