import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../members-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'
import {Form, Formik} from 'formik'
import { RootState } from '../../../redux/store'
import { useSelector } from 'react-redux'

const API_URL = process.env.REACT_APP_API_URL
//const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
//const GET_ALL_SERVICETYPES = `${API_URL}/master/getall/SERVICE_TYPE`
//const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`
//const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  setAllAppointments: any
  setCurrentPage: any
  setFilterData: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
}
export function AppointmentViewTableFilterDropdown({
  setAllAppointments,
  setCurrentPage,
  setFilterData,
  setTotalPages,
}: Props) {
  const {currentUser} = useAuth()

  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState<any>({
    search: '',
    id: 0,
  })
  const [currentPage, setpage] = useState(1)
  const {items: responseCustomer, loading: isFetchingCustomer} = useSelector(
    (state: RootState) => state.customers
  )
  const {items: responseLanguage, loading: isFetchingLanguage} = useSelector(
    (state: RootState) => state.languages
  )
  const {items: responseServicetypes, loading: isFetchingServicetypes} = useSelector(
    (state: RootState) => state.servicetypes
  )
  const {items: responseCommunicationtypes, loading: isFetchingCommunicationtypes} = useSelector(
    (state: RootState) => state.communicationtypes
  )
  // const getCustomers = (): Promise<DropdownResponse> => {
  //   return axios
  //     .get(`${GET_ALL_CUSTOMER_NAME}`)
  //     .then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

  // const getservicetypes = (): Promise<DropdownResponse> => {
  //   return axios.get(`${GET_ALL_SERVICETYPES}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

//  const getcommunicationtypes = (): Promise<DropdownResponse> => {
//     return axios
//       .get(`${GET_ALL_COMMUNICATIONTYPES}`)
//        .then((d: AxiosResponse<DropdownResponse>) => d.data)
//   }

  // const getNativeLanguage = (): Promise<DropdownResponse> => {
  //   return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

  // const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
  //   `${roleQUERIES.Customer_LIST}`,
  //   getCustomers,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  // const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
  //   `Query.nativeLanguageList`,
  //   getNativeLanguage,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  // const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
  //   `Query.serviceTypeList`,
  //   getservicetypes,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  // const {isFetching: isFetchingcommunication, data: communicationTypeList} = useQuery(
  //   `Query.communicationTypeList`,
  //   getcommunicationtypes,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  const languageOptions =
  responseLanguage?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const serviceTypeOptions =
  responseServicetypes?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
  responseCommunicationtypes?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const customerOptions =
    responseCustomer?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []
  const [data, setdata] = useState({
    accounts: [],
    langs: [],
    serviceTypes: [],
    communicationTypes: [],
    startDate: undefined,
    endDate: undefined,
    status: [],
  })
  const handleSubmit = (values: any) => {
    const SUBMIT_FILTERS_URL = `${API_URL}/Appoinment/filter/${currentUser?.result.userType}/${currentUser?.result.code}`
    setFilterData(values)
    setCurrentPage(1)

    axios.post(SUBMIT_FILTERS_URL, values).then((response) => {
      const {data: appointments, payload} = response.data
      setAllAppointments(appointments)
      setTotalPages(payload.pagination.last_page)
      setShowDropdownMenu(false)
      setIsFilterApplied(true)
    })
  }
  const status = [
    {value: '0', label: 'Open'},
    {value: '1', label: 'Ready to Assign'},
    {value: '2', label: 'Confirmed'},
    {value: '7', label: 'Accepted'},
    {value: '3', label: 'No interpreter'},
    {value: '4', label: 'Abandoned'},
    {value: '5', label: 'Complete'},
    {value: '6', label: 'Cancelled'},
  ]
  const getFilteredStatusOptions = () => {
    if (currentUser?.result.userType === 'INTERPRETER') {
      return status.filter((option) => ['0', '2', '4', '5', '6', '7'].includes(option.value))
    }
    if (currentUser?.result.userType === 'SYSTEM') {
      return status.filter((option) => ['0', '1', '2', '3', '4', '5', '6'].includes(option.value))
    }
    if (currentUser?.result.userType === 'CONSUMER') {
      return status.filter((option) => ['0', '2', '3', '4', '5', '6'].includes(option.value))
    }

    return status
  }

  const fetchAllAppointments = async () => {
    setIsLoading(true)
    try {
      let response = await axios.get(
        `${API_URL}/Appoinment/${currentUser?.result?.userType}/${currentUser?.result?.code}`,
        {
          params: {
            page: currentPage,
            items_per_page: 10,
            ...searchQuery,
          },
        }
      )
      const {data, payload} = response.data
      setCurrentPage(1)
      setAllAppointments(data)
      setTotalPages(payload.pagination.last_page)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleReset = async () => {
    setCurrentPage(1)
    setdata({
      accounts: [],
      langs: [],
      serviceTypes: [],
      communicationTypes: [],
      startDate: undefined,
      endDate: undefined,
      status: [],
    })

    setShowDropdownMenu(false)
    await fetchAllAppointments()
    setFilterData({})
    setIsFilterApplied(false)
  }
  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)
                
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                  Customer Name(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.accounts?.length === customerOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'accounts',
                                        customerOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('accounts', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('accounts')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCustomer ? customerOptions : []}
                                  placeholder='Select Customer(s)'
                                  isMulti
                                  value={
                                    customerOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.accounts) &&
                                        (values.accounts as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'accounts',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  onBlur={(e) => setFieldTouched('customers', true)}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='languages' className='form-label fs-7 mb-1'>
                                  Language(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.langs?.length === languageOptions?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'langs',
                                        languageOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('langs', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('langs')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingLanguage ? languageOptions : []}
                                  placeholder='Select Language(s)'
                                  isMulti
                                  value={
                                    languageOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.langs) &&
                                        (values.langs as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'langs',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                                  Service Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.serviceTypes?.length === serviceTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'serviceTypes',
                                        serviceTypeOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('serviceTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('serviceTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingServicetypes ? serviceTypeOptions : []}
                                  placeholder='Select : All'
                                  isMulti
                                  value={
                                    serviceTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.serviceTypes) &&
                                        (values.serviceTypes as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'serviceTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                                  Communication Type(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    values?.communicationTypes?.length ===
                                    communicationTypeOptions?.length
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'communicationTypes',
                                        communicationTypeOptions?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('communicationTypes', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('communicationTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCommunicationtypes ? communicationTypeOptions : []}
                                  placeholder='Select Communication Type(s)'
                                  isMulti
                                  value={
                                    communicationTypeOptions?.filter(
                                      (option: any) =>
                                        Array.isArray(values.communicationTypes) &&
                                        (values.communicationTypes as string[]).includes(
                                          option.value
                                        )
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'communicationTypes',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='status' className='form-label fs-7 mb-1'>
                                  Status
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values?.status?.length === getFilteredStatusOptions()?.length}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        'status',
                                        getFilteredStatusOptions()?.map((option) => option.value)
                                      )
                                    } else {
                                      setFieldValue('status', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('status')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  value={
                                    getFilteredStatusOptions()?.filter(
                                      (option: any) =>
                                        Array.isArray(values.status) &&
                                        (values.status as string[]).includes(option.value)
                                    ) || []
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      'status',
                                      selectedOptions
                                        ? selectedOptions.map((option) => option.value)
                                        : []
                                    )
                                  }}
                                  options={getFilteredStatusOptions()}
                                  isMulti
                                  placeholder='Select Status'
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                              Start Date
                            </label>
                            <input
                              {...formik.getFieldProps('startDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.startDate}
                              onChange={(e) => setFieldValue('startDate', e.target.value)}
                              placeholder='Enter Subject'
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                              End Date
                            </label>
                            <input
                              {...formik.getFieldProps('endDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.endDate}
                              onChange={(e) => setFieldValue('endDate', e.target.value)}
                              placeholder='Start Time'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
