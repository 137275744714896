/* eslint-disable jsx-a11y/alt-text */
import {Page, View, Text, Image, Document} from '@react-pdf/renderer'
import moment from 'moment'
import styles from './invoiceStyle'
import {ToWords} from 'to-words'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

// ----------------------------------------------------------------------
type Props = {
  items?: any
  details?: any
  invoiceDetails?: any
}

const InvoicePdf: React.FC<Props> = ({items, details, invoiceDetails}) => {
  const toWords = new ToWords()
  // const tatalAmount =
  //   (items?.reduce(
  //     (total: number, item: any) =>
  //       total + parseFloat(String((item?.qty || 0) * (item?.rate || 0))),
  //     0
  //   ) || 0) +
  //   (details?.taxAmountModelList?.reduce(
  //     (total: number, item: any) => total + parseFloat(item?.taxAmount.toFixed(2) || '0'),
  //     0
  //   ) || 0)

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View>
          <View fixed>
            <View style={[styles.gridContainer, styles.mb3]}>
              <View style={{alignItems: 'flex-start', flexDirection: 'column'}}>
                <Text style={[styles.font8, styles.subtitle2]}>Ad Astra</Text>
                {/* <Text style={[styles.font7]}>Business Registration No - Pv_82345</Text> */}
                <Text style={[styles.font7]}>
                  8701 Georgia Ave, Suit # 800 Silver Spring, Maryland, 20910 US.
                </Text>
                {/* <Text style={[styles.font7]}>
                  Business Address - No.69, Ramyaweera Mawatha, Orugodawatta.
                </Text> */}
                {/* <Text>{`INV-xxx`} </Text> */}
              </View>
              <View style={{alignItems: 'flex-start'}}>
                <Image
                  source={toAbsoluteUrl('/media/logos/Untitled-1.png')}
                  style={[styles.logo, {width: 50, height: 50}]}
                />
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb8, styles.redbg]}>
              <View style={[styles.whiteContainer]}>
                <Text style={[styles.whitebg]}>INVOICE | COPY</Text>
              </View>
            </View>
          </View>
          <View style={[styles.borderstyle, styles.uppercase]}>
            {/* <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>CUSTOMER NAME</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>INV NO</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>JOB No</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>VEHICLE TYPE</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>ADDRESS</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>INV DATE</Text>
                </View>
                <View style={styles.col8}>
                  {' '}
                  <Text>: 2024-10-25</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>CUSTOMER SVAT NO</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>OUR SVAT NO</Text>
                </View>
                <View style={styles.col8}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8, styles.uppercase]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>CUSTOMER VAT NO</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>OUR VAT NO</Text>
                </View>
                <View style={styles.col8}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>BOOKING CUSTOMER</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>FROM</Text>
                </View>
                <View style={styles.col8}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>CONSIGNEE</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>TO</Text>
                </View>
                <View style={styles.col8}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>VEHICLE NO</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>CONT. NOS</Text>
                </View>
                <View style={styles.col8}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, styles.mb3, styles.font8]}>
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>TMS REQUEST ID</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
              <View style={[styles.gridContainer, styles.col5]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>PO REF NO</Text>
                </View>
                <View style={styles.col8}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View>
            </View> */}
            <View style={[styles.gridContainer, styles.mb3, styles.font7]}>
              {/* Left side: "To" Address */}
              <View style={[styles.gridContainer, styles.col7, styles.mr3]}>
                <View style={styles.col12}>
                  <Text>To:</Text>
                  <Text style={[styles.font8, styles.subtitle2]}>{invoiceDetails?.customer}</Text>
                  {invoiceDetails?.customerAddress &&
                    invoiceDetails?.customerAddress.split(',').map((item: any, index: number) => (
                      <Text style={[styles.font7]} key={index}>
                        {item}
                        {invoiceDetails?.customerAddress?.split(',').length !== index + 1
                          ? ','
                          : '.'}
                      </Text>
                    ))}
                </View>
              </View>

              {/* Right side: Invoice Info */}
              <View
                style={[
                  styles.gridContainer,
                  styles.col5,
                  {justifyContent: 'flex-start', alignItems: 'stretch', textAlign: 'right'}, // Center vertically and align right
                ]}
              >
                <View style={styles.col12}>
                  <Text>Invoice #: {invoiceDetails?.invoiceNo ?? 'N/A'}</Text>
                  <Text>
                    Invoice Date: {moment(invoiceDetails?.invoiceDate)?.format('YYYY-MM-DD')}
                  </Text>
                  <Text>Due Date: {moment(invoiceDetails?.dueDate)?.format('YYYY-MM-DD')}</Text>
                  <Text>Time Zone: {invoiceDetails?.timeZone ?? 'N/A'}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 8,
                marginBottom: 8,
                paddingHorizontal: 8,
                paddingVertical: 5,
                backgroundColor: '#f8f9fa', 
                borderWidth: 1, 
                borderColor: '#d1d5db', 
                borderRadius: 8,
              }}
            >
              <Text style={{color: '#000', fontWeight: 'bold'}}>
                {' '}
                {/* equivalent to text-dark fw-bold */}
                Line Count: {invoiceDetails?.invoiceLineList?.length ?? 0}
              </Text>
            </View>

            <View style={{borderWidth: 1, borderColor: '#ccc', marginBottom: 10}}>
              <View style={{padding: 10}}>
                <View
                  style={{
                    flexDirection: 'row',
                    borderBottomWidth: 1,
                    borderColor: '#ddd',
                    paddingBottom: 5,
                  }}
                >
                  <View style={{flex: 1}}>
                    <Text style={{fontWeight: 'bold'}}>Call</Text>
                  </View>
                  <View style={{flex: 2}}>
                    <Text style={{fontWeight: 'bold'}}>Requester</Text>
                  </View>
                  <View style={{flex: 1, alignItems: 'flex-end'}}>
                    <Text style={{fontWeight: 'bold'}}>Type</Text>
                  </View>
                  <View style={{flex: 2, alignItems: 'center'}}>
                    <Text style={{fontWeight: 'bold'}}>Date/Time</Text>
                  </View>
                  <View style={{flex: 1, alignItems: 'flex-end'}}>
                    <Text style={{fontWeight: 'bold'}}>Duration</Text>
                  </View>
                  <View style={{flex: 1, alignItems: 'flex-end'}}>
                    <Text style={{fontWeight: 'bold'}}>Rate</Text>
                  </View>
                  <View style={{flex: 1, alignItems: 'flex-end'}}>
                    <Text style={{fontWeight: 'bold'}}>Extras</Text>
                  </View>
                  <View style={{flex: 1, alignItems: 'flex-end'}}>
                    <Text style={{fontWeight: 'bold'}}>Total</Text>
                  </View>
                </View>

                <View>
                  {/* Example data */}
                  {invoiceDetails?.invoiceLineList?.map((item: any, index: number) => (
                    <View
                      style={{
                        flexDirection: 'row',
                        borderBottomWidth: 1,
                        borderColor: '#ddd',
                        paddingVertical: 5,
                      }}
                      key={index}
                    >
                      <View style={{flex: 1}}>
                        <Text> {item?.contactId ?? 'N/A'}</Text>
                        <Text>
                          {item?.fromLanguage ?? 'N/A'} to {item?.toLanguage ?? 'N/A'}
                        </Text>
                      </View>
                      <View style={{flex: 2}}>
                        <Text>{item?.requester ?? 'N/A'}</Text>
                      </View>
                      <View style={{flex: 1, alignItems: 'flex-end'}}>
                        <Text>{item?.callType ?? 'N/A'}</Text>
                      </View>
                      <View style={{flex: 2, alignItems: 'flex-end'}}>
                        <Text>
                          {' '}
                          {moment(item?.callTime)?.format('MM/DD/YYYY hh:mm A') ?? 'N/A'}
                        </Text>
                      </View>
                      <View style={{flex: 1, alignItems: 'flex-end'}}>
                        <Text>{item?.duration ?? 'N/A'} min</Text>
                      </View>
                      <View style={{flex: 1, alignItems: 'flex-end'}}>
                        <Text> ${item?.amount ?? 'N/A'}</Text>
                      </View>
                      <View style={{flex: 1, alignItems: 'flex-end'}}>
                        <Text>${item?.extra ?? 'N/A'}</Text>
                      </View>
                      <View style={{flex: 1, alignItems: 'flex-end'}}>
                        <Text>${item?.total ?? 'N/A'}</Text>
                      </View>
                    </View>
                  ))}
                </View>

                <View style={[styles.gridContainer, styles.col12, styles.font8]}>
                  <View style={[styles.col10, styles.alignRight]}>
                    <Text style={{fontWeight: 'bold', marginTop: 5}}>TOTAL:</Text>
                    <Text style={{color: 'red', fontWeight: 'bold', marginRight: 5}}>
                      (*Taxes Not Included)
                    </Text>
                  </View>
                  <View style={[styles.col2, styles.alignRight]}>
                    <Text style={{marginTop: 5}}>
                      {' '}
                      $
                      {invoiceDetails?.invoiceLineList?.reduce((accumulator: number, item: any) => {
                        return accumulator + (+item?.total || 0)
                      }, 0)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={[styles.gridContainer, styles.mb3, styles.mt5]}>
              {/* <View style={[styles.gridContainer, styles.col8, styles.font8]}>
                <View style={styles.col4}>
                  {' '}
                  <Text>Remark</Text>
                </View>
                <View style={[styles.col8]}>
                  {' '}
                  <Text>: {'N/A'}</Text>
                </View>
              </View> */}
              <View style={[styles.col4]}>
                <View style={(styles.col12, styles.signatureStyle)}>
                  {' '}
                  <Text>Adastra</Text>
                  <View>
                    <Image
                      source={toAbsoluteUrl('/media/logos/signature.png')}
                      style={[styles.signature]}
                    />
                  </View>
                  <Text style={[styles.hr, styles.mt3]}></Text>
                  <Text>Authorized Signature</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View fixed>
          <View>
            {/* <View style={styles.col6}>
              <Text>
                <Text style={styles.subtitle2}>CREATED BY :</Text> {'N/A'}
              </Text>
            </View> */}
            {/* <View style={[styles.col6, styles.alignRight]}>
              <Text>
                <Text style={styles.subtitle2}>H</Text> +94 11 481 3921 | +94 11 7474747 |{' '}
                <Text style={styles.subtitle2}>F</Text> +94 11 481 3920
              </Text>
              <Text style={styles.lowercase}>hello@adastra.lk | www.adastra.lk</Text>
            </View> */}
          </View>
        </View>
      </Page>
    </Document>
  )
}

export {InvoicePdf}
