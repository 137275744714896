import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {useAuth} from '../modules/auth'
import {DashboardMain} from '../pages/dashboard/DashboardMain'
import {BillingView} from '../UI/billing-management/components/BillingView'
import {MemberView} from '../UI/members-management/MemberView'
import {ConfigurationView} from '../UI/configuration-management/components/ConfigurationView'
import {CompanyView} from '../UI/company-management/components/CompanyView'
import {AppointmentView} from '../UI/appointment-management/components/AppointmentView'
// import {InterpreterAppointmentView} from '../UI/Interpreter-appointment-management/components/InterpreterAppointmentView '

import {CustomersView} from '../UI/customers-management/MemberView'
import {CustomerDetail} from '../UI/customers-management/CustomerDetail'
import {AssignAppointmentView} from '../UI/appointment-management/components/AssignAppointmentView'
import {CreateAppointmentView} from '../UI/appointment-management/components/CreateAppointmentView'
import {QuickStart} from '../UI/quick-start/QuickStart'
import {EditAppointment} from '../UI/appointment-management/components/EditAppointment'
import {InterpreterInvoiceViewer} from '../UI/billing-management/components/InterpreterInvoiceViewer'
import {CustomerInvoiceViewer} from '../UI/billing-management/components/CustomerInvoiceViewer'
import {UpcomingAppointmentViewer} from '../UI/appointment-management/components/UpcomingAppointmentViewer'
import {EmailConfig} from '../UI/master-data/email-management/Email'
import {LogData} from '../UI/master-data/log-management/LogData'
import {BillingConfig} from '../UI/master-data/billing-management/BillingConfig'
import {PreCallDetails} from '../UI/master-data/log-management/PreCallDetails'
import {CustomerProfile} from '../UI/customers-management/CompanyProfile'
import {EmailConfigDuplicate} from '../UI/master-data/email-management-duplicate/Email'
import { PermissionsChnage } from '../UI/master-data/log-management/PermissionsChnage'
import { DeactivateServiceTypes } from '../UI/master-data/deactivate-service-types/DeactivateServiceTypes'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../../app/UI/UserProfileView/accounts/AccountPage'))
  // const UsersPage = lazy(() => import('../UI/user-management/UsersPage'))
  const UsersPage = lazy(() => import('../../app/UI/user-management/UsersPage'))
  const RolesPage = lazy(() => import('../../app/UI/user-role-management/RolesPage'))

  const LanguageView = lazy(() => import('../UI/master-data/language-management/Page'))
  const ServiceTypeView = lazy(() => import('../UI/master-data/servicetype-management/Page'))
  {
    /*const IndustryView = lazy(() => import('../UI/master-data/industry-management/Page'))*/
  }
  const CommunicationView = lazy(() => import('../UI/master-data/communication-management/Page'))
  const SpecialTypeView = lazy(() => import('../UI/master-data/specialtype-management/Page'))
  const GenderView = lazy(() => import('../UI/master-data/gender-management/Page'))
  const SystemVariablesView = lazy(
    () => import('../UI/master-data/systemVariables-management/Page')
  )
  const IVRView = lazy(() => import('../UI/master-data/IVR-management/Page'))
  const TimeZoneView = lazy(() => import('../UI/master-data/timezone-management/Page'))
  const DocumentView = lazy(() => import('../UI/document-management/Page'))
  const CountryView = lazy(() => import('../UI/master-data/country-management/Page'))
  const PrecallConfigView = lazy(
    () => import('../UI/master-data/precall-management/precallPolicyConfigPage')
  )
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardMain />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        <Route
          path='/billing/:id'
          element={
             <BillingView/>
          }
        />
        <Route path='/appointment/:id' element={<AppointmentView />} />
        {/* <Route path='/appointment/interpreter' element={<InterpreterAppointmentView />} /> */}
        <Route
          path='/members/:id'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <MemberView />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='/customers'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <CustomersView />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='/customers/:id'
          element={
            currentUser?.result?.userType !== 'INTERPRETER' ? (
              <CustomerDetail userType={currentUser?.result.userType ?? ''} />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='/company-profile/:id'
          element={
            currentUser?.result?.role === 'Admin' ? (
              <CustomerProfile />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />

        <Route path='/profile/:id' element={<ConfigurationView />} />
        <Route path='/company' element={<CompanyView className={''} />} />
        <Route path='/configuration' element={<ConfigurationView />} />
        <Route path='/appointmentViewer/:id' element={<AssignAppointmentView />} />
        <Route path='/upcomingAppointmentViewer/:id' element={<UpcomingAppointmentViewer />} />
        <Route path='/create-appointment' element={<CreateAppointmentView />} />
        <Route
          path='/quick-start'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <QuickStart />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route path='/edit-appointment/:id' element={<EditAppointment />} />
        <Route
          path='/voucher/:id'
          element={
            currentUser?.result?.userType === 'SYSTEM' ||
            currentUser?.result?.userType === 'INTERPRETER' ? (
              <InterpreterInvoiceViewer />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='/invoice/:id'
          element={
            currentUser?.result?.userType === 'SYSTEM' ||
            (currentUser?.result?.userType === 'CONSUMER' &&
              currentUser?.result?.role !== 'User') ? (
              <CustomerInvoiceViewer />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='/email/configuration'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <EmailConfig />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='/deactivate/serviceType/:id'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <DeactivateServiceTypes />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        {process.env.REACT_APP_SERVER === 'DEV' && (
          <Route
            path='/email/configuration-duplicate'
            element={
              currentUser?.result?.userType === 'SYSTEM' ? (
                <EmailConfigDuplicate />
              ) : (
                <Navigate to='/error/404' />
              )
            }
          />
        )}
        <Route
          path='/logData/all'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? <LogData /> : <Navigate to='/error/404' />
          }
        />
        <Route
          path='/premissionsChnage/all'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? <PermissionsChnage /> : <Navigate to='/error/404' />
          }
        />
        <Route
          path='/precalldata/all'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <PreCallDetails />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='/billConfig/configuration'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <BillingConfig />
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />

        {/* Lazy Modules */}
        {/* <Route
        this part is build but not used in system
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/*
        this part is build but not used in system
         <Route
          path='apps/user-role-management/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/service-type/*'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <SuspensedView>
                <ServiceTypeView />
              </SuspensedView>
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='apps/country-management/*'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <SuspensedView>
                <CountryView />
              </SuspensedView>
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='customers/:customerId/precalls/:policyId'
          element={
            <SuspensedView>
              <PrecallConfigView />
            </SuspensedView>
          }
        />
        {/* 
        <Route
          path='apps/document/*'
          element={
            <SuspensedView>
              <DocumentView userID='fbea8052-06cf-4b25-b026-9a527c90a18a' />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/communication/*'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <SuspensedView>
                <CommunicationView />
              </SuspensedView>
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        {/* <Route
          path='apps/industry/*'
          element={
            <SuspensedView>
              <IndustryView />
            </SuspensedView>
          }
        />*/}

        {/*  this part is build but not used in system
        <Route
          path='apps/special-type/*'
          element={
            <SuspensedView>
              <SpecialTypeView />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/language/*'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <SuspensedView>
                <LanguageView />
              </SuspensedView>
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='apps/gender/*'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <SuspensedView>
                <GenderView />
              </SuspensedView>
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='apps/SystemVariables/*'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <SuspensedView>
                <SystemVariablesView />
              </SuspensedView>
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='apps/ivr/*'
          element={
            currentUser?.result?.userType === 'SYSTEM' ? (
              <SuspensedView>
                <IVRView />
              </SuspensedView>
            ) : (
              <Navigate to='/error/404' />
            )
          }
        />
        <Route
          path='apps/time-zone/*'
          element={
            <SuspensedView>
              <TimeZoneView />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
