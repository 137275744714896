import {configureStore} from '@reduxjs/toolkit'
import precallReducer from '../modules/precall-management/precallSlice'
import AppApi from './api'
import customerSlice from './customerSlice';
import languagesSlice from './languagesSlice';
import awsRequesterSlice from './awsRequesterSlice';
import serviceSlice from './serviceSlice';
import communicationSlice from './communicationSlice';

export const store = configureStore({
  reducer: {
    precall: precallReducer,
    customers: customerSlice,
    languages : languagesSlice,
    awsRequesters: awsRequesterSlice,
    servicetypes: serviceSlice,
    communicationtypes: communicationSlice,
    [AppApi.reducerPath]: AppApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(AppApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
