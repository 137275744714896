import React, {FC, useState, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'

const RemindarScheduleAppointmentModal = ({...props}) => {
  const navigate = useNavigate()

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      tabIndex={-2}
      dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <h4 className=''>Reminder!</h4>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center'>
          <div className='symbol symbol-100px '>
            <img src='/media/other/to-do-list.gif' alt='' />
          </div>
        </div>
        <h4 className='fw-normal mt-2' style={{textAlign: 'center'}}>
          You have an appointment on{' '}
          <strong>{moment(props.title).format('MMMM D, YYYY [at] hh:mm A')}</strong>
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={() => {
            props.onHide()
            navigate(`/appointmentViewer/${props.appointmentId}`)
          }}
          className='btn btn-primary btn-sm'
        >
          Go Appoinment
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {RemindarScheduleAppointmentModal}
