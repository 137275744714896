import axios from 'axios'
import clsx from 'clsx'
import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useAuth} from '../../modules/auth'
import toaster from '../../../Utils/toaster'
import {updateUser} from './components/core/_requests'
import {useListView} from '../members-management/users-list/core/ListViewProvider'
import {ConfirmationModal} from './ConfirmationModal'

interface PermissionsModalProps {
  show: boolean
  handleClose: () => void
  handleSave: (updateData: any, errorMessage?: string, list?: any) => void
  formik: any
  id: any
  refetchCsutomer: any
}

const API_URL = process.env.REACT_APP_API_URL

const PermissionsModal: React.FC<PermissionsModalProps> = ({
  show,
  handleClose,
  handleSave,
  formik,
  id,
  refetchCsutomer,
}) => {
  const [loading, setLoading] = React.useState(false)
  const {currentUser} = useAuth()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const cancel = async (withRefresh?: boolean) => {
    if (withRefresh) {
      await refetchCsutomer()
    }
    setItemIdForUpdate(undefined)
    formik.resetForm()
  }

  const handleSaveChanges = async () => {
    try {
      setLoading(true)

      const updateData = {
        id: formik.values.code,
        opI_ShdTelephonic: formik.values.opI_ShdTelephonic,
        opI_OndemandTelephonic: formik.values.opI_OndemandTelephonic,
        vrI_ShdVideoInteroreting: formik.values.vrI_ShdVideoInteroreting,
        vrI_OndemandVideoInteroreting: formik.values.vrI_OndemandVideoInteroreting,
        osI_OnsiteConsecutive: formik.values.osI_OnsiteConsecutive,
        osI_OnsiteSimultaneous: formik.values.osI_OnsiteSimultaneous,
        osI_OnsiteWhisper: formik.values.osI_OnsiteWhisper,
        osI_Onsite: formik.values.osI_Onsite,
        modifiedBy: formik.values.modifiedBy ? formik.values.modifiedBy : currentUser?.result?.code,
        modifiedDateTime: formik.values.modifiedDateTime,
        other_3rdPartyPlatform: formik.values.other_3rdPartyPlatform,
      }

      const result = await axios.post(`${API_URL}/accounts/permission-update-check`, updateData)

      handleSave(updateData)
      if (result?.data?.status === 'S') {
        const response = await axios.put(`${API_URL}/accounts/permission-update`, updateData)
        if (response?.data?.status === 'S') {
          toaster('success', 'User has been updated successfully')
          formik.resetForm()
          handleClose()
          await cancel(true)
        } else {
          toaster('error', result?.data?.text ?? 'Record Not Updated')
        }
      } else if (result?.data?.status === 'E') {
        handleSave(updateData, result?.data?.text, result?.data?.result ?? [])
        handleClose()
        await cancel(true)
      }
    } catch (error) {
      console.error('Error updating permissions:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleModalClose = () => {
    formik.resetForm()
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <div className='row g-4 g-xl-6'>
            <div className='col-sm-6 col-md-6 col-lg-6 '>
              <label className='form-label fs-7 mb-1'>OPI</label>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('opI_ShdTelephonic')}
                    type='checkbox'
                    name='opI_ShdTelephonic'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.opI_ShdTelephonic && formik.errors.opI_ShdTelephonic,
                      },
                      {
                        'is-valid':
                          formik.touched.opI_ShdTelephonic && !formik.errors.opI_ShdTelephonic,
                      }
                    )}
                    checked={formik.values.opI_ShdTelephonic}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Scheduled Telephonic</label>
                </div>
              </div>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('opI_OndemandTelephonic')}
                    type='checkbox'
                    name='opI_OndemandTelephonic'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.opI_OndemandTelephonic &&
                          formik.errors.opI_OndemandTelephonic,
                      },
                      {
                        'is-valid':
                          formik.touched.opI_OndemandTelephonic &&
                          !formik.errors.opI_OndemandTelephonic,
                      }
                    )}
                    checked={formik.values.opI_OndemandTelephonic}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>On-Demand Telephonic</label>
                </div>
              </div>
              <div>
                <label className='form-label fs-7 mb-1'>VRI</label>
                <div className='d-flex align-items-center mb-2'>
                  <div className='form-check-custom form-check-white form-check-sm-12'>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('vrI_ShdVideoInteroreting')}
                      type='checkbox'
                      name='vrI_ShdVideoInteroreting'
                      className={clsx(
                        'form-check-input',
                        {
                          'is-invalid':
                            formik.touched.vrI_ShdVideoInteroreting &&
                            formik.errors.vrI_ShdVideoInteroreting,
                        },
                        {
                          'is-valid':
                            formik.touched.vrI_ShdVideoInteroreting &&
                            !formik.errors.vrI_ShdVideoInteroreting,
                        }
                      )}
                      checked={formik.values.vrI_ShdVideoInteroreting}
                      disabled={formik.isSubmitting}
                    />
                    <label className='form-check-label form-check-sm'>
                      Scheduled Video Interpreting
                    </label>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='form-check-custom form-check-white form-check-sm-12'>
                    <input
                      placeholder=''
                      {...formik.getFieldProps('vrI_OndemandVideoInteroreting')}
                      type='checkbox'
                      name='vrI_OndemandVideoInteroreting'
                      className={clsx(
                        'form-check-input',
                        {
                          'is-invalid':
                            formik.touched.vrI_OndemandVideoInteroreting &&
                            formik.errors.vrI_OndemandVideoInteroreting,
                        },
                        {
                          'is-valid':
                            formik.touched.vrI_OndemandVideoInteroreting &&
                            !formik.errors.vrI_OndemandVideoInteroreting,
                        }
                      )}
                      checked={formik.values.vrI_OndemandVideoInteroreting}
                      disabled={formik.isSubmitting}
                    />
                    <label className='form-check-label form-check-sm'>
                      On-Demand Video Interpreting
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6 '>
              <label className='form-label fs-7 mb-1'>OSI</label>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('osI_OnsiteConsecutive')}
                    type='checkbox'
                    name='osI_OnsiteConsecutive'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.osI_OnsiteConsecutive &&
                          formik.errors.osI_OnsiteConsecutive,
                      },
                      {
                        'is-valid':
                          formik.touched.osI_OnsiteConsecutive &&
                          !formik.errors.osI_OnsiteConsecutive,
                      }
                    )}
                    checked={formik.values.osI_OnsiteConsecutive}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Onsite Consecutive</label>
                </div>
              </div>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('osI_OnsiteSimultaneous')}
                    type='checkbox'
                    name='osI_OnsiteSimultaneous'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.osI_OnsiteSimultaneous &&
                          formik.errors.osI_OnsiteSimultaneous,
                      },
                      {
                        'is-valid':
                          formik.touched.osI_OnsiteSimultaneous &&
                          !formik.errors.osI_OnsiteSimultaneous,
                      }
                    )}
                    checked={formik.values.osI_OnsiteSimultaneous}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Onsite Simultaneous</label>
                </div>
              </div>
              <label className='form-label fs-7 mb-1'>Other</label>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('other_3rdPartyPlatform')}
                    type='checkbox'
                    name='other_3rdPartyPlatform'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.other_3rdPartyPlatform &&
                          formik.errors.other_3rdPartyPlatform,
                      },
                      {
                        'is-valid':
                          formik.touched.other_3rdPartyPlatform &&
                          !formik.errors.other_3rdPartyPlatform,
                      }
                    )}
                    checked={formik.values.other_3rdPartyPlatform}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Third Party Platform</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleModalClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleSaveChanges} disabled={loading || !formik.dirty}>
          {loading ? 'Saving…' : 'Save'}
        </Button>
      </Modal.Footer>

      {/* <ConfirmationModal
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleSave={(note: any) => {
          console.log('Note saved:', note)
          setShowConfirmationModal(false)
        }}
      /> */}
    </Modal>
  )
}

export default PermissionsModal
