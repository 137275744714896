import axios, {AxiosResponse} from 'axios'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {KTSVG, roleQUERIES} from '../../../../_metronic/helpers'
import {Button, Dropdown, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../Common/components/core/_models'
import Select, {MultiValue} from 'react-select'
import {request} from 'http'
import toaster from '../../../../Utils/toaster'
import {Form, Formik} from 'formik'
import {C} from '@fullcalendar/core/internal-common'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
const GET_ALL_REQUESTERS = `${API_URL}/accounts/dd-list-accounts/CONSUMER`
const GET_ALL_INTERPRETERS = `${API_URL}/accounts/dd-list-accounts/INTERPRETER`
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

const userTypes = [
  {value: 'customer', label: 'Customer'},
  {value: 'interpreter', label: 'Interpreter'},
  {value: 'requester', label: 'Requester'},
]

export function PermissionsChnage() {
  const [logData, setLogData] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const tableRef = useRef(null)

  useEffect(() => {
    fetchAllLogInData(currentPage)
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    if (searchTerm.trim() === '') {
      handleSearch()
    }
  }, [searchTerm])
  const [showModal, setShowModal] = useState(false)

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios.get(GET_ALL_CUSTOMER_NAME).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const customerOptions: any =
    responseCustomer?.data?.map((d: any) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const getRequesters = (): Promise<DropdownResponse> => {
    return axios.get(GET_ALL_REQUESTERS).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingRequester, data: responseRequester} = useQuery(
    `${roleQUERIES.requesters_LIST}`,
    getRequesters,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const requesterOptions: any =
    responseRequester?.data?.map((d: any) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const getInterpreter = (): Promise<DropdownResponse> => {
    return axios.get(GET_ALL_INTERPRETERS).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingInterpreter, data: responseInterpreter} = useQuery(
    `${roleQUERIES.interpreter_LIST}`,
    getInterpreter,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const interpreterOptions: any =
    responseInterpreter?.data?.map((d: any) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleDownloadClick = () => {
    setShowModal(true)
  }

  const submitData = async (values: any, resetForm: any) => {
    let dataobject = {}
    setIsLoading(true)

    switch (values.userType) {
      case 'customer':
        dataobject = {
          filter: {
            customer: values.customers,
            search: searchTerm,
          },
          isCsv: values.fileType === 'CSV',
        }
        break

      case 'interpreter':
        dataobject = {
          filter: {
            interpreters: values.interpreters,
            search: searchTerm,
          },
          isCsv: values.fileType === 'CSV',
        }
        break

      case 'requester':
        dataobject = {
          filter: {
            users: values.users,
            search: searchTerm,
          },
          isCsv: values.fileType === 'CSV',
        }
        break

      default:
        dataobject = {}
    }

    try {
      const result: any = await axios.post(
        `${API_URL}/accountsuser-permission-export`,
        dataobject,
        {
          responseType: 'blob',
        }
      )

      const fileExtension = values.fileType === 'CSV' ? 'csv' : 'xlsx'
      const mimeType =
        values.fileType === 'CSV'
          ? 'text/csv'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

      const url = window.URL.createObjectURL(new Blob([result.data], {type: mimeType}))

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `permission-log-export.${fileExtension}`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      toaster('success', 'File downloaded successfully.')
      resetForm()
      setShowModal(false)
    } catch (error) {
      console.error('Error:', error)
      toaster('error', 'File not downloaded.')
    } finally {
      setIsLoading(false)
    }
  }
  const handleClose = (resetForm: any) => {
    return () => {
      resetForm()
    }
    setShowModal(false)
  }
  const fetchAllLogInData = async (page: number) => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${API_URL}/accounts/user-permissions-log`, {
        params: {
          page,
          items_per_page: rowsPerPage,
          search: searchTerm,
        },
      })
      const {payload} = response.data
      setLogData(response.data.data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
    fetchAllLogInData(1)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }
  const [data, setdata] = useState({
    fileType: 'CSV',
    userType: '',
    customers: [],
    users: [],
    interpreters: [],
  })
  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Premissions_Details',
    sheet: searchTerm ? `Filtered results for "${searchTerm}"` : 'Complete PreCall Data',
  })
  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Permissions Details
        </h1>

        <p className='text-gray-500 fs-5'>Permissions Details List</p>
      </div>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title d-flex align-items-center position-relative me-4 '>
            {/* Login Data */}
          </div>

          <div className='card-toolbar'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                  onClick={handleSearch}
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Download CSV</Tooltip>}
              >
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-3 btn-icon'
                  onClick={handleDownloadClick}
                >
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-muted' />
                </button>
              </OverlayTrigger>
              <Formik
                enableReinitialize
                initialValues={data}
                onSubmit={async (values, {setSubmitting, resetForm}) => {
                  setSubmitting(true)

                  await submitData(values, resetForm)
                }}
              >
                {({
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  setFieldTouched,
                  setFieldValue,
                  handleSubmit,
                  resetForm,
                  values,
                  errors,
                  touched,
                  isValid,
                  dirty,
                  ...formik
                }) => (
                  <Form>
                    <Modal show={showModal} onHide={handleClose(resetForm)}>
                      <Modal.Header closeButton>
                        <Modal.Title>Select Option for Download</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <div style={{display: 'flex'}}>
                          <p className='col-md-3'>Select file type</p>

                          <label className='col-md-3'>
                            <input
                              type='checkbox'
                              value='CSV'
                              className='form-check-input mb-2'
                              style={{marginRight: '5px'}}
                              checked={values.fileType === 'CSV'}
                              onChange={() => {
                                setFieldValue('fileType', 'CSV')
                              }}
                            />
                            CSV
                          </label>
                          <label className='col-md-3'>
                            <input
                              type='checkbox'
                              value='XLSX'
                              className='form-check-input mb-2'
                              style={{marginRight: '5px'}}
                              checked={values.fileType === 'XLSX'}
                              onChange={() => {
                                setFieldValue('fileType', 'XLSX')
                              }}
                            />
                            XLSX
                          </label>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <p className='required form-label'>Select UserType</p>
                        </div>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={userTypes}
                          value={
                            userTypes?.find((option: any) => option.value === values.userType) ||
                            null
                          }
                          onChange={(option) => setFieldValue('userType', option?.value || '')}
                        />

                        {values.userType === 'customer' && (
                          <>
                            <div className='d-flex justify-content-between'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required  form-label mb-1 mt-5'
                              >
                                Select Customer
                              </label>
                              <div className='col-md-2 d-flex justify-content-end align-items-end mb-1'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm '>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    id='flexRadioLg2'
                                    checked={values.customers?.length === customerOptions?.length}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          'customers',
                                          customerOptions.map((x: any) => x.value)
                                        )
                                      } else {
                                        setFieldValue('customers', [])
                                      }
                                    }}
                                  />
                                  <label className='form-check-label fs-8' htmlFor='flexRadioLg2'>
                                    All
                                  </label>
                                </div>
                              </div>
                            </div>
                            <Select
                              className='react-select-styled react-select-solid react-select-sm'
                              classNamePrefix='react-select'
                              options={!isFetchingCustomer ? customerOptions : []}
                              isMulti
                              value={
                                customerOptions.filter(
                                  (option: any) =>
                                    Array.isArray(values.customers) &&
                                    (values.customers as string[]).includes(option.value)
                                ) || []
                              }
                              onChange={(selectedOptions: any) => {
                                setFieldValue(
                                  'customers',
                                  selectedOptions
                                    ? selectedOptions.map((option: any) => option.value)
                                    : []
                                )
                              }}
                              onBlur={handleBlur}
                            />
                          </>
                        )}
                        {values.userType === 'requester' && (
                          <>
                            <div className='d-flex justify-content-between'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required  form-label mb-1 mt-5'
                              >
                                Select Requester
                              </label>
                              <div className='col-md-2 d-flex justify-content-end align-items-end mb-1'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm '>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    id='flexRadioLg3'
                                    checked={values.users?.length === requesterOptions?.length}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          'users',
                                          requesterOptions.map((x: any) => x.value)
                                        )
                                      } else {
                                        setFieldValue('users', [])
                                      }
                                    }}
                                  />
                                  <label className='form-check-label fs-8' htmlFor='flexRadioLg2'>
                                    All
                                  </label>
                                </div>
                              </div>
                            </div>

                            <Select
                              className='react-select-styled react-select-solid react-select-sm'
                              classNamePrefix='react-select'
                              options={!isFetchingRequester ? requesterOptions : []}
                              isMulti
                              value={
                                requesterOptions.filter(
                                  (option: any) =>
                                    Array.isArray(values.users) &&
                                    (values.users as string[]).includes(option.value)
                                ) || []
                              }
                              onChange={(selectedOptions: any) => {
                                setFieldValue(
                                  'users',
                                  selectedOptions
                                    ? selectedOptions.map((option: any) => option.value)
                                    : []
                                )
                              }}
                              onBlur={handleBlur}
                            />
                          </>
                        )}
                        {values.userType === 'interpreter' && (
                          <>
                            <div className='d-flex justify-content-between'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required  form-label mb-1 mt-5'
                              >
                                Select Interpreter
                              </label>
                              <div className='col-md-2 d-flex justify-content-end align-items-end mb-1'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm '>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    id='flexRadioLg4'
                                    checked={
                                      values.interpreters?.length === interpreterOptions?.length
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          'interpreters',
                                          interpreterOptions.map((x: any) => x.value)
                                        )
                                      } else {
                                        setFieldValue('interpreters', [])
                                      }
                                    }}
                                    onBlur={(e) => setFieldTouched('interpreters', true)}
                                  />
                                  <label className='form-check-label fs-8' htmlFor='flexRadioLg2'>
                                    All
                                  </label>
                                </div>
                              </div>
                            </div>
                            <Select
                              className='react-select-styled react-select-solid react-select-sm'
                              classNamePrefix='react-select'
                              options={!isFetchingInterpreter ? interpreterOptions : []}
                              isMulti
                              value={
                                interpreterOptions.filter(
                                  (option: any) =>
                                    Array.isArray(values.interpreters) &&
                                    (values.interpreters as string[]).includes(option.value)
                                ) || []
                              }
                              onChange={(selectedOptions: any) => {
                                setFieldValue(
                                  'interpreters',
                                  selectedOptions
                                    ? selectedOptions.map((option: any) => option.value)
                                    : []
                                )
                              }}
                              onBlur={handleBlur}
                            />
                          </>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type='reset'
                          className='btn btn-light btn-sm'
                          data-bs-dismiss='modal'
                          onClick={(e) => {
                            resetForm()
                            setShowModal(false)
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          //type='submit'
                          className='btn btn-primary btn-sm'
                          onClick={(e) => handleSubmit()}
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          Download
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <div className='mt-1 mb-4 py-2 px-8'>
          <div className='tab-content' id='myTabContent'>
            {/* begin::Body */}
            <div className='py-0'>
              {/* begin::Table container */}
              <div className='table-responsive' style={{overflowY: 'scroll'}}>
                {/* begin::Table */}
                <table className='table table-row-dashed overflow-auto table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                  {/* begin::Table head */}
                  <thead
                    style={{
                      position: 'sticky',
                      top: '0',
                      backgroundColor: '#ffffff',
                      zIndex: '10',
                      boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;',
                      border: '1px solid #dee2e6',
                    }}
                  >
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-200px ' rowSpan={3} colSpan={1}>
                        Name
                      </th>
                      <th className='min-w-200px ' rowSpan={3} colSpan={1}>
                        Email
                      </th>
                      <th className='min-w-200px ' rowSpan={3} colSpan={1}>
                        Role
                      </th>
                      <th className='min-w-600px ' rowSpan={1} colSpan={7}>
                        Previous Permission
                      </th>
                      <th className='min-w-600px ' rowSpan={1} colSpan={7}>
                        New Permission
                      </th>
                      <th className='min-w-300px ' rowSpan={3} colSpan={1}>
                        Reason
                      </th>
                      <th className='min-w-150px ' rowSpan={3} colSpan={1}>
                        Modified By
                      </th>
                      <th className='min-w-150px' rowSpan={3} colSpan={1}>
                        Timestamp (UTC)
                      </th>
                    </tr>
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        OPI
                      </th>
                      <th className='min-w-120px ' rowSpan={1} colSpan={2}>
                        OSI
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        VRI
                      </th>
                      <th className='min-w-150px ' rowSpan={1} colSpan={1}>
                        OTHER
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        OPI
                      </th>
                      <th className='min-w-120px ' rowSpan={1} colSpan={2}>
                        OSI
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={2}>
                        VRI
                      </th>
                      <th className='min-w-150px ' rowSpan={1} colSpan={1}>
                        OTHER
                      </th>
                    </tr>
                    <tr className='fw-semibold text-muted text-uppercase text-center'>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Consecutive
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Simultaneous
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={1}>
                        Third Party Platform
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Consecutive
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Simultaneous
                      </th>
                      <th className='min-w-100px ' rowSpan={1} colSpan={1}>
                        On-Demand
                      </th>
                      <th className='min-w-60px ' rowSpan={1} colSpan={1}>
                        Scheduled
                      </th>
                      <th className='min-w-200px ' rowSpan={1} colSpan={1}>
                        Third Party Platform
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {logData?.length > 0  &&
                      logData.map((item: any, index: number) => (
                        <tr key={index}>
                          <td style={{borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd'}}>
                            {item.userName || '-'}
                          </td>
                          <td style={{borderRight: '1px solid #ddd'}}>{item.email || '-'}</td>
                          <td style={{borderRight: '1px solid #ddd'}}>{item.role || '-'}</td>

                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.opI_OndemandTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.opI_ShdTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.osI_OnsiteConsecutive ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.osI_OnsiteSimultaneous ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.vrI_OndemandVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.vrI_ShdVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.previousPermissions.other_3rdPartyPlatform ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>

                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.opI_OndemandTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.opI_ShdTelephonic ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.osI_OnsiteConsecutive ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.osI_OnsiteSimultaneous ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.vrI_OndemandVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.vrI_ShdVideoInteroreting ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>
                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.newPermissions.other_3rdPartyPlatform ? (
                              <i className='bi bi-check fw-bold fs-4 text-success' />
                            ) : (
                              <i className='bi bi-x fw-bold fs-4 text-danger' />
                            )}
                          </td>

                          <td style={{borderRight: '1px solid #ddd', textAlign: 'center'}}>
                            {item.reason ? item.reason : '-'}
                          </td>
                          <td
                            style={{
                              borderRight: '1px solid #ddd',
                              textAlign: 'center',
                            }}
                          >
                            {item.modifiedBy || '-'}
                          </td>
                          <td
                            style={{
                              borderRight: '1px solid #ddd',
                              borderLeft: '1px solid #ddd',
                              textAlign: 'center',
                            }}
                          >
                            {moment(item.lastModifiedDateTime).utc().format('YYYY-MM-DD hh:mm A') ||
                              '-'}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {logData?.length === 0 && (
                  <div
                    className='py-5 d-flex flex-column align-items-center justify-content-center'
                    style={{width: '90vw !important'}}
                  >
                    <div className='text-center'>
                      <div className='symbol symbol-200px'>
                        <img src='/media/other/nodata.png' alt='' />
                      </div>
                    </div>
                    <div className='d-flex text-center w-100 align-items-center justify-content-center fw-semibold fs-3 text-gray-400'>
                      No matching records found
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
          />
        </div>
      </div>
      {isLoading && <CommonLoading />}
    </>
  )
}
