import './style.css'

type Props = {
  count: number
  totalMinutes: number
  className: string
  className1: string
  className2: string
  className3: string
  description: string
  url: string
}

const OverviewSmallCardsSecond = ({
  count,
  totalMinutes,
  className,
  className1,
  className2,
  className3,
  description,
  url,
}: Props) => (
  <div
    className={`card card-md-stretch mb-5 shadow-custom  ${className1}`}
    style={{fontSize: '24px'}}
  >
    <div className='card-body px-4 py-4 rounded'>
      <div className='d-flex justify-content-start flex-wrap align-items-start flex-column'>
        <div className='d-flex me-4 '>
          <div className='symbol symbol-40px symbol-circle'>
            <div className='symbol-label'>
              {' '}
              <i className={`${url} ${className}`} style={{fontSize: '25px'}}></i>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column'>
          <span className={`pt-1 fw-semibold fs-5 mb-2  ${className2}`}>{description}</span>
          <div className='d-flex justify-content-between flex-wrap'>
            <div className='d-flex'>
              <div className='d-flex flex-column '>
                <h1 className='px-2'>{count}</h1>
                <span className='text-gray-600 fs-7 mt-0 px-2'>Calls</span>
              </div>
            </div>
            <div className='d-flex vertical-separator'></div>
            <div className='d-flex'>
              <div className='d-flex flex-column'>
                <h1 className='px-2'>{totalMinutes}</h1>
                <span className='text-gray-600 fs-7 mt-0 px-2'>Minutes</span>
              </div>
            </div>
          </div>
          {/* <span className={`fw-bold me-2 fs-1 mb-2  ${className3}`}>{number}</span> */}
          {/* <span className='text-gray-500 fs-8 mt-0'>{subnumber}</span> */}
        </div>
        {/* <span className={`fw-bold me-2 fs-1 mb-2  ${className3}`}>{number}</span>
        <span className='text-gray-500 fs-8 mt-0'>{subnumber}</span> */}
      </div>
    </div>
  </div>
)
export {OverviewSmallCardsSecond}
