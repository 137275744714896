import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {useQuery} from 'react-query'
import {useAuth} from '../../../../modules/auth'
import {DropdownResponse} from '../../../members-management/users-list/core/_models'
import {KTSVG, roleQUERIES} from '../../../../../_metronic/helpers'
import {CommonLoading} from '../../../../../Utils/commonLoading'
import {Form, Formik} from 'formik'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_REQUESTERS = `${API_URL}/accounts/ddlist-awsuserid`
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`

type Props = {
  setcall_log: any
  setCurrentPage: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  setFilterData: any
  fetchcall_log: any
  searchQuery: any
  filterData: any
}

export function CustomerFilterDropdown({
  setcall_log,
  setCurrentPage,
  setTotalPages,
  setFilterData,
  fetchcall_log,
  searchQuery,
  filterData,
}: Props) {
  const [selectedRequestor, setSelectedRequestor] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [selectedLanguages, setSelectedLanguages] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_CUSTOMER_NAME}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const requesterOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleSubmit = async () => {
    const passingData = {
      customerList: selectedRequestor.map((option) => option.value.toString()),
      languageList: selectedLanguages.map((option) => option.label),
      startDate: startDate || undefined,
      callType: 5,
      endDate: endDate || undefined,
    }
    setFilterData(passingData)
    try {
      await fetchcall_log(passingData, {
        params: {
          page: 1,
          items_per_page: 10,
          ...searchQuery,
        },
      })
      setCurrentPage(1)
      setIsFilterApplied(true)
    } catch (error) {
      console.log(error)
    } finally {
      setShowDropdownMenu(false)
      setIsLoading(false)
    }
  }

  const handleReset = async () => {
    setCurrentPage(1)
    setSelectedRequestor([])
    setSelectedLanguages([])
    setStartDate('')
    setEndDate('')
    setShowDropdownMenu(false)
    setFilterData({
      customerList: null,
      languageList: null,
      callStateList: null,
      startDate: null,
      endDate: null,
      companyCode: null,
      callType: 5,
    })
    await fetchcall_log(
      {
        customerList: null,
        languageList: null,
        callStateList: null,
        startDate: null,
        endDate: null,
        companyCode: null,
        callType: 5,
      },
      {
        search: '',
      }
    )
    setIsFilterApplied(false)
  }

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Formik
            enableReinitialize
            initialValues={{
              ...filterData,
              customerList:
                requesterOptions.filter((d: any) =>
                  filterData?.customerList?.includes(d?.value.toString())
                ) ?? [],
              languageList:
                languageOptions.filter((d: any) => filterData?.languageList?.includes(d?.label)) ??
                [],
            }}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              const passingData = {
                customerList: values.customerList.map((option: any) => option.value.toString()),
                languageList: values.languageList.map((option: any) => option.label),
                startDate: startDate || undefined,
                callType: 5,
                endDate: endDate || undefined,
              }
              setFilterData(passingData)
              try {
                await fetchcall_log(passingData, {
                  params: {
                    page: 1,
                    items_per_page: 10,
                    ...searchQuery,
                  },
                })
                setCurrentPage(1)
                setIsFilterApplied(true)
              } catch (error) {
                console.log(error)
              } finally {
                setShowDropdownMenu(false)
                setIsLoading(false)
                setSubmitting(false)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <Dropdown.Menu className='p-4 w-350px' style={{minHeight: '100%'}}>
                  <div className='px-4 pb-4'>
                    <div className='fs-4 text-dark fw-semibolder'>Filter</div>
                  </div>

                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      {currentUser?.result?.userType !== 'CONSUMER' &&
                        currentUser?.result?.role !== 'User' && (
                          <div className='col-sm-12 col-md-12'>
                            <div className='mb-3'>
                              <div className='col-md-12'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div>
                                    <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                                      Customer(s)
                                    </label>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={
                                        values.customerList.length === requesterOptions.length
                                      }
                                      onChange={(e: any) => {
                                        if (e.target.checked) {
                                          setFieldValue('customerList', requesterOptions)
                                        } else {
                                          setFieldValue('customerList', [])
                                        }
                                      }}
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg1'>
                                      All
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className='row g-4 g-xl-4'>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                  <div className='w-100'>
                                    <Select
                                      className='react-select-styled react-select-solid react-select-sm'
                                      classNamePrefix='react-select'
                                      options={!isFetchingCustomer ? requesterOptions : []}
                                      placeholder='Select Customer(s)'
                                      isMulti
                                      value={values.customerList}
                                      onChange={(e: any) => setFieldValue('customerList', e)}
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          maxHeight: '300px',
                                          overflow: 'auto',
                                          border: '1px solid #e4e6ef',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='col-md-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <label htmlFor='languages' className='form-label fs-7 mb-1'>
                                  Languages(s)
                                </label>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={values.languageList.length === languageOptions.length}
                                  onChange={(e: any) => {
                                    if (e.target.checked) {
                                      setFieldValue('languageList', languageOptions)
                                    } else {
                                      setFieldValue('languageList', [])
                                    }
                                  }}
                                />
                                <label className='form-check-label' htmlFor='flexRadioLg1'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingNativeLanguage ? languageOptions : []}
                                  placeholder='Select Languages(s)'
                                  isMulti
                                  value={values.languageList}
                                  onChange={(e: any) => setFieldValue('languageList', e)}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      maxHeight: '300px',
                                      overflow: 'auto',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                              Start Date
                            </label>
                            <input
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              id='startDate'
                              name='startDate'
                              value={values.startDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Enter Start Date'
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                              End Date
                            </label>
                            <input
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              id='endDate'
                              name='endDate'
                              value={values.endDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='End Date'
                              max={new Date().toISOString().split('T')[0]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={() => {
                        handleReset()
                        resetForm()
                      }}
                    >
                      Reset
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      Apply
                    </button>
                  </div>
                </Dropdown.Menu>
              </Form>
            )}
          </Formik>
        )}
      </Dropdown>
      {isLoading && <CommonLoading />}
    </>
  )
}
