import {useEffect, useState} from 'react'
import * as connect from 'amazon-connect-streams'
import {Button} from 'react-bootstrap'
import {useAuth} from './modules/auth'

const CCPContainer = () => {
  const {auth} = useAuth()
  const [showCCP, setShowCCP] = useState(false)

  const toggleCCP = () => {
    setShowCCP((prevShow) => !prevShow)
  }

  useEffect(() => {
    if (showCCP && auth?.isAuthenticated) {
      loadConnectLibrary()
    }
  }, [showCCP, auth])

  const loadConnectLibrary = async () => {
    if (window.connect && window.connect.core && window.connect.core.initCCP) {
      try {
        window.connect.core.initCCP(document.getElementById('ccpContainer') as HTMLElement, {
          ccpUrl: `${process.env.REACT_APP_AWS_CONNECT_URL}`, // Replace with your CCP URL
          loginPopup: false, // optional, defaults to `true`
          loginPopupAutoClose: false, // optional, defaults to `false`
          loginOptions: {
            // optional, if provided opens login in new window
            autoClose: true, // optional, defaults to `false`
            height: 600, // optional, defaults to 578
            width: 400, // optional, defaults to 433
            top: 0, // optional, defaults to 0
            left: 0, // optional, defaults to 0
          },
          region: 'us-east-1',
          softphone: {
            // optional, defaults below apply if not provided
            allowFramedSoftphone: true, // optional, defaults to false
            //disableRingtone: false, // optional, defaults to false
            //ringtoneUrl: '[your-ringtone-filepath].mp3', // optional, defaults to CCP’s default ringtone if a falsy value is set
            allowFramedVideoCall: true, // optional, default to false
            allowEarlyGum: true, //optional, default to true
          },
          task: {
            disableRingtone: false, // optional, defaults to false
            ringtoneUrl: '[your-ringtone-filepath].mp3', // optional, defaults to CCP's default ringtone if a falsy value is set
          },
          pageOptions: {
            //optional
            enableAudioDeviceSettings: true, //optional, defaults to 'false'
            enableVideoDeviceSettings: true, //optional, defaults to 'false'
            enablePhoneTypeSettings: true, //optional, defaults to 'true'
          },
          ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
          ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
          ccpLoadTimeout: 30000, //optional, defaults to 5000 (ms)
        })
      } catch (error) {
        console.error('Error initializing CCP:', error)
      }
    } else {
      console.error('Amazon Connect library failed to load.')
    }
  }

  return (
    <div>
      {/* Toggle Button */}
      <Button
        variant='primary'
        onClick={toggleCCP}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1100,
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {showCCP ? (
          <i className='bi bi-arrow-right'></i> // Back icon
        ) : (
          <i className='bi bi-telephone-fill'></i> // Call icon
        )}
      </Button>

      {/* CCP Widget */}
      <div
        id='ccpContainer'
        style={{
          width: '350px',
          height: '500px',
          position: 'fixed',
          bottom: '20px',
          right: showCCP ? '20px' : '-400px', // Slide in and out
          transition: 'right 0.3s ease',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
          backgroundColor: 'white',
          borderRadius: '8px',
        }}
      ></div>
    </div>
  )
}

export default CCPContainer
