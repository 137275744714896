import {FC, useState, useEffect} from 'react'
import {LanguageModel} from '../../core/_models'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {KTSVG, toAbsoluteUrl, roleQUERIES} from '../../../../../../_metronic/helpers'
import {getCustomers} from '../../../../Common/components/core/_requests'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useQuery} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import clsx from 'clsx'

type Props = {
  isUserLoading?: boolean
  userType: string
  customerCode?: string
}

const UsersListHeader: React.FC<Props> = ({userType, isUserLoading, customerCode}) => {
  const [data, setData] = useState<string[]>([])
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const {selected, setAdminModalOpen} = useListView()
  const editUserSchema = Yup.object().shape({
    email: Yup.string().email().min(2, 'Too Short!').max(500, 'Too Long!').required('Required'),
    fK_Customer: Yup.string().required('Required'),
  })

  const [userForEdit] = useState<LanguageModel>({})

  // const {
  //   isFetching: isFetchingCustomer,
  //   data: responseCustomer,
  // } = useQuery(
  //   `${roleQUERIES.Customer_LIST}`,
  //   () => {
  //     return getCustomers(userType, customerCode ?? '')
  //   },
  //   { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  // )

  // const openAdminModal = () => {
  //   setAdminModalOpen(null)
  // }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // const func_addemail = (params: string) => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     if (!data.includes(params)) {
  //       data.push(params)
  //       setData(data);
  //     }
  //     setEmail('');
  //     setLoading(false);
  //   }, 10);
  // }

  // const func_removeemail = (params: string) => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     var restData = data.filter(function (item) {
  //       return item !== params
  //     })
  //     setData(restData);
  //     setEmail('');
  //     setLoading(false);
  //   }, 10);
  // }

  return (
    <div className='card-header'>
      <div className='card-title d-flex align-items-center position-relative me-4'>
        {userType == 'USER' && (
          <>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <h5 className='text-black fs-3 fw-bold '>Languages</h5>
              </div>
              <div className='d-flex flex-wrap fs-6'>
                <p className='text-gray-500 mb-0' style={{fontSize: '12px'}}>
                  Qualified Languages
                </p>
              </div>
            </div>
          </>
        )}
        {userType == 'ADMIN' && (
          <>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <h5 className='text-black fs-3 fw-bold '>Pending Approvals / Permissions</h5>
              </div>
              <div className='d-flex flex-wrap fs-6'>
                <p className='text-gray-500 mb-0' style={{fontSize: '12px'}}>
                  Qualified Languages
                </p>
              </div>
            </div>
          </>
        )}
        {userType == 'VISITOR' && (
          <>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <h5 className='text-black fs-3 fw-bold '>Languages</h5>
              </div>
              <div className='d-flex flex-wrap fs-6'>
                <p className='text-gray-500 mb-0' style={{fontSize: '12px'}}>
                  Qualified Languages
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center'>
            <UsersListSearchComponent />
          </div>
          <div>
            {(userType === 'USER' || userType === 'ADMIN') && (
              <>
                <UsersListToolbar />
              </>
            )}
            {/* {userType == 'ADMIN' && (
              <>
                <UsersListToolbar />
              </>
            )} */}
            {/* <button
              type='button'
              className='btn btn-sm btn-primary fs-6'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
            >
              <i className='bi bi-people-fill me-2'></i> Invite All
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )

  // return (
  //   <div className='card-header px-0'>
  //     <div className='card-title d-flex align-items-center position-relative me-4 '>
  //       {
  //         (userType == "CONSUMER" && <> Connected Users for Ad Astra</>)
  //       }
  //       {
  //         (userType == "INTERPRETER" && <> Connected Interpreters for Ad Astra Internal</>)
  //       }
  //       {
  //         (userType == "SYSTEM" && <> Connected Administrators for Ad Astra Internal</>)
  //       }
  //     </div>
  //     <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
  //       <div className='card-toolbar'>
  //         <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
  //           <div className='modal-dialog'>
  //             <div className='modal-content'>
  //               <div className='modal-header py-2'>
  //                 <h4 className='modal-title'>Invite Your Team</h4>
  //                 <div
  //                   className='btn btn-icon btn-sm btn-active-light-primary ms-2'
  //                   data-bs-dismiss='modal'
  //                   aria-label='Close'
  //                 >
  //                   <KTSVG
  //                     path='/media/icons/duotune/arrows/arr061.svg'
  //                     className='svg-icon svg-icon-2x'
  //                   />
  //                 </div>
  //               </div>
  //               <div className='modal-body'>
  //                 <div className='row g-4 g-xl-6'>
  //                   <div className='col-sm-12 col-md-12 col-lg-12'>
  //                     <div className=''>
  //                       <label htmlFor='exampleFormControlInput1' className='required form-label'>
  //                         Add e-mail addresses of users you wish to invite
  //                       </label>
  //                       <div className='row g-4 g-xl-6 d-flex align-items-center'>
  //                         <div className='col-sm-9 col-md-9 col-lg-9'>
  //                           <input
  //                             onChange={(event) => setEmail(event.target?.value)}
  //                             placeholder='Enter Email Address'

  //                             className={clsx(
  //                               'form-control form-control-solid form-select-sm mb-3 mb-lg-0',
  //                               { 'is-invalid': formik.touched.email && formik.errors.email },
  //                               {
  //                                 'is-valid': formik.touched.email && !formik.errors.email,
  //                               }
  //                             )}
  //                             value={email}
  //                             type='email'
  //                             name='email'
  //                             autoComplete='off'
  //                             disabled={formik.isSubmitting}
  //                           />
  //                           {/* end::Input */}
  //                           {formik.touched.email && formik.errors.email && (
  //                             <div className='fv-plugins-message-container'>
  //                               <span role='alert'>{formik.errors.email}</span>
  //                             </div>
  //                           )}

  //                         </div>
  //                         <div className='col-sm-3 col-md-3 col-lg-3 d-flex flex-end'>
  //                           <button type='button' onClick={(e) => func_addemail(email)} className='btn btn-sm btn-primary'>
  //                             <i className='bi bi-plus fs-2'></i>Add
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className='col-sm-12 col-md-12 col-lg-12'>
  //                     <div className='table-responsive'>
  //                       {/* begin::Table */}
  //                       <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
  //                         {/* begin::Table head */}
  //                         <thead>
  //                           <tr className='fw-semibold text-muted text-uppercase'>
  //                             <th className='min-w-150px '>Email</th>
  //                             <th className='min-w-100px text-end'>Action</th>
  //                           </tr>
  //                         </thead>
  //                         {/* end::Table head */}
  //                         {/* begin::Table body */}
  //                         <tbody>

  //                           {!isLoading && data.map((d, key) => (
  //                             <tr>
  //                               <td>
  //                                 <a className='text-gray-800 text-hover-primary fs-6'>
  //                                   {d}
  //                                 </a>
  //                               </td>

  //                               <td>
  //                                 <div className='d-flex justify-content-end flex-shrink-0'>
  //                                   <button onClick={(e) => func_removeemail(d)}
  //                                     className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
  //                                     type='button'
  //                                   >
  //                                     <KTSVG
  //                                       path='/media/icons/duotune/general/gen027.svg'
  //                                       className='svg-icon-3'
  //                                     />
  //                                   </button>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                           ))}

  //                         </tbody>
  //                         {/* end::Table body */}
  //                       </table>
  //                       {/* end::Table */}
  //                     </div>
  //                     {/* <div className='form-check-custom form-check-solid form-check-sm'>
  //                     <input
  //                       className='form-check-input'
  //                       type='checkbox'
  //                       value=''
  //                       id='flexCheckDefault'
  //                     />
  //                     <label
  //                       className='form-check-label form-check-sm'
  //                       htmlFor='flexCheckDefault'
  //                     >
  //                       Suppress Invitation Email
  //                     </label>
  //                   </div> */}
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className='modal-footer py-3'>
  //                 <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
  //                   Close
  //                 </button>
  //                 <button
  //                   type='submit'
  //                   className='btn btn-primary'
  //                   data-bs-dismiss='modal'
  //                   data-kt-users-modal-action='submit'
  //                   disabled={isUserLoading || formik.isSubmitting}
  //                 >
  //                   <span className='indicator-label'>Send Invitations</span>
  //                   {(formik.isSubmitting || isUserLoading) && (
  //                     <span className='indicator-progress'>
  //                       Please wait...{' '}
  //                       <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
  //                     </span>
  //                   )}
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
  //           <div className='modal-dialog'>
  //             <div className='modal-content'>
  //               <div className='modal-body'>
  //                 <div className='row g-4 g-xl-6'>
  //                   <div className='col-sm-12 col-md-12 col-lg-12'>
  //                     <div className='text-center'>
  //                       <KTSVG
  //                         path='/media/icons/duotune/general/gen044.svg'
  //                         className='svg-icon-info svg-icon-5hx'
  //                       />
  //                       <h1>Confirmation</h1>
  //                       <span> Are you sure you'd like to re-invite all individuals?</span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className='modal-footer py-3'>
  //                 <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
  //                   Cancel
  //                 </button>
  //                 <Link to='#'>
  //                   <button
  //                     type='button'
  //                     className='btn btn-primary btn-sm'
  //                     data-bs-dismiss='modal'
  //                   >
  //                     Re-Invite
  //                   </button>
  //                 </Link>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='d-flex align-items-center position-relative me-4 '>
  //           <span className='svg-icon svg-icon-3 position-absolute ms-3'>
  //             <svg
  //               width='24'
  //               height='24'
  //               viewBox='0 0 24 24'
  //               fill='none'
  //               xmlns='http://www.w3.org/2000/svg'
  //               className='mh-50px'
  //             >
  //               <rect
  //                 opacity='0.5'
  //                 x='17.0365'
  //                 y='15.1223'
  //                 width='8.15546'
  //                 height='2'
  //                 rx='1'
  //                 transform='rotate(45 17.0365 15.1223)'
  //                 fill='currentColor'
  //               ></rect>
  //               <path
  //                 d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
  //                 fill='currentColor'
  //               ></path>
  //             </svg>
  //           </span>
  //           <UsersListSearchComponent />
  //         </div>
  //         <div>
  //           {(userType == "CONSUMER" && <button
  //             type='button'
  //             className='btn btn-sm btn-primary fs-6 me-2'
  //             data-bs-toggle='modal'
  //             data-bs-target='#kt_modal_1'
  //           >
  //             <i className='bi bi-person-fill me-2'></i>Send Invitation
  //           </button>)}

  //         </div>
  //         <div>
  //           {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
  //           {/* <button
  //             type='button'
  //             className='btn btn-sm btn-primary fs-6'
  //             data-bs-toggle='modal'
  //             data-bs-target='#kt_modal_2'
  //           >
  //             <i className='bi bi-people-fill me-2'></i> Invite All
  //           </button> */}
  //         </div>
  //       </div>
  //     </form>
  //   </div >

  // )

  // return (
  //   <div className='card-header border-0 pt-6'>
  //     <UsersListSearchComponent />
  //     {/* begin::Card toolbar */}
  //     <div className='card-toolbar'>
  //       {/* begin::Group actions */}
  //       {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
  //       {/* end::Group actions */}
  //     </div>
  //     {/* end::Card toolbar */}
  //   </div>
  // )
}

export {UsersListHeader}
