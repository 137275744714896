/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import Select from 'react-select'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import ImageComponent from './ImageComponent'
import {ListWrapper} from '../../document-management/list/List'
import {UsersViewTable} from '../../user-language-map/UsersViewTable'
import {useParams} from 'react-router'
import {
  getUserByID,
  updateUserInfo,
  changePassword,
  getUserAdditionalInfo,
  getadditionalMaster,
  updateadditionalInfo,
} from './core/_requests'
import {
  getMasterData,
  getNativeLanguage,
  getTimeZone,
  getCountries,
  getOfficeLocations,
} from '../../Common/components/core/_requests'
import {useFormik} from 'formik'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import clsx from 'clsx'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import toaster from '../../../../Utils/toaster'
import './style.css'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete'
import PermissionsModal from '../permissionmodel'
import {useListView} from '../../members-management/users-list/core/ListViewProvider'
import {ConsumerLangugeTable} from './ConsumerLangugeTable'
import {ConfirmationModal} from '../ConfirmationModal'

interface RenderFuncProps {
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>
  getSuggestionItemProps: (suggestion: Suggestion) => React.HTMLAttributes<HTMLDivElement>
  suggestions: any
  loading?: boolean // Optional loading indicator
  error: any
}

const center = {
  lat: -3.745,
  lng: -38.523,
}

const styles = `
.phoneNumber input{
    border: none; 
    outline: none; 

  }
`
type Props = {
  className: string
  isUserLoading?: boolean
}

const MyProfileView: React.FC<Props> = ({className, isUserLoading}) => {
  let {id} = useParams()
  const [showInfoUI, setShowInfoUI] = useState(true)
  const {currentUser, setCurrentUser} = useAuth()
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showPin, setShowPin] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const hiddenFileInput = useRef<any>(null)
  const [permissionEditData, setPermissionEditData] = useState<any>()
  const [permissionError, setPermissionError] = useState<any>()
  const [permissionErrorTypes, setPermissionErrorTypes] = useState<any>()

  useEffect(() => {
    refetchCsutomer()
  }, [itemIdForUpdate])

  useEffect(() => {
    if (!selectedFile) {
      setPreview('')
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const {isFetching: isFetchingOfficeLocation, data: officeLocationList} = useQuery(
    [`Query.offliceLocationList`, currentUser?.result.fK_Customer, id],
    () => {
      return getOfficeLocations(Number(currentUser?.result.fK_Customer))
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    () => {
      return getNativeLanguage()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
    `Query.serviceTypeList`,
    () => {
      return getMasterData('SERVICE_TYPE')
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingGender, data: genderList} = useQuery(
    `gender-shortlist`,
    () => {
      return getMasterData('GENDER')
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isLoading: isLoadingCustomer,
    data: user,
    error,
    refetch: refetchCsutomer,
  } = useQuery(
    [`customer-detail`, id ?? currentUser?.result.code],
    () => {
      return getUserByID(id ?? currentUser?.result.code)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isLoading: isLoadingMasterInfo,
    data: userMasterIfo,
    error: errorMasterinfo,
    refetch: refetchMasterInfo,
  } = useQuery(
    [`customer-additional-master`, id ?? currentUser?.result.code],
    () => {
      return getadditionalMaster()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isLoading: isLoadingInfo,
    data: userIfo,
    error: errorinfo,
    refetch: refetchCsutomerInfo,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(
    [`customer-additional-detail`, id ?? currentUser?.result.code],
    () => {
      return getUserAdditionalInfo(id ?? currentUser?.result.code)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {isFetching: isFetchingCountry, data: countryList} = useQuery(
    `Query.countryList`,
    () => {
      return getCountries()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const editUserSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required('Required')
      .test('is-valid-phone-number', 'Invalid phone number', (value) => {
        return isValidPhoneNumber(value ? value : '')
      }),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    //fK_Customer: Yup.string().required('Required'),
    fK_DefaultTimeZone: Yup.string()
      .nullable()
      .required('Required')
      .notOneOf([''], 'Please select a default timezone'),
    fK_DefaultNativeLanguage: Yup.string()
      .nullable()
      .required('Required')
      .notOneOf([''], 'Please select a native language'),
    fK_ServiceType: Yup.string()
      .nullable()
      .when('currentUser?.result?.userType', {
        is: (value: string) => value == 'INTERPRETER',
        then: Yup.string().required('Required').notOneOf([''], 'Please select a service type'),
        otherwise: Yup.string().nullable(),
      }),
    // fK_Location: Yup.string()
    //   .required('Please select a location')
    //   .notOneOf([''], 'Please select a location'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // fK_Country: Yup.string().required('Required').notOneOf([''], 'Please select a country'),
    address: Yup.string().required('Required'),
    street1: Yup.string(),
    street2: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
    latitude: Yup.number(),
    longitude: Yup.number(),
  })

  const passwordFormValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required'),
    newPassword: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(50, 'Password must be at most 50 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Enter valid password as mentioned below'
      ),
    confirmPassword: Yup.string()
      .min(8, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      //  refetch()
    }
    formik.resetForm()
    hiddenFileInput.current.value = null // Clear the file input
    setSelectedFile(undefined)
    //setItemIdForUpdate(undefined)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...user,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        // if (values.isDeleted == undefined) {
        //   values.isDeleted = false;
        // }

        const formData = new FormData()
        formData.append('firstName', values?.firstName ?? '')
        formData.append('lastName', values.lastName ?? '')
        formData.append('phoneNumber', values.phoneNumber ?? '')
        //formData.append('isdeleted', values.isdeleted ?? "false");
        formData.append('email', values.email ?? '')
        formData.append('fK_Gender', values.fK_Gender ?? '')
        formData.append('fK_Customer', values.fK_Customer ?? '0')
        //formData.append('fK_Country', values.fK_Country ?? '0')
        formData.append('fK_Location', values.fK_Location ?? '')
        formData.append('fK_ServiceType', values.fK_ServiceType ?? '')
        formData.append('fK_DefaultTimeZone', values.fK_DefaultTimeZone ?? '')
        formData.append('fK_DefaultNativeLanguage', values.fK_DefaultNativeLanguage ?? '')
        formData.append('address', values.address ?? '')
        formData.append('street1', values?.street1 ?? '')
        formData.append('street2', values?.street2 ?? '')
        formData.append('city', values?.city ?? '')
        formData.append('state', values?.state ?? '')
        formData.append('country', values?.country ?? '')
        formData.append('postalCode', values?.postalCode ?? '')
        formData.append('latitude', values?.latitude?.toString() ?? '0')
        formData.append('longitude', values?.longitude?.toString() ?? '0')
        formData.append('profileImageFile', selectedFile ?? '')
        formData.append('profileImage', values?.profileImage ?? '')
        formData.append('code', values.code + '')

        if (values.opI_ShdTelephonic + '' === 'true' || values.opI_ShdTelephonic + '' == 'false') {
          formData.append('oPI_ShdTelephonic', JSON.parse(values.opI_ShdTelephonic + ''))
        } else {
          formData.append('oPI_ShdTelephonic', 'false')
        }

        if (
          values.opI_OndemandTelephonic + '' === 'true' ||
          values.opI_OndemandTelephonic + '' == 'false'
        ) {
          formData.append('oPI_OndemandTelephonic', JSON.parse(values.opI_OndemandTelephonic + ''))
        } else {
          formData.append('oPI_OndemandTelephonic', 'false')
        }

        if (
          values.vrI_ShdVideoInteroreting + '' === 'true' ||
          values.vrI_ShdVideoInteroreting + '' == 'false'
        ) {
          formData.append(
            'vRI_ShdVideoInteroreting',
            JSON.parse(values.vrI_ShdVideoInteroreting + '')
          )
        } else {
          formData.append('vRI_ShdVideoInteroreting', 'false')
        }

        if (
          values.vrI_OndemandVideoInteroreting + '' === 'true' ||
          values.vrI_OndemandVideoInteroreting + '' == 'false'
        ) {
          formData.append(
            'vRI_OndemandVideoInteroreting',
            JSON.parse(values.vrI_OndemandVideoInteroreting + '')
          )
        } else {
          formData.append('vRI_OndemandVideoInteroreting', 'false')
        }
        if (
          values.osI_OnsiteConsecutive + '' === 'true' ||
          values.osI_OnsiteConsecutive + '' == 'false'
        ) {
          formData.append('oSI_OnsiteConsecutive', JSON.parse(values.osI_OnsiteConsecutive + ''))
        } else {
          formData.append('oSI_OnsiteConsecutive', 'false')
        }

        if (
          values.osI_OnsiteSimultaneous + '' === 'true' ||
          values.osI_OnsiteSimultaneous + '' == 'false'
        ) {
          formData.append('oSI_OnsiteSimultaneous', JSON.parse(values.osI_OnsiteSimultaneous + ''))
        } else {
          formData.append('oSI_OnsiteSimultaneous', 'false')
        }

        if (values.osI_OnsiteWhisper + '' === 'true' || values.osI_OnsiteWhisper + '' == 'false') {
          formData.append('oSI_OnsiteWhisper', JSON.parse(values.osI_OnsiteWhisper + ''))
        } else {
          formData.append('oSI_OnsiteWhisper', 'false')
        }

        if (values.osI_Onsite + '' === 'true' || values.osI_Onsite + '' == 'false') {
          formData.append('oSI_Onsite', JSON.parse(values.osI_Onsite + ''))
        } else {
          formData.append('oSI_Onsite', 'false')
        }

        if (
          values.other_3rdPartyPlatform + '' === 'true' ||
          values.other_3rdPartyPlatform + '' == 'false'
        ) {
          formData.append('other_3rdPartyPlatform', JSON.parse(values.other_3rdPartyPlatform + ''))
        } else {
          formData.append('other_3rdPartyPlatform', 'false')
        }

        var result = await updateUserInfo(formData)
        if (result?.status == 'S') {
          toaster('success', result?.text ?? 'Record Updated')
          let obj: any = {
            code: '',
            status: 'S',
            text: '',
            result: {...currentUser?.result, defaultTimeZone: values?.fK_DefaultTimeZone},
          }
          setCurrentUser(obj)
        } else if (result?.status == 'E') {
          toaster('error', result?.text ?? 'Record Not Updated')
        }
        refetchCsutomer()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formikChangePassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: '',
      oldPassword: '',
      confirmPassword: '',
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await changePassword(values)
        toaster('success', 'Successfully updated')
        refetchCsutomer()
        formikChangePassword.resetForm()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik_additionalInfo = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...userIfo,
    },
    //validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (values.isHIPAA + '' == 'false' || values.isHIPAA == undefined) {
          values.isHIPAA = false
        } else {
          values.isHIPAA = true
        }

        if (values.isNAATI + '' == 'false' || values.isNAATI == undefined) {
          values.isNAATI = false
        } else {
          values.isNAATI = true
        }

        if (values.isASL + '' == 'false' || values.isASL == undefined) {
          values.isASL = false
        } else {
          values.isASL = true
        }

        if (values.isNZSTI + '' == 'false' || values.isNZSTI == undefined) {
          values.isNZSTI = false
        } else {
          values.isNZSTI = true
        }
        await updateadditionalInfo(values)
        refetchCsutomerInfo()
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        formik.setFieldValue('profileImage', reader.result)
        formik.setFieldTouched('profileImage', true, true)
      }
      reader.readAsDataURL(file)
    }

    setSelectedFile(file)
  }

  const renderFunc: React.FC<RenderFuncProps> = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
    error,
  }) => (
    <div className='autocomplete-root'>
      <input
        className={clsx('form-control form-control-sm form-control-light mb-3 custom-input-height')}
        disabled={formik.isSubmitting || isUserLoading}
        placeholder='Address'
        {...getInputProps()}
      />
      {error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
      <div className='autocomplete-dropdown-container'>
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.placeId}
            className='bg-light p-2 border border-bottom overflow-auto'
            style={{cursor: 'pointer'}}
          >
            <option>{suggestion.description}</option>
          </div>
        ))}
      </div>
    </div>
  )

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address)
      const placeDetails = await getLatLng(results[0])

      // Extract detailed address components
      let street1 = ''
      let street2 = ''
      let city = ''
      let state = ''
      let country = ''
      let postalCode = ''

      results[0].address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          street1 = component.long_name
        } else if (component.types.includes('route')) {
          street2 = component.long_name
        } else if (component.types.includes('locality')) {
          city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name
        } else if (component.types.includes('country')) {
          country = component.long_name
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
      })

      formik.setFieldValue('address', address)
      formik.setFieldValue('street1', street1)
      formik.setFieldValue('street2', street2)
      formik.setFieldValue('city', city)
      formik.setFieldValue('state', state)
      formik.setFieldValue('country', country)
      formik.setFieldValue('postalCode', postalCode)
      formik.setFieldValue('latitude', placeDetails.lat)
      formik.setFieldValue('longitude', placeDetails.lng)
      // Select the input element with id "city"
      const cityInputElement = document.querySelector<HTMLInputElement>('#city')

      // Ensure the cityInputElement exists before focusing on it
      if (cityInputElement) {
        cityInputElement.focus() // Focuses on the input field
      }
    } catch (error) {
      console.error('Error fetching place details', error)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {isFetching: isFetchingTimeZone, data: timeZoneList} = useQuery(
    `Query.timeZoneList`,
    () => {
      return getTimeZone()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const genderOptions = !isFetchingGender
    ? genderList?.data?.map((d) => ({
        value: d.key,
        label: d.value,
      }))
    : []
  const locationOptions = !isFetchingOfficeLocation
    ? officeLocationList?.data?.map((d) => ({
        value: d.key,
        label: d.value,
      }))
    : []
  const languageOptions = !isFetchingNativeLanguage
    ? nativeLanguageList?.data?.map((d) => ({
        value: d.key,
        label: d.value,
      }))
    : []
  const serviceOptions = !isFetchingServiceType
    ? servicetypeList?.data?.map((d) => ({
        value: d.key,
        label: d.value,
      }))
    : []

  const TimezoneOptions = !isFetchingTimeZone
    ? timeZoneList?.data?.map((d: any) => ({
        value: d.code,
        label: d.name,
      }))
    : []

  return (
    <div className={`card ${className}`}>
      <br />
      <div className='px-0' style={{borderBottom: 'none'}}>
        <div className='card-toolbar'>
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
              <div className='modal-dialog modal-lg'>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Complete Your Profile</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => cancel()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className=''>
                          <div className='row g-4 g-xl-6'>
                            <ImageComponent
                              selectedFile={selectedFile}
                              onSelectFile={onSelectFile}
                              preview={preview}
                              profileImage={user?.profileImage}
                              type='Profile'
                              hiddenFileInput={hiddenFileInput}
                            />

                            <div className='col-sm-8 col-md-8 col-lg-8 '>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Name
                                </label>
                                <div className='d-flex align-items-center'>
                                  <div className='row g-3 g-xl-4 flex-grow-1'>
                                    <div className='col-sm-6 col-md-6 col-lg-6 '>
                                      <input
                                        {...formik.getFieldProps('firstName')}
                                        name='firstName'
                                        className={clsx(
                                          'form-control form-control-white form-select-sm mb-2 custom-input-height'
                                        )}
                                        type='text'
                                        placeholder='Enter First Name'
                                      />
                                      {formik.errors.firstName ? (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.firstName}</span>
                                          </div>
                                        </div>
                                      ) : null}
                                      {currentUser?.result.userType === 'INTERPRETER' && (
                                        <Select
                                          {...formik.getFieldProps('fK_Gender')}
                                          name='fK_Gender'
                                          placeholder='Select a Gender'
                                          value={genderOptions?.find(
                                            (option) => option.value === formik.values.fK_Gender
                                          )}
                                          onChange={(option) =>
                                            formik.setFieldValue('fK_Gender', option?.value || '')
                                          }
                                          isDisabled={formik.isSubmitting || isUserLoading}
                                          options={genderOptions}
                                          styles={{
                                            control: (provided) => ({
                                              ...provided,
                                              width: '100%',
                                              border: '1px solid #e4e6ef',
                                            }),
                                            placeholder: (provided) => ({
                                              ...provided,
                                              color: '#a4afc4',
                                            }),
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className='col-sm-6 col-md-6 col-lg-6 '>
                                      <input
                                        {...formik.getFieldProps('lastName')}
                                        name='lastName'
                                        className={clsx(
                                          'form-control form-control-white form-select-sm mb-2 custom-input-height'
                                        )}
                                        type='text'
                                        placeholder='Enter Last Name'
                                      />
                                      {formik.errors.lastName ? (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.lastName}</span>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mb-3 '>
                                <div className='row g-4 g-xl-6'>
                                  <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Mobile Phone
                                    </label>

                                    <PhoneInput
                                      {...formik.getFieldProps('phoneNumber')}
                                      countryCallingCodeEditable={false}
                                      international
                                      defaultCountry='US'
                                      id='phoneNumber'
                                      name='phoneNumber'
                                      value={formik.values.phoneNumber}
                                      onChange={(value) =>
                                        formik.setFieldValue('phoneNumber', value ? value : '')
                                      }
                                      className={clsx(
                                        'phoneNumber form-control form-control-white form-select-sm mb-2 custom-input-height'
                                      )}
                                      styles={{
                                        border: 'none !important ',
                                      }}
                                    />
                                    {formik.errors.phoneNumber ? (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{formik.errors.phoneNumber}</span>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-sm-12'>
                              <div className='mb-3'>
                                <label className='required form-label fs-7 mb-1 '>Address</label>
                                {typeof window.google !== 'undefined' && (
                                  <PlacesAutocomplete
                                    onSelect={(suggestion: any) => {
                                      handleSelect(suggestion)
                                    }}
                                    value={formik.values.address ?? ''}
                                    onChange={(e: any) => {
                                      if (e === '') {
                                        formik.setFieldValue('address', '')
                                        formik.setFieldValue('street1', '')
                                        formik.setFieldValue('street2', '')
                                        formik.setFieldValue('city', '')
                                        formik.setFieldValue('state', '')
                                        formik.setFieldValue('country', '')
                                        formik.setFieldValue('postalCode', '')
                                        formik.setFieldValue('latitude', center.lat)
                                        formik.setFieldValue('longitude', center.lng)
                                      }
                                      formik.setFieldValue('address', e)
                                    }}
                                  >
                                    {({
                                      getInputProps,
                                      getSuggestionItemProps,
                                      suggestions,
                                      loading,
                                    }) => (
                                      <div>
                                        {renderFunc({
                                          getInputProps,
                                          getSuggestionItemProps,
                                          suggestions,
                                          loading,
                                          error: formik.errors.address,
                                        })}
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                )}
                              </div>

                              <div className='row'>
                                <div className='col-lg-6 col-sm-12 mb-3'>
                                  <label className='required form-label fs-7 mb-1 '>City</label>
                                  <div>
                                    <input
                                      placeholder='City'
                                      {...formik.getFieldProps('city')}
                                      type='text'
                                      name='city'
                                      id='city'
                                      className={clsx(
                                        'form-control form-control-sm form-control-light custom-input-height'
                                      )}
                                      autoComplete='off'
                                      disabled={formik.isSubmitting || isUserLoading}
                                    />
                                    {formik.errors.city && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{formik.errors.city}</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='col-lg-6 col-sm-12 mb-3'>
                                  <label className='required form-label fs-7 mb-1 '>State</label>
                                  <div>
                                    <input
                                      placeholder='State'
                                      {...formik.getFieldProps('state')}
                                      type='text'
                                      name='state'
                                      id='state'
                                      className={clsx(
                                        'form-control form-control-sm form-control-light custom-input-height'
                                      )}
                                      autoComplete='off'
                                      disabled={formik.isSubmitting || isUserLoading}
                                    />
                                    {formik.errors.state && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{formik.errors.state}</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='col-lg-6 col-sm-12 mb-3'>
                                  <label className='required form-label fs-7 mb-1 '>Country</label>
                                  <div>
                                    <input
                                      placeholder='Country'
                                      {...formik.getFieldProps('country')}
                                      type='text'
                                      name='country'
                                      id='country'
                                      className={clsx(
                                        'form-control form-control-sm form-control-light custom-input-height'
                                      )}
                                      autoComplete='off'
                                      disabled={formik.isSubmitting || isUserLoading}
                                    />
                                    {formik.errors.country && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{formik.errors.country}</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='col-lg-6 col-sm-12 mb-3'>
                                  <label className='required form-label fs-7 mb-1 '>
                                    Postal Code
                                  </label>
                                  <div className=''>
                                    <input
                                      placeholder='Postal Code'
                                      {...formik.getFieldProps('postalCode')}
                                      type='text'
                                      name='postalCode'
                                      id='postalCode'
                                      className={clsx(
                                        'form-control form-control-sm form-control-light custom-input-height'
                                      )}
                                      autoComplete='off'
                                      disabled={formik.isSubmitting || isUserLoading}
                                    />
                                    {formik.errors.postalCode && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{formik.errors.postalCode}</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-lg-6 col-sm-12'>
                                  <label
                                    htmlFor='exampleFormControlInput1'
                                    className='required form-label fs-7 mb-1 '
                                  >
                                    Company
                                  </label>

                                  <div>
                                    <input
                                      {...formik.getFieldProps('customerName')}
                                      name='customerName'
                                      className={clsx(
                                        'form-control form-control-white form-select-sm custom-input-height '
                                      )}
                                      type='text'
                                      placeholder='Company'
                                      disabled={true}
                                    />

                                    {formik.errors.fK_Customer && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>{formik.errors.fK_Customer}</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className='col-md-6 col-sm-12 d-flex align-items-end'>
                                  <div className='w-100  align-items-end'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 '
                                    >
                                      Location
                                    </label>
                                    <Select
                                      {...formik.getFieldProps('fK_Location')}
                                      name='fK_Location'
                                      placeholder='Select a Location'
                                      options={locationOptions}
                                      value={locationOptions?.find(
                                        (option) => option.value === formik.values.fK_Location
                                      )}
                                      onChange={(option) =>
                                        formik.setFieldValue('fK_Location', option?.value || '')
                                      }
                                      isDisabled={
                                        formik.isSubmitting ||
                                        isUserLoading ||
                                        currentUser?.result.userType === 'CONSUMER' ||
                                        currentUser?.result.userType === 'INTERPRETER'
                                      }
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          border: '1px solid #e4e6ef',
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: '#a4afc4',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-lg-6 col-sm-12'>
                                  <label
                                    htmlFor='exampleFormControlInput1'
                                    className='required form-label fs-7 mb-1 '
                                  >
                                    Native Language
                                  </label>
                                  <div className=''>
                                    <Select
                                      {...formik.getFieldProps('fK_DefaultNativeLanguage')}
                                      name='fK_DefaultNativeLanguage'
                                      placeholder='Select a Language'
                                      options={languageOptions}
                                      value={languageOptions?.find(
                                        (option) =>
                                          option.value === formik.values.fK_DefaultNativeLanguage
                                      )}
                                      onChange={(option) =>
                                        formik.setFieldValue(
                                          'fK_DefaultNativeLanguage',
                                          option?.value || ''
                                        )
                                      }
                                      isDisabled={formik.isSubmitting || isUserLoading}
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          border: '1px solid #e4e6ef',
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: '#a4afc4',
                                        }),
                                      }}
                                    />

                                    {formik.errors.fK_DefaultNativeLanguage && (
                                      <div className='fv-plugins-message-container '>
                                        <div className='fv-help-block'>
                                          <span role='alert'>
                                            {formik.errors.fK_DefaultNativeLanguage}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {currentUser?.result.userType !== 'INTERPRETER' && (
                                  <div className='col-md-6 col-sm-12 d-flex align-items-end'>
                                    <div className='w-100  align-items-end'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='required form-label fs-7 mb-1 '
                                      >
                                        Default Service Type
                                      </label>
                                      <Select
                                        {...formik.getFieldProps('fK_ServiceType')}
                                        name='fK_ServiceType'
                                        placeholder='Select a Service'
                                        options={serviceOptions}
                                        value={serviceOptions?.find(
                                          (option) => option.value === formik.values.fK_ServiceType
                                        )}
                                        onChange={(option) =>
                                          formik.setFieldValue(
                                            'fK_ServiceType',
                                            option?.value || ''
                                          )
                                        }
                                        isDisabled={
                                          formik.isSubmitting ||
                                          isUserLoading ||
                                          currentUser?.result.userType === 'CONSUMER'
                                        }
                                        styles={{
                                          control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            border: '1px solid #e4e6ef',
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: '#a4afc4',
                                          }),
                                        }}
                                      />
                                      {formik.errors.fK_ServiceType && (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.fK_ServiceType}</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}

                                {currentUser?.result.userType !== 'INTERPRETER' && (
                                  <div className='mb-3'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label fs-7 mb-1 mt-5 '
                                    >
                                      Service Type(s)
                                    </label>
                                    <div>
                                      <div className='w-100'>
                                        <Select
                                          name='serviceTypes'
                                          isMulti
                                          isDisabled={
                                            formik.isSubmitting ||
                                            isUserLoading ||
                                            currentUser?.result.userType === 'CONSUMER' ||
                                            formik.values.fK_Customer === ''
                                              ? true
                                              : false
                                          }
                                          placeholder='Select Service Types'
                                          value={formik.values.serviceTypes}
                                          styles={{
                                            control: (provided) => ({
                                              ...provided,
                                              width: '100%',
                                              border: '1px solid #e4e6ef',
                                            }),
                                            placeholder: (provided) => ({
                                              ...provided,
                                              color: '#a4afc4',
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Default Timezone
                                </label>
                                <div>
                                  <div className='w-100'>
                                    <Select
                                      {...formik.getFieldProps('fK_DefaultTimeZone')}
                                      name='fK_DefaultTimeZone'
                                      //placeholder='Select a Service'
                                      options={TimezoneOptions}
                                      value={TimezoneOptions?.find(
                                        (option) =>
                                          option.value === formik.values.fK_DefaultTimeZone
                                      )}
                                      onChange={(option) =>
                                        formik.setFieldValue(
                                          'fK_DefaultTimeZone',
                                          option?.value || ''
                                        )
                                      }
                                      isDisabled={formik.isSubmitting || isUserLoading}
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          width: '100%',
                                          border: '1px solid #e4e6ef',
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: '#a4afc4',
                                        }),
                                      }}
                                    />
                                    {formik.errors.fK_DefaultTimeZone && (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                          <span role='alert'>
                                            {formik.errors.fK_DefaultTimeZone}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => cancel()}
                    >
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-dismiss='modal'
                      data-kt-users-modal-action='submit'
                      disabled={
                        isUserLoading || formik.isSubmitting || !formik.isValid || !formik.dirty
                      }
                    >
                      <span className='indicator-label'>Update Profile</span>
                      {(formik.isSubmitting || isUserLoading) && (
                        <span className='indicator-progress'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className='modal fade' tabIndex={-1} id='kt_modal_4'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header py-2'>
                  <h4 className='modal-title'>Configure Email Authentication</h4>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='row g-4 g-xl-6 d-flex align-items-center'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className=''>
                        <p className='text-gray-600' style={{fontSize: '12px'}}>
                          <i className='bi bi-info-circle-fill text-gray-600 me-2 '></i> We will
                          send an email with your confirmation code
                        </p>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 '
                        >
                          Enter Your Email Address
                        </label>
                        <div className='row g-4 g-xl-6'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <input
                              type='text'
                              className='form-control form-control-white form-select-sm'
                              placeholder='Enter Email Address'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer py-3'>
                  <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                  <Link to='#'>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      data-bs-dismiss='modal'
                    >
                      Send Code
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <form
            id='kt_modal_5_form'
            className='form'
            onSubmit={formik_additionalInfo.handleSubmit}
            noValidate
          >
            <div className='modal fade' tabIndex={-1} id='kt_modal_5'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content '>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'> Language</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className=''>
                          <div className='row g-4 g-xl-6'>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Languages From
                                </label>
                                <div className='d-flex align-items-center flex-row justify-content-between w-100'>
                                  <div className='w-100'>
                                    <Select
                                      {...formik_additionalInfo.getFieldProps('languages')}
                                      className='react-select-styled react-select-solid react-select-sm'
                                      classNamePrefix='react-select'
                                      options={userMasterIfo?.data?.languages}
                                      onChange={(e) =>
                                        formik_additionalInfo.setFieldValue('languages', e)
                                      }
                                      placeholder=' Language From'
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          border: '1px solid #e4e6ef',
                                          color: '#5e6278',
                                          minHeight: '33px',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='form-label fs-7 mb-1 '
                                >
                                  Specialized Types
                                </label>
                                <div className='d-flex align-items-center flex-row justify-content-between w-100'>
                                  <div className='w-100'>
                                    <Select
                                      {...formik_additionalInfo.getFieldProps('specializedTypes')}
                                      className='react-select-styled react-select-solid react-select-sm'
                                      classNamePrefix='react-select'
                                      options={userMasterIfo?.data?.specializedTypes}
                                      onChange={(e) =>
                                        formik_additionalInfo.setFieldValue('specializedTypes', e)
                                      }
                                      placeholder='Specialized Types'
                                      isMulti
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          border: '1px solid #e4e6ef',
                                          color: '#5e6278',
                                          minHeight: '33px',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Languages To
                                </label>
                                <div className='d-flex align-items-center flex-row justify-content-between w-100'>
                                  <div className='w-100'>
                                    <Select
                                      {...formik_additionalInfo.getFieldProps('languages')}
                                      className='react-select-styled react-select-solid react-select-sm'
                                      classNamePrefix='react-select'
                                      options={userMasterIfo?.data?.languages}
                                      onChange={(e) =>
                                        formik_additionalInfo.setFieldValue('languages', e)
                                      }
                                      placeholder=' Language From'
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          border: '1px solid #e4e6ef',
                                          color: '#5e6278',
                                          minHeight: '33px',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Years Experience
                                </label>
                                <input
                                  {...formik_additionalInfo.getFieldProps('yearOfExp')}
                                  type='number'
                                  className='form-control form-control-white form-select-sm'
                                  placeholder='Enter Subject'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-dismiss='modal'
                      data-kt-users-modal-action='submit'
                      disabled={isUserLoading || formik_additionalInfo.isSubmitting}
                    >
                      <span className='indicator-label'>Update Info</span>
                      {(formik_additionalInfo.isSubmitting || isUserLoading) && (
                        <span className='indicator-progress'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <form
            id='kt_modal_5_1_form'
            className='form'
            onSubmit={formik_additionalInfo.handleSubmit}
            noValidate
          >
            <div className='modal fade' id='kt_modal_5_1'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content '>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Update Degree & Accreditation</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className=''>
                          <div className='row g-4 g-xl-6'>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label fs-7 mb-1 '
                                >
                                  Describe Degree:
                                </label>
                                <textarea
                                  {...formik_additionalInfo.getFieldProps('note')}
                                  placeholder='Personal Note'
                                  name='note'
                                  className='form-control form-select-white mb-lg-0'
                                  rows={11}
                                ></textarea>
                              </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6'>
                              <div className='mb-3'>
                                <div className='row g-4 g-xl-6 mb-3'>
                                  <label className='form-label fs-7 mb-1 '>
                                    Current HIPAA training & complianc
                                  </label>
                                  <div className='d-flex align-items-center  mt-1 mb-3'>
                                    <label className='d-flex flex-start mb-0 cursor-pointer me-3'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          {...formik_additionalInfo.getFieldProps('isHIPAA')}
                                          checked={
                                            formik_additionalInfo.values.isHIPAA + '' == 'true'
                                              ? true
                                              : false
                                          }
                                          value='true'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          Yes
                                        </span>
                                      </span>
                                    </label>
                                    <label className='d-flex flex-start mb-0 cursor-pointer'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          {...formik_additionalInfo.getFieldProps('isHIPAA')}
                                          checked={
                                            formik_additionalInfo.values.isHIPAA + '' == 'false'
                                              ? true
                                              : false
                                          }
                                          className='form-check-input'
                                          type='radio'
                                          value='false'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2 justify-content-end'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          No
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className='row g-4 g-xl-6 mb-3'>
                                  <label className='form-label fs-7 mb-1 '>
                                    Current NAATI accreditation/recognition
                                  </label>

                                  <div className='d-flex align-items-center  mt-1 mb-3'>
                                    <label className='d-flex flex-start mb-0 cursor-pointer me-3'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          {...formik_additionalInfo.getFieldProps('isNAATI')}
                                          checked={
                                            formik_additionalInfo.values.isNAATI + '' == 'true'
                                              ? true
                                              : false
                                          }
                                          value='true'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          Yes
                                        </span>
                                      </span>
                                    </label>
                                    <label className='d-flex flex-start mb-0 cursor-pointer'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          {...formik_additionalInfo.getFieldProps('isNAATI')}
                                          checked={
                                            formik_additionalInfo.values.isNAATI + '' == 'false'
                                              ? true
                                              : false
                                          }
                                          value='false'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2 justify-content-end'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          No
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className='row g-4 g-xl-6 mb-3 '>
                                  <label className='form-label fs-7 mb-1 '>
                                    Current NZSTI accreditation/recognition
                                  </label>

                                  <div className='d-flex align-items-center mt-1 mb-3'>
                                    <label className='d-flex flex-start cursor-pointer me-3'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          {...formik_additionalInfo.getFieldProps('isNZSTI')}
                                          checked={
                                            formik_additionalInfo.values.isNZSTI + '' == 'true'
                                              ? true
                                              : false
                                          }
                                          value='true'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          Yes
                                        </span>
                                      </span>
                                    </label>
                                    <label className='d-flex flex-start  cursor-pointer'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          {...formik_additionalInfo.getFieldProps('isNZSTI')}
                                          checked={
                                            formik_additionalInfo.values.isNZSTI + '' == 'false'
                                              ? true
                                              : false
                                          }
                                          value='false'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2 justify-content-end'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          No
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className='row g-4 g-xl-6 mb-3'>
                                  <label className='form-label fs-7 mb-1  '>ASL</label>

                                  <div className='d-flex align-items-center mt-1 mb-3'>
                                    <label className='d-flex flex-start  cursor-pointer me-3'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          {...formik_additionalInfo.getFieldProps('isASL')}
                                          checked={
                                            formik_additionalInfo.values.isASL + '' == 'true'
                                              ? true
                                              : false
                                          }
                                          value='true'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          Yes
                                        </span>
                                      </span>
                                    </label>
                                    <label className='d-flex flex-start mb-0 cursor-pointer'>
                                      <span className='form-check form-check-custom form-check-white me-3'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          {...formik_additionalInfo.getFieldProps('isASL')}
                                          checked={
                                            formik_additionalInfo.values.isASL + '' == 'false'
                                              ? true
                                              : false
                                          }
                                          value='false'
                                        />
                                      </span>
                                      <span className='d-flex align-items-center me-2 justify-content-end'>
                                        <span className='text-gray-800 text-hover-primary fs-6'>
                                          No
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className='mb-3'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='form-label fs-7 mb-1 '
                                >
                                  Industries
                                </label>
                                <div className='d-flex align-items-center flex-row justify-content-between w-100'>
                                  <div className='w-100'>
                                    <Select
                                      {...formik_additionalInfo.getFieldProps('industries')}
                                      options={userMasterIfo?.data?.industries}
                                      onChange={(e) =>
                                        formik_additionalInfo.setFieldValue('industries', e)
                                      }
                                      className='react-select-styled react-select-solid react-select-sm'
                                      classNamePrefix='react-select'
                                      placeholder='Industries'
                                      isMulti
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          border: '1px solid #e4e6ef',
                                          color: '#5e6278',
                                          minHeight: '33px',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-bs-dismiss='modal'
                      data-kt-users-modal-action='submit'
                      disabled={isUserLoading || formik_additionalInfo.isSubmitting}
                    >
                      <span className='indicator-label'>Update Info</span>
                      {(formik_additionalInfo.isSubmitting || isUserLoading) && (
                        <span className='indicator-progress'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className='modal fade' tabIndex={-1} id='kt_modal_6'>
            <div className='modal-dialog '>
              <div className='modal-content '>
                <div className='modal-header py-2'>
                  <h4 className='modal-title'>Add Document</h4>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='row g-4 g-xl-6'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className=''>
                        <div className='row g-4 g-xl-6'>
                          <div className='col-sm-12 col-md-12 col-lg-12 '>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label fs-7 mb-1 '
                              >
                                Name
                              </label>
                              <input
                                type='text'
                                className='form-control form-control-white form-select-sm'
                                placeholder='Enter Name'
                              />
                            </div>

                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1 '
                              >
                                Description
                              </label>
                              <input
                                type='text'
                                className='form-control form-control-white form-select-sm'
                                placeholder='Enter Description'
                              />
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label fs-7 mb-1 '
                              >
                                File Upload
                              </label>
                              <input
                                type='file'
                                className='form-control form-control-white form-select-sm'
                                placeholder=''
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer py-3'>
                  <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                  <Link to='#'>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      data-bs-dismiss='modal'
                    >
                      Save
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className='card mb-5 mb-xl-5 shadow-sm flex-grow-1 h-md-200'
          style={{borderTop: '4px solid #a3a242'}}
        >
          {isLoadingCustomer ? (
            <span
              className='indicator-progress p-5'
              style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <div className='card-body'>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12 '>
                  <div className='d-flex flex-wrap flex-sm-nowrap'>
                    <div className='me-7 mb-4'>
                      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border border-primary border-3 rounded-circle '>
                        {user?.profileImage !== undefined &&
                          user?.profileImage !== null &&
                          user?.profileImage !== '' && (
                            <>
                              <>
                                <img
                                  src={
                                    process.env.REACT_APP_IMG_URL + '/profile/' + user?.profileImage
                                  }
                                  alt='user'
                                  className='rounded-circle'
                                  style={{objectFit: 'cover'}}
                                />
                              </>
                            </>
                          )}

                        {!(
                          user?.profileImage !== undefined &&
                          user?.profileImage !== null &&
                          user?.profileImage !== ''
                        ) && (
                          <>
                            {preview !== undefined && preview !== '' && (
                              <>
                                <img
                                  src={preview}
                                  alt='user'
                                  className='rounded-circle'
                                  style={{objectFit: 'cover'}}
                                />
                              </>
                            )}

                            {!(preview !== undefined && preview !== '') && (
                              <>
                                <img
                                  src={toAbsoluteUrl('../media/avatars/blank.png')}
                                  alt='user'
                                  className='rounded-circle'
                                  style={{objectFit: 'cover'}}
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {currentUser?.result.userType === 'SYSTEM' ? (
                        <div className='d-flex flex-center mt-2'>
                          <a
                            className='btn btn-color-primary btn-active-primary btn-sm'
                            onClick={() => {
                              setItemIdForUpdate(user?.code)
                            }}
                          >
                            <i className='fa-solid fa-pen me-1 fs-8'></i>
                            Edit
                          </a>
                        </div>
                      ) : (
                        <div className='d-flex flex-center mt-2'>
                          <a
                            className='btn btn-color-primary btn-active-primary btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <i className='fa-solid fa-pen me-1 fs-8'></i>
                            Edit
                          </a>
                        </div>
                      )}
                    </div>

                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <a className='text-gray-800 fs-2 fw-bolder me-1 text-uppercase'>
                              {user?.firstName} {user?.lastName}
                            </a>
                          </div>

                          <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 '>
                            <a className='d-flex align-items-center text-gray-600  me-5'>
                              <i className='fa-solid fa-envelope me-2'></i>
                              {user?.email}
                            </a>
                            <a className='d-flex align-items-center text-gray-600  me-5 '>
                              <i className='fa-solid fa-phone me-2'></i>
                              {user?.phoneNumber}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='separator border-muted border border-1 border mb-3'></div>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='d-flex flex-column flex-grow-1 pe-8'>
                          <div className='d-flex flex-wrap'>
                            <div className='card-body p-0'>
                              <div className='row mb-3 gy-0'>
                                <div
                                  className={`${
                                    user?.userType === 'CONSUMER'
                                      ? 'col-xl-6 col-lg-12 col-sm-12'
                                      : 'col-xl-12 col-lg-12 col-sm-12'
                                  } `}
                                >
                                  {user?.userType === 'CONSUMER' && (
                                    <div className='row mb-3'>
                                      <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                        <label className='fw-bold text-black fs-6 me-1'>
                                          {' '}
                                          Company Name
                                        </label>
                                        <div className=' d-flex align-items-center justify-content-end'>
                                          :
                                          {/* <span className='badge badge-success'>Verified</span> */}
                                        </div>
                                      </div>

                                      <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                        <span className='fs-6 me-2'>{user?.customerName} </span>
                                        {/* <span className='badge badge-success'>Verified</span> */}
                                      </div>
                                    </div>
                                  )}
                                  {user?.userType === 'SYSTEM' && (
                                    <>
                                      <div className='row mb-3'>
                                        <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                          <label className='fw-bold text-black fs-6 me-1'>
                                            Office Location
                                          </label>
                                          <div className=' d-flex align-items-center justify-content-end'>
                                            :
                                            {/* <span className='badge badge-success'>Verified</span> */}
                                          </div>
                                        </div>

                                        <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                          <span className='fs-6 me-2'>{user?.officeLocation} </span>
                                          {/* <span className='badge badge-success'>Verified</span> */}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {user?.userType === 'INTERPRETER' && (
                                    <>
                                      <div className='row mb-3'>
                                        <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                          <label className='fw-bold text-black fs-6 me-1'>
                                            Gender
                                          </label>
                                          <div className=' d-flex align-items-center justify-content-end'>
                                            :
                                            {/* <span className='badge badge-success'>Verified</span> */}
                                          </div>
                                        </div>

                                        <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                          <span className='fs-6 me-2'>{user?.gender} </span>
                                          {/* <span className='badge badge-success'>Verified</span> */}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {user?.userType !== 'INTERPRETER' && (
                                    <div className='row mb-3'>
                                      <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                        <label className='fw-bold text-black fs-6 me-1'>Role</label>
                                        <div className=' d-flex align-items-center justify-content-end'>
                                          :
                                          {/* <span className='badge badge-success'>Verified</span> */}
                                        </div>
                                      </div>

                                      <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                        <span className='fs-6 me-2'>{user?.role}</span>
                                        {/* <span className='badge badge-success'>Verified</span> */}
                                      </div>
                                    </div>
                                  )}

                                  <div className='row mb-3'>
                                    <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                      <label className='fw-bold text-black fs-6 me-1'>
                                        Native Language
                                      </label>
                                      <div className=' d-flex align-items-center justify-content-end'>
                                        :
                                        {/* <span className='badge badge-success'>Verified</span> */}
                                      </div>
                                    </div>

                                    <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                      <span className='fs-6 me-2'>{user?.defaultLanguage} </span>
                                      {/* <span className='badge badge-success'>Verified</span> */}
                                    </div>
                                  </div>

                                  <div className='row mb-3'>
                                    <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                      <label className='fw-bold text-black fs-6 me-1'>
                                        Timezone
                                      </label>
                                      <div className=' d-flex align-items-center justify-content-end'>
                                        :
                                        {/* <span className='badge badge-success'>Verified</span> */}
                                      </div>
                                    </div>

                                    <div className='col-lg-8 col-md-8 d-flex align-items-center'>
                                      <span className='fs-6 me-2'>
                                        {user?.defaultTimeZone ? (
                                          <> {user?.defaultTimeZone}</>
                                        ) : (
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr010.svg'
                                            className='svg-icon-gray-700 svg-icon-2'
                                          />
                                        )}
                                      </span>
                                      {/* <span className='badge badge-success'>Verified</span> */}
                                    </div>
                                  </div>

                                  <div className='row mb-3'>
                                    <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                      <label className='fw-bold text-black fs-6 me-1'>
                                        {' '}
                                        Address
                                      </label>
                                      <div className=' d-flex align-items-center justify-content-end'>
                                        :
                                        {/* <span className='badge badge-success'>Verified</span> */}
                                      </div>
                                    </div>

                                    <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                      <span className='fs-6 me-2'>{user?.address} </span>
                                      {/* <span className='badge badge-success'>Verified</span> */}
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <label className='col-lg-4 fw-bold text-black fs-6' hidden>
                                      Overview :
                                    </label>
                                    <div className='col-lg-8 d-flex align-items-center'>
                                      <span className='badge badge-dark me-2' hidden>
                                        {user?.defaultLanguage}
                                      </span>
                                      <span className='badge badge-danger me-2' hidden>
                                        {user?.serviceType}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-12 col-sm-12 '>
                                  {user?.userType === 'CONSUMER' && (
                                    <>
                                      <div className='row mb-3'>
                                        <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                          <label className='fw-bold text-black fs-6 me-1'>
                                            Service Type(s)
                                          </label>
                                          <div className=' d-flex align-items-center justify-content-end'>
                                            :
                                            {/* <span className='badge badge-success'>Verified</span> */}
                                          </div>
                                        </div>

                                        <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                          <span className='fs-6 me-2'>
                                            {' '}
                                            {user?.serviceTypes
                                              ?.map((item: any) => item?.label)
                                              .join(', ')}
                                          </span>

                                          {/* <span className='badge badge-success'>Verified</span> */}
                                        </div>
                                      </div>
                                      <div className='row mb-3'>
                                        <div className='col-lg-4 col-md-4 d-flex justify-content-between'>
                                          <label className='fw-bold text-black fs-6 me-1'>
                                            Default Service Type
                                          </label>
                                          <div className=' d-flex align-items-center justify-content-end'>
                                            :
                                            {/* <span className='badge badge-success'>Verified</span> */}
                                          </div>
                                        </div>

                                        <div className='col-lg-8 col-sm-8 d-flex align-items-center justify-content-start'>
                                          <span className='fs-6 me-2'>{user?.serviceType} </span>
                                          {/* <span className='badge badge-success'>Verified</span> */}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {user?.userType == 'CONSUMER' && <></>}

        {isLoadingCustomer ? (
          <div className='card mb-5 mb-xl-5 shadow-sm'>
            <div className='card-body pt-9 pb-4'>
              <span
                className='indicator-progress p-5'
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
              >
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          </div>
        ) : (
          <>
            {user?.userType == 'INTERPRETER' || user?.userType == 'SYSTEM' ? (
              <></>
            ) : (
              <div className='card mb-5 mb-xl-5 shadow-sm'>
                <div className='card-body pt-9 pb-4'>
                  <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='pe-7 mb-4 border-2 border-end border-secondary'>
                      <div className='d-flex align-items-start mb-2 flex-column'>
                        <h5 className='text-black fs-3 fw-bold'>IVR Number/PIN</h5>
                        <h5 className='text-gray-600 text-hover-primary fw-normal me-1'>
                          {user?.ivr}
                        </h5>
                        <div
                          className='px-3 py-2 rounded border border-dashed mt-2 bg-white border-gray-300 '
                          style={{minWidth: '140px'}}
                          onMouseEnter={() => setShowPin(true)}
                          onMouseLeave={() => setShowPin(false)}
                        >
                          <span className='fw-bold text-black fs-1'>
                            {' '}
                            {showPin ? `#${user?.pinCodeString}` : '#⁕⁕⁕⁕⁕⁕⁕⁕'}
                          </span>
                        </div>
                        <span
                          className='text-primary mt-2 fw-bold'
                          style={{fontSize: '12px', cursor: 'pointer'}}
                        >
                          {' '}
                          (Hover to see your PIN){' '}
                        </span>
                      </div>
                    </div>

                    <div className='ps-0 ps-sm-7 flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center'>
                            <h5 className='text-black fs-3 fw-bold '>
                              Pre-Authenticated PIN Management
                            </h5>
                          </div>

                          <div className='d-flex flex-wrap fs-6'>
                            <p className='text-gray-500' style={{fontSize: '12px'}}>
                              Add/Update numbers that can call directly into IVR without a PIN
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {(user?.userType == 'INTERPRETER' || user?.userType == 'CONSUMER') && (
          <>
            {currentUser?.result.userType == 'SYSTEM' && (
              <div
                className='card mb-5 mb-xl-5 shadow-sm'
                hidden={user?.userType == 'CONSUMER' && true}
              >
                <div className='card-body p-0'>
                  <UsersViewTable
                    className=''
                    userType='ADMIN'
                    customerCode={id ?? currentUser?.result.code}
                  />
                </div>
              </div>
            )}
            {currentUser?.result.userType == 'INTERPRETER' && id == currentUser?.result.code && (
              <div className='card shadow-sm mb-5'>
                <div className='card-body p-0'>
                  <UsersViewTable
                    className=''
                    userType='USER'
                    customerCode={id ?? currentUser?.result.code}
                  />
                </div>
              </div>
            )}
            {!(currentUser?.result.userType === 'INTERPRETER' && id === currentUser?.result.code) &&
              currentUser?.result.userType !== 'SYSTEM' &&
              currentUser?.result.userType !== 'CONSUMER' && (
                <div className='card mb-5 mb-xl-5 shadow-sm'>
                  <div className='card-body pt-9 pb-4'>
                    <UsersViewTable className='' userType='VISITOR' customerCode={id ?? ''} />
                  </div>
                </div>
              )}
            <div className='card mb-5 mb-xl-5 shadow-sm '>
              {currentUser?.result.userType === 'CONSUMER' && <ConsumerLangugeTable />}
            </div>

            <div className='card mb-5 mb-xl-5 shadow-sm '>
              <div className='card-body pt-9 pb-4'>
                <div className='col-md-12 col-sm-12'>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h5 className='text-black fs-3 fw-bold'>Permissions</h5>
                    {currentUser?.result.userType === 'SYSTEM' && (
                      <button className='btn btn-sm btn-primary' onClick={() => setShowModal(true)}>
                        <i className='fa-solid fa-pen '></i>
                        Edit
                      </button>
                    )}
                  </div>
                  <div className='separator separator border-muted border mb-3'></div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='col-lg-6 fw-bold text-black fs-6'> OPI</label>

                        <div className='col-lg-6 d-flex align-items-start flex-column'>
                          <span className=' fs-6 me-2'>
                            {user?.opI_ShdTelephonic && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-success svg-icon-2 me-2'
                              />
                            )}
                            {!user?.opI_ShdTelephonic && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon-danger svg-icon-2 me-2'
                              />
                            )}{' '}
                            Scheduled Telephonic
                          </span>
                          <span className=' fs-6 me-2'>
                            {user?.opI_OndemandTelephonic && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-success svg-icon-2 me-2'
                              />
                            )}
                            {!user?.opI_OndemandTelephonic && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon-danger svg-icon-2 me-2'
                              />
                            )}
                            On-Demand Telephonic
                          </span>
                        </div>
                      </div>
                      {/**hide VRI from production*/}

                      <div className='mb-3'>
                        <label className='col-lg-6 fw-bold text-black fs-6'> VRI</label>

                        <div className='col-lg-6 d-flex align-items-start flex-column'>
                          <span className=' fs-6 me-2'>
                            {user?.vrI_ShdVideoInteroreting && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-success svg-icon-2 me-2'
                              />
                            )}
                            {!user?.vrI_ShdVideoInteroreting && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon-danger svg-icon-2 me-2'
                              />
                            )}
                            Scheduled Video Interpreting
                          </span>
                          <span className=' fs-6 me-2'>
                            {user?.vrI_OndemandVideoInteroreting && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-success svg-icon-2 me-2'
                              />
                            )}
                            {!user?.vrI_OndemandVideoInteroreting && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon-danger svg-icon-2 me-2'
                              />
                            )}
                            On-Demand Video Interpreting
                          </span>
                        </div>
                      </div>

                      {/**add other part section here, after develop VRI need to removed code comments code line 2389 - 2409 */}
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-3'>
                        <label className='col-lg-6 fw-bold text-black fs-6'> OSI</label>

                        <div className='col-lg-6 d-flex align-items-start flex-column'>
                          <span className=' fs-6 me-2'>
                            {user?.osI_OnsiteConsecutive && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-success svg-icon-2 me-2'
                              />
                            )}
                            {!user?.osI_OnsiteConsecutive && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon-danger svg-icon-2 me-2'
                              />
                            )}
                            Onsite Consecutive
                          </span>
                          <span className=' fs-6 me-2'>
                            {user?.osI_OnsiteSimultaneous && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-success svg-icon-2 me-2'
                              />
                            )}
                            {!user?.osI_OnsiteSimultaneous && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon-danger svg-icon-2 me-2'
                              />
                            )}
                            Onsite Simultaneous
                          </span>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label className='col-lg-6 fw-bold text-black fs-6'> OTHER</label>

                        <div className='col-lg-6 d-flex align-items-start flex-column'>
                          <span className=' fs-6 me-2'>
                            {user?.other_3rdPartyPlatform && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr016.svg'
                                className='svg-icon-success svg-icon-2 me-2'
                              />
                            )}
                            {!user?.other_3rdPartyPlatform && (
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon-danger svg-icon-2 me-2'
                              />
                            )}
                            Third Party Platform
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!user?.other_3rdPartyPlatform &&
                  !user?.osI_OnsiteConsecutive &&
                  !user?.osI_OnsiteSimultaneous &&
                  !user?.opI_OndemandTelephonic &&
                  !user?.opI_ShdTelephonic &&
                  !user?.vrI_OndemandVideoInteroreting &&
                  !user?.vrI_ShdVideoInteroreting && (
                    <div className='alert alert-info d-flex align-items-center p-5 mt-3'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen048.svg'
                        className='svg-icon-danger svg-icon-2hx me-2'
                      />

                      <div className='d-flex flex-column fw-semibold'>
                        <span>Permissions must be added to accept calls</span>
                      </div>
                    </div>
                  )}

                <PermissionsModal
                  show={showModal}
                  handleClose={() => {
                    setShowModal(false)
                  }}
                  handleSave={(updateData: any, errorMessage?: string, list?: any) => {
                    setPermissionEditData(updateData)
                    setPermissionErrorTypes(list)
                    if (errorMessage) {
                      setPermissionError(errorMessage)
                      setShowConfirmationModal(true)
                    }
                    setShowModal(false)
                  }}
                  formik={formik}
                  id={user?.code}
                  refetchCsutomer={refetchCsutomer}
                />
                <ConfirmationModal
                  show={showConfirmationModal}
                  handleClose={() => setShowConfirmationModal(false)}
                  permissionError={permissionError}
                  updateData={permissionEditData}
                  list={permissionErrorTypes}
                  refetchCsutomer={refetchCsutomer}
                />
              </div>
            </div>
          </>
        )}
        {(user?.userType === 'INTERPRETER' || user?.userType === 'SYSTEM') && (
          <div className='card mb-5 mb-xl-5 shadow-sm'>
            <div className='card-body pt-9 pb-4'>
              <div className='row mb-5 g-6'>
                {isLoadingCustomer ? (
                  <>
                    <span
                      className='indicator-progress p-5'
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </>
                ) : (
                  <>
                    <div className='col-md-12 col-sm-12'>
                      <div className='card-body pt-0 pb-2 px-0'>
                        {user && (
                          <>
                            <ListWrapper
                              userType={currentUser?.result.code ? 'USER' : 'VISITOR'}
                              userID={user?.code ?? ''}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {MyProfileView}

// Insert the styles into the document head
const styleSheet = document.createElement('style')
styleSheet.innerText = styles
document.head.appendChild(styleSheet)
