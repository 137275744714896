import {FC, useState, useEffect, useCallback} from 'react'
import {Customer} from '../../core/_models'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {KTSVG, toAbsoluteUrl, roleQUERIES} from '../../../../../../_metronic/helpers'
import {
  sendInvitation,
  getCustomers,
  createCustomer,
  getCountries,
  checkCustomerEmail,
} from '../../core/_requests'
import {
  getMasterData,
  getNativeLanguage,
  getTimeZone,
} from '../../../../Common/components/core/_requests'

import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useQuery} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import clsx from 'clsx'
import toaster from '../../../../../../Utils/toaster'
import debounce from 'lodash/debounce'
import Select from 'react-select'
import './Style.css'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete'
import {UserListFilterDropdown} from './UserListFilterDropdown'
import {Modal} from 'bootstrap'

type Props = {
  isUserLoading?: boolean
  userType: string
}

interface RenderFuncProps {
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>
  getSuggestionItemProps: (suggestion: Suggestion) => React.HTMLAttributes<HTMLDivElement>
  suggestions: any
  loading?: boolean // Optional loading indicator
  error: any
}

const center = {
  lat: -3.745,
  lng: -38.523,
}

const UsersListHeader: React.FC<Props> = ({userType, isUserLoading}) => {
  const [data, setData] = useState<string[]>([])
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const {selected} = useListView()
  const editCustomerSchema = Yup.object().shape({
    adminEmail: Yup.string().email('Please enter valid email!').required('Required'),
    name: Yup.string()
      .required('Required')
      .matches(/^[^ ].*$/, 'Should not start with a space'),
    fK_NativeLanguage: Yup.string()
      .required('Required')
      .notOneOf([''], 'Please select a native language'),
    fK_ServiceType: Yup.array().required('Required').min(1, 'Required'),
    //fK_Country: Yup.string().required('Required').notOneOf([''], 'Please select a country'),
    fK_DefaultTimeZone: Yup.string().required('Required'),
    uniqueIdentifier: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    street1: Yup.string(),
    street2: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
    latitude: Yup.number(),
    longitude: Yup.number(),
  })

  const [customerForEdit] = useState<Customer>({
    name: '',
    adminEmail: '',
    isInformedViaEmail: false,
    fK_NativeLanguage: 'EN',
    fK_ServiceType: [],
    //fK_Country: '',
    fK_DefaultTimeZone: '',
    uniqueIdentifier: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    street1: '',
    street2: '',
    latitude: '',
    longitude: '',
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    () => {
      return getNativeLanguage()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
    `Query.serviceTypeList`,
    () => {
      return getMasterData('SERVICE_TYPE')
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingCountry, data: responseCountry} = useQuery(
    `${roleQUERIES.Country_LIST}`,
    () => {
      return getCountries()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const formik = useFormik({
    initialValues: {
      ...customerForEdit,
      //fK_Country: customerForEdit.fK_Country || 'USA',
    },
    validationSchema: editCustomerSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (values.isInformedViaEmail == undefined) {
          values.isInformedViaEmail = false
        }

        var result = await createCustomer(values)
        if (result?.status == 'S') {
          toaster('success', result?.text ?? 'Record Updated')
          const modal = document.getElementById('kt_modal_1')
          if (modal) {
            const modalInstance = Modal.getInstance(modal)
            if (modalInstance) modalInstance.hide()
          }
          formik.resetForm()
        } else if (result?.status == 'E') {
          toaster('error', result?.text ?? 'Record Not Updated')
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  // Debounce function to handle API call
  const debouncedApiCall = useCallback(
    debounce(async (value) => {
      try {
        if (value != null) {
          var result = await checkCustomerEmail(value)
          if (result) {
            formik.setFieldValue('adminEmail', '')
            toaster('error', `'${value}' is already used`)
          }
        }
      } catch (error) {
        formik.setFieldError('adminEmail', 'Required') // Set Formik error based on API response
      }
    }, 2000), // 500ms debounce delay
    []
  )

  const handleChange = (e: any) => {
    formik.handleChange(e)
    debouncedApiCall(e.target.value)
  }

  const {isFetching: isFetchingTimeZone, data: timeZoneList} = useQuery(
    `Query.timeZoneList`,
    () => {
      return getTimeZone()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const options =
    !isFetchingCountry && responseCountry?.data
      ? responseCountry.data.map((d) => ({
          value: d.key,
          label: d.value,
        }))
      : []
  const languageOptions = nativeLanguageList?.data?.map((d) => ({
    value: d.key,
    label: d.value,
  }))
  const serviceTypeOptions = servicetypeList?.data?.map((d) => ({
    value: d.key,
    label: d.value,
  }))

  const timezoneOptions: any = !isFetchingTimeZone
    ? timeZoneList?.data?.map((d: any) => ({
        value: d.code,
        label: d.name,
      }))
    : []

  const renderFunc: React.FC<RenderFuncProps> = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
    error,
  }) => (
    <div className='autocomplete-root'>
      <input
        className={clsx('form-control form-control-sm form-control-light mb-3 custom-input-height')}
        disabled={formik.isSubmitting || isUserLoading}
        placeholder='Address'
        {...getInputProps()}
      />
      {error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
      <div className='autocomplete-dropdown-container'>
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.placeId}
            className='bg-light p-2 border border-bottom overflow-auto'
            style={{cursor: 'pointer'}}
          >
            <option>{suggestion.description}</option>
          </div>
        ))}
      </div>
    </div>
  )

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address)
      const placeDetails = await getLatLng(results[0])

      // Extract detailed address components
      let street1 = ''
      let street2 = ''
      let city = ''
      let state = ''
      let country = ''
      let postalCode = ''

      results[0].address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          street1 = component.long_name
        } else if (component.types.includes('route')) {
          street2 = component.long_name
        } else if (component.types.includes('locality')) {
          city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name
        } else if (component.types.includes('country')) {
          country = component.long_name
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
      })

      formik.setFieldValue('address', address)
      formik.setFieldValue('street1', street1)
      formik.setFieldValue('street2', street2)
      formik.setFieldValue('city', city)
      formik.setFieldValue('state', state)
      formik.setFieldValue('country', country)
      formik.setFieldValue('postalCode', postalCode)
      formik.setFieldValue('latitude', placeDetails.lat)
      formik.setFieldValue('longitude', placeDetails.lng)
      // Select the input element with id "city"
      const cityInputElement = document.querySelector<HTMLInputElement>('#city')

      // Ensure the cityInputElement exists before focusing on it
      if (cityInputElement) {
        cityInputElement.focus() // Focuses on the input field
      }
    } catch (error) {
      console.error('Error fetching place details', error)
    }
  }

  return (
    <div className='card-header align-items-center justify-content-end'>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='card-toolbar'>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog modal-xl'>
              <div className='modal-content'>
                <div className='modal-header py-2'>
                  <h4 className='modal-title'>Add Customer</h4>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => formik.resetForm()}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='row g-4 g-xl-6'>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                      <div className='mb-1'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 fs-7'
                        >
                          Company Name
                        </label>
                        <div className='d-flex align-items-center'>
                          <input
                            {...formik.getFieldProps('name')}
                            name='name'
                            className={clsx(
                              'form-control form-control-white form-select-sm mb-2 custom-input-height'
                            )}
                            type='text'
                            placeholder='Enter Company Name'
                          />
                        </div>
                        {formik.errors.name && formik.touched.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.name}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='row'>
                        <div className='col-sm-12 mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1 fs-7'
                          >
                            Address
                          </label>
                          {typeof window.google !== 'undefined' && (
                            <PlacesAutocomplete
                              onSelect={(suggestion: any) => {
                                handleSelect(suggestion)
                              }}
                              value={formik.values.address}
                              onChange={(e: any) => {
                                if (e === '') {
                                  formik.setFieldValue('address', '')
                                  formik.setFieldValue('street1', '')
                                  formik.setFieldValue('street2', '')
                                  formik.setFieldValue('city', '')
                                  formik.setFieldValue('state', '')
                                  formik.setFieldValue('country', '')
                                  formik.setFieldValue('postalCode', '')
                                  formik.setFieldValue('latitude', center.lat)
                                  formik.setFieldValue('longitude', center.lng)
                                }
                                formik.setFieldValue('address', e)
                              }}
                            >
                              {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                                <div>
                                  {renderFunc({
                                    getInputProps,
                                    getSuggestionItemProps,
                                    suggestions,
                                    loading,
                                    error: formik.errors.address,
                                  })}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          )}
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1'
                          >
                            City
                          </label>
                          <input
                            placeholder='City'
                            {...formik.getFieldProps('city')}
                            type='text'
                            name='city'
                            id='city'
                            className={clsx(
                              'form-control form-control-sm form-control-light custom-input-height'
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                          />
                          {formik.errors.city && formik.touched.city && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.city}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1 fs-7 mb-0'
                          >
                            State
                          </label>
                          <input
                            placeholder='State'
                            {...formik.getFieldProps('state')}
                            type='text'
                            name='state'
                            id='state'
                            className={clsx(
                              'form-control form-control-sm form-control-light custom-input-height'
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                          />
                          {formik.errors.state && formik.touched.state && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.state}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1 fs-7 mb-0'
                          >
                            Country
                          </label>
                          <input
                            placeholder='Country'
                            {...formik.getFieldProps('country')}
                            type='text'
                            name='country'
                            id='country'
                            className={clsx(
                              'form-control form-control-sm form-control-light custom-input-height'
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                          />
                          {formik.errors.country && formik.touched.country && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.country}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1 fs-7 mb-0'
                          >
                            Postal Code
                          </label>
                          <input
                            placeholder='Postal Code'
                            {...formik.getFieldProps('postalCode')}
                            type='text'
                            name='postalCode'
                            id='postalCode'
                            className={clsx(
                              'form-control form-control-sm form-control-light custom-input-height'
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                          />
                          {formik.errors.postalCode && formik.touched.postalCode && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.postalCode}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='mb-3'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 fs-7 mb-0'
                        >
                          Company E-mail Address
                        </label>
                        <div className='d-flex align-items-center'>
                          <input
                            {...formik.getFieldProps('adminEmail')}
                            name='adminEmail'
                            type='email'
                            className={clsx(
                              'form-control form-control-white form-select-sm mb-2 custom-input-height'
                            )}
                            placeholder='Enter E-mail Address'
                            onChange={handleChange}
                            value={formik.values.adminEmail}
                          />
                        </div>
                        {formik.errors.adminEmail && formik.touched.adminEmail && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.adminEmail}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                      <div className='mb-3'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 fs-7'
                        >
                          Native Language
                        </label>
                        <div>
                          <div className='w-100'>
                            <Select
                              name='fK_NativeLanguage'
                              isDisabled={formik.isSubmitting || isUserLoading}
                              placeholder='Select a Language'
                              value={
                                languageOptions?.find(
                                  (option) => option.value === formik.values.fK_NativeLanguage
                                ) || null
                              }
                              onChange={(option) =>
                                formik.setFieldValue('fK_NativeLanguage', option?.value || '')
                              }
                              options={!isFetchingNativeLanguage ? languageOptions : []}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: '#a4afc4',
                                }),
                              }}
                              onBlur={() => formik.setFieldTouched('fK_NativeLanguage', true)}
                            />
                            {formik.errors.fK_NativeLanguage && formik.touched.fK_NativeLanguage && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.fK_NativeLanguage}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 fs-7'
                        >
                          Service Type(s)
                        </label>
                        <div>
                          <div className='w-100'>
                            <Select
                              name='fK_ServiceType'
                              isMulti
                              isDisabled={formik.isSubmitting || isUserLoading}
                              placeholder='Select Service Types'
                              value={
                                serviceTypeOptions?.filter(
                                  (option) =>
                                    Array.isArray(formik.values.fK_ServiceType) &&
                                    formik.values.fK_ServiceType.includes(option.value)
                                ) || []
                              }
                              onChange={(selectedOptions) => {
                                formik.setFieldValue(
                                  'fK_ServiceType',
                                  selectedOptions
                                    ? selectedOptions.map((option) => option.value)
                                    : []
                                )
                              }}
                              options={!isFetchingServiceType ? serviceTypeOptions : []}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: '#a4afc4',
                                }),
                              }}
                              onBlur={() => formik.setFieldTouched('fK_ServiceType', true)}
                            />

                            {formik.errors.fK_ServiceType && formik.touched.fK_ServiceType && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{String(formik.errors.fK_ServiceType)}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 fs-7'
                        >
                          Default Time Zone
                        </label>
                        <div>
                          <div className='w-100'>
                            <Select
                              {...formik.getFieldProps('fK_DefaultTimeZone')}
                              name='fK_DefaultTimeZone'
                              placeholder='Select a timezone'
                              options={!isFetchingTimeZone ? timezoneOptions : []}
                              value={
                                timezoneOptions?.find(
                                  (option: any) => option.value === formik.values.fK_DefaultTimeZone
                                ) || null
                              }
                              onChange={(option) =>
                                formik.setFieldValue('fK_DefaultTimeZone', option?.value || '')
                              }
                              isDisabled={formik.isSubmitting || isUserLoading}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: '#a4afc4',
                                }),
                              }}
                              onBlur={() => formik.setFieldTouched('fK_DefaultTimeZone', true)}
                            />
                            {formik.errors.fK_DefaultTimeZone && formik.touched.fK_DefaultTimeZone && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.fK_DefaultTimeZone}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='mb-3'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='required form-label fs-7 mb-1 fs-7 '
                        >
                          Unique Identifier
                        </label>
                        <div className='d-flex align-items-center'>
                          <input
                            {...formik.getFieldProps('uniqueIdentifier')}
                            name='uniqueIdentifier'
                            className={clsx(
                              'form-control form-control-white form-select-sm mb-2 custom-input-height'
                            )}
                            type='text'
                            placeholder='Enter Unique Identifier'
                          />
                        </div>
                        {formik.errors.uniqueIdentifier && formik.touched.uniqueIdentifier && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.uniqueIdentifier}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='modal-footer py-3'>
                  <button
                    type='button'
                    className='btn btn-light btn-sm'
                    data-bs-dismiss='modal'
                    onClick={() => formik.resetForm()}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-sm btn-primary'
                    disabled={isUserLoading || formik.isSubmitting}
                  >
                    {formik.values.isInformedViaEmail && (
                      <span className='indicator-label'>Send Invitations</span>
                    )}
                    {!formik.values.isInformedViaEmail && (
                      <span className='indicator-label'>Create</span>
                    )}
                    {(formik.isSubmitting || isUserLoading) && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center me-3'>
            <UsersListSearchComponent />
          </div>
          <div className='me-3'>
            <UserListFilterDropdown />
          </div>
          <div>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id='tooltip-filter'>Add Customer</Tooltip>}
            >
              <div>
                <button
                  type='button'
                  className='btn btn-sm btn-primary btn-icon'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                >
                  <i className='bi bi-plus fs-2'></i>
                </button>
              </div>
            </OverlayTrigger>
          </div>

          <div>{selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}</div>
        </div>
      </form>
    </div>
  )

  // return (
  //   <div className='card-header px-0'>
  //     <div className='card-title d-flex align-items-center position-relative me-4 '>
  //       {
  //         (userType == "CONSUMER" && <> Connected Users for Ad Astra</>)
  //       }
  //       {
  //         (userType == "INTERPRETER" && <> Connected Interpreters for Ad Astra Internal</>)
  //       }
  //       {
  //         (userType == "SYSTEM" && <> Connected Administrators for Ad Astra Internal</>)
  //       }
  //     </div>
  //     <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
  //       <div className='card-toolbar'>
  //         <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
  //           <div className='modal-dialog'>
  //             <div className='modal-content'>
  //               <div className='modal-header py-2'>
  //                 <h4 className='modal-title'>Invite Your Team</h4>
  //                 <div
  //                   className='btn btn-icon btn-sm btn-active-light-primary ms-2'
  //                   data-bs-dismiss='modal'
  //                   aria-label='Close'
  //                 >
  //                   <KTSVG
  //                     path='/media/icons/duotune/arrows/arr061.svg'
  //                     className='svg-icon svg-icon-2x'
  //                   />
  //                 </div>
  //               </div>
  //               <div className='modal-body'>
  //                 <div className='row g-4 g-xl-6'>
  //                   <div className='col-sm-12 col-md-12 col-lg-12'>
  //                     <div className=''>
  //                       <label htmlFor='exampleFormControlInput1' className='required form-label'>
  //                         Add e-mail addresses of users you wish to invite
  //                       </label>
  //                       <div className='row g-4 g-xl-6 d-flex align-items-center'>
  //                         <div className='col-sm-9 col-md-9 col-lg-9'>
  //                           <input
  //                             onChange={(event) => setEmail(event.target?.value)}
  //                             placeholder='Enter Email Address'

  //                             className={clsx(
  //                               'form-control form-control-solid form-select-sm mb-3 mb-lg-0',
  //                               { 'is-invalid': formik.touched.email && formik.errors.email },
  //                               {
  //                                 'is-valid': formik.touched.email && !formik.errors.email,
  //                               }
  //                             )}
  //                             value={email}
  //                             type='email'
  //                             name='email'
  //                             autoComplete='off'
  //                             disabled={formik.isSubmitting}
  //                           />
  //                           {/* end::Input */}
  //                           {formik.touched.email && formik.errors.email && (
  //                             <div className='fv-plugins-message-container'>
  //                               <span role='alert'>{formik.errors.email}</span>
  //                             </div>
  //                           )}

  //                         </div>
  //                         <div className='col-sm-3 col-md-3 col-lg-3 d-flex flex-end'>
  //                           <button type='button' onClick={(e) => func_addemail(email)} className='btn btn-sm btn-primary'>
  //                             <i className='bi bi-plus fs-2'></i>Add
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className='col-sm-12 col-md-12 col-lg-12'>
  //                     <div className='table-responsive'>
  //                       {/* begin::Table */}
  //                       <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
  //                         {/* begin::Table head */}
  //                         <thead>
  //                           <tr className='fw-semibold text-muted text-uppercase'>
  //                             <th className='min-w-150px '>Email</th>
  //                             <th className='min-w-100px text-end'>Action</th>
  //                           </tr>
  //                         </thead>
  //                         {/* end::Table head */}
  //                         {/* begin::Table body */}
  //                         <tbody>

  //                           {!isLoading && data.map((d, key) => (
  //                             <tr>
  //                               <td>
  //                                 <a className='text-gray-800 text-hover-primary fs-6'>
  //                                   {d}
  //                                 </a>
  //                               </td>

  //                               <td>
  //                                 <div className='d-flex justify-content-end flex-shrink-0'>
  //                                   <button onClick={(e) => func_removeemail(d)}
  //                                     className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
  //                                     type='button'
  //                                   >
  //                                     <KTSVG
  //                                       path='/media/icons/duotune/general/gen027.svg'
  //                                       className='svg-icon-3'
  //                                     />
  //                                   </button>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                           ))}

  //                         </tbody>
  //                         {/* end::Table body */}
  //                       </table>
  //                       {/* end::Table */}
  //                     </div>
  //                     {/* <div className='form-check-custom form-check-solid form-check-sm'>
  //                     <input
  //                       className='form-check-input'
  //                       type='checkbox'
  //                       value=''
  //                       id='flexCheckDefault'
  //                     />
  //                     <label
  //                       className='form-check-label form-check-sm'
  //                       htmlFor='flexCheckDefault'
  //                     >
  //                       Suppress Invitation Email
  //                     </label>
  //                   </div> */}
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className='modal-footer py-3'>
  //                 <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
  //                   Close
  //                 </button>
  //                 <button
  //                   type='submit'
  //                   className='btn btn-primary'
  //                   data-bs-dismiss='modal'
  //                   data-kt-users-modal-action='submit'
  //                   disabled={isUserLoading || formik.isSubmitting}
  //                 >
  //                   <span className='indicator-label'>Send Invitations</span>
  //                   {(formik.isSubmitting || isUserLoading) && (
  //                     <span className='indicator-progress'>
  //                       Please wait...{' '}
  //                       <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
  //                     </span>
  //                   )}
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
  //           <div className='modal-dialog'>
  //             <div className='modal-content'>
  //               <div className='modal-body'>
  //                 <div className='row g-4 g-xl-6'>
  //                   <div className='col-sm-12 col-md-12 col-lg-12'>
  //                     <div className='text-center'>
  //                       <KTSVG
  //                         path='/media/icons/duotune/general/gen044.svg'
  //                         className='svg-icon-info svg-icon-5hx'
  //                       />
  //                       <h1>Confirmation</h1>
  //                       <span> Are you sure you'd like to re-invite all individuals?</span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className='modal-footer py-3'>
  //                 <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
  //                   Cancel
  //                 </button>
  //                 <Link to='#'>
  //                   <button
  //                     type='button'
  //                     className='btn btn-primary btn-sm'
  //                     data-bs-dismiss='modal'
  //                   >
  //                     Re-Invite
  //                   </button>
  //                 </Link>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='d-flex align-items-center position-relative me-4 '>
  //           <span className='svg-icon svg-icon-3 position-absolute ms-3'>
  //             <svg
  //               width='24'
  //               height='24'
  //               viewBox='0 0 24 24'
  //               fill='none'
  //               xmlns='http://www.w3.org/2000/svg'
  //               className='mh-50px'
  //             >
  //               <rect
  //                 opacity='0.5'
  //                 x='17.0365'
  //                 y='15.1223'
  //                 width='8.15546'
  //                 height='2'
  //                 rx='1'
  //                 transform='rotate(45 17.0365 15.1223)'
  //                 fill='currentColor'
  //               ></rect>
  //               <path
  //                 d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
  //                 fill='currentColor'
  //               ></path>
  //             </svg>
  //           </span>
  //           <UsersListSearchComponent />
  //         </div>
  //         <div>
  //           {(userType == "CONSUMER" && <button
  //             type='button'
  //             className='btn btn-sm btn-primary fs-6 me-2'
  //             data-bs-toggle='modal'
  //             data-bs-target='#kt_modal_1'
  //           >
  //             <i className='bi bi-person-fill me-2'></i>Send Invitation
  //           </button>)}

  //         </div>
  //         <div>
  //           {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
  //           {/* <button
  //             type='button'
  //             className='btn btn-sm btn-primary fs-6'
  //             data-bs-toggle='modal'
  //             data-bs-target='#kt_modal_2'
  //           >
  //             <i className='bi bi-people-fill me-2'></i> Invite All
  //           </button> */}
  //         </div>
  //       </div>
  //     </form>
  //   </div >

  // )

  // return (
  //   <div className='card-header border-0 pt-6'>
  //     <UsersListSearchComponent />
  //     {/* begin::Card toolbar */}
  //     <div className='card-toolbar'>
  //       {/* begin::Group actions */}
  //       {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
  //       {/* end::Group actions */}
  //     </div>
  //     {/* end::Card toolbar */}
  //   </div>
  // )
}

export {UsersListHeader}
