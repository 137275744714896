import {useEffect, useMemo, useState} from 'react'
import {useAuth} from './modules/auth'
import {Helmet} from 'react-helmet'

const RequesterWidget = () => {
  const {currentUser, auth} = useAuth()
  const [isConsumer, setIsConsumer] = useState(false)

  useEffect(() => {
    if (
      auth?.isAuthenticated &&
      currentUser?.result.userType == 'CONSUMER'
    ) {
      setIsConsumer(true)
    }
    if (isConsumer && !auth?.isAuthenticated) {
      window.location.reload()
    }
  }, [auth, currentUser])

  const Widget = useMemo(() => {
    return (
      <Helmet>
        {auth?.isAuthenticated == true &&
          currentUser?.result.userType == 'CONSUMER' &&
          (process.env.REACT_APP_SERVER == 'DEV') && (
            <script type='text/javascript'>{`
      (function(w, d, x, id){
  s=d.createElement('script');
  s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
  s.async=1;
  s.id=id;
  d.getElementsByTagName('head')[0].appendChild(s);
  w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', '2860e39c-75f2-485c-b974-2e06d172f32b');
  amazon_connect('styles', { iconType: 'CHAT_VOICE', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456'} });
  amazon_connect('snippetId', 'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdGSzNrZThsbXh3UTV0bEdjeGJBb2R0QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNUkN1ZG5HZGtNdllkUjhzdkFnRVFnQ3QwWDE1anNHZ0RYQUtrSEFFVHExRXk1VG12NzZXZDhlb05ZTEJIWGgzeUUrbUozNFp1c29oYklYb2o6Okwrb3B6VUdUQWhQVUhqc0hlM0RwM1kzNzFsdW5ySDRNMzhBcmpYak50cWNZUXJpZFBLdGlNQzJET3dlYWdNSUxJWCtEZlYvSS9YZlNQNW41cytWNzVDYWxlSmxUUm01Wm9vZnIvMGhvUHVZdFQ5b21XQ3lRcjVFM2lENkRyS2lEQTdjR2tFd2VvVE1wd0hLNzFhVk9NSDR3OVUrVjg5ST0=');
  amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  amazon_connect('contactAttributes', {
  AWSUserId : '${currentUser?.result?.awsUserId}'
  })
  `}</script>
          )}
        {auth?.isAuthenticated == true &&
          currentUser?.result.userType == 'CONSUMER' &&
          (process.env.REACT_APP_SERVER == 'QA') && (
            <script type='text/javascript'>{`
  (function(w, d, x, id){
    s=d.createElement('script');
    s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', 'b25bbcdb-bd99-4887-9c2a-71a8f62d4580');
  amazon_connect('styles', { iconType: 'VOICE', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456'} });
  amazon_connect('snippetId', 'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdHRVFjZEJGNGM4Q0szbTVsL1V2dEhqQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNZUtEbE5udDdoMCtCaHhweEFnRVFnQ3VuVXJFQnpxQlJEbkVrUHhRV2JaVjFzV2lEMGNvTlBTUERNUVkyN1VmbzM1eWdNZHg5dXdiV2Y3S2g6OldsU2RDbUFJV2tvUFBHNWpzN1RYdWRwaEo4dkRsUTJqbm5nOWFRSm5BSTM5QkI0SmZFWDZ4YTB0S2NJMlM0MnQ3cytjRmtEbngrSDQwS2dlWDBZUG1sbU44T014TWV2KzErN1lhVERxY2lRRjVPbndtN1Y4OTdJOFdENmRtdjA2cU5FMVkxOWJqd1N0ZVo5WG9URE1kNXZYZlB2U1JJQT0=');
  amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  amazon_connect('contactAttributes', {
  AWSUserId : '${currentUser?.result?.awsUserId}'
  })
  `}</script>
          )}
        {auth?.isAuthenticated == true &&
          currentUser?.result.userType == 'CONSUMER' &&
          (process.env.REACT_APP_SERVER == 'PROD') && (
            <script type='text/javascript'>{`(function(w, d, x, id){
    s=d.createElement('script');
    s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', '5c174333-57aa-4e44-b008-9f7d2227ade5');
  amazon_connect('styles', { iconType: 'VOICE', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456'} });
  amazon_connect('snippetId', 'QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdHayt6dDBVNnI1a3pTR0ZldFRCdU5lQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNME8yYy91VmxmTDNIZThNZkFnRVFnQ3M0TlYzT2pUNitQajVNRFdjNUxJMXowTTNMWDZqMWIxUVFZaC9NK05jOTREdDY2Y2tqQWorOFM0YjU6OkhjaFJuM1NzdHhHZVY4RHErNnhUaXhyR1AvVjk3cHM1dmttcHJlUytSWUxPUXlIaGpMZURjbGNydXpBNlZNaElkSjVuM2U0OVVmRHR6SXpvcFprWDdkMnpTQ3UxRDYxZUN5ZyttTnQzVXRHeHhRNGJJL3FVK3NzaDJOOCtkSHBvVlgwRnNGODdxQjdCYUZxa0M2VCt0cC9EMGdNSVlRMD0=');
  amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
   amazon_connect('contactAttributes', {
  AWSUserId : '${currentUser?.result?.awsUserId}'
  })`}</script>
          )}
        <script
          type='text/javascript'
          src='https://www.bugherd.com/sidebarv2.js?apikey=zn3taxwqsc5wjxromdl1iw'
          async={true}
        ></script>
        <script src='https://maps.googleapis.com/maps/api/js?key=AIzaSyAwcOSQ6hnqoqiXX_1D1ykHOBAZZ2UorHE&libraries=places'></script>
      </Helmet>
    )
  }, [auth, currentUser])

  return <>{Widget}</>
}

export default RequesterWidget
