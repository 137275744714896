import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_REQUESTERS = `${API_URL}/accounts/ddlist-awsuserid`

interface AwsRequester {
  key: string
  value: string
}

interface AwsRequesterState {
  items: AwsRequester[]
  loading: boolean
  error: string | null
}

const initialState: AwsRequesterState = {
  items: [],
  loading: false,
  error: null,
}

export const fetchAwsRequesters = createAsyncThunk(
  'awsRequester/fetchAwsRequesters',
  async (_, {rejectWithValue}) => {
    try {
      const response = await axios.get(GET_ALL_REQUESTERS)
      return response.data.data as AwsRequester[]
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

const awsRequesterSlice = createSlice({
  name: 'awsRequester',
  initialState,
  reducers: {
    setAwsRequesters: (state, action: PayloadAction<AwsRequester[]>) => {
      state.items = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAwsRequesters.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchAwsRequesters.fulfilled, (state, action: PayloadAction<AwsRequester[]>) => {
        state.loading = false
        state.items = action.payload
      })
      .addCase(fetchAwsRequesters.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  },
})

export const {setAwsRequesters} = awsRequesterSlice.actions
export default awsRequesterSlice.reducer