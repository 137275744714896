import React, {useEffect, useState} from 'react'
import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {AppointmentViewTable} from './AppointmentViewTable'
import {AppointmentFullCalendar} from './AppointmentFullCalendar'
import {NotesView} from './NotesView'
import {useNavigate, useParams} from 'react-router-dom'
import { UpcomingAppointmentViewTable } from './UpcomingAppointmentViewTable'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { fetchCustomers } from '../../../redux/customerSlice'
import { useDispatch } from 'react-redux'
import { fetchLanguages } from '../../../redux/languagesSlice'
import { fetchServicetypes } from '../../../redux/serviceSlice'
import { fetchCommunication } from '../../../redux/communicationSlice'

export function AppointmentView() {
  const {id} = useParams()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<any>(id)
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch()
  useEffect(() => {
    setActiveTab(Number(id))
  }, [id])
  useEffect(() => {
    dispatch(fetchCustomers())
    dispatch(fetchLanguages())
    dispatch(fetchServicetypes())
    dispatch(fetchCommunication())
  }, [dispatch])
  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    navigate(`/appointment/${tabNumber}`)
  }

  return (
    <>
      {/* <div className='mb-3'>
        <h1 className='mb-0 fw-semibold fs-2'>Appointments</h1>
        <span className='text-gray-500'>Manage Appointment and Notes</span>
      </div> */}
      <div className='mb-5'>
        <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
          Appointments
        </h1>
        <p className='text-gray-500 fs-5'>Manage Appointment and Notes</p>
      </div>

      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400   ${
                  activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
                data-testid="appointment-calendar"
                onClick={() => handleTabClick(1)}
              >
                Calendar
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
                data-testid="appointment-table"
                onClick={() => handleTabClick(2)}
              >
                All  Appointments
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_3'
                data-testid="upcoming-appointment-table"
                onClick={() => handleTabClick(3)}
              >
                Upcoming Appointments
              </a>
            </li>
          </ul>
        </div>
        <div className='card-body pt-0'>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              {activeTab === 1 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                  <div className='px-0 py-5 card-body'>
                    <AppointmentFullCalendar />
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                  <div className='px-0 py-5  card-body '>
                    <AppointmentViewTable className={''} />
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                  <div className='px-0 py-5 card-body '>
                    <UpcomingAppointmentViewTable className={''} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
