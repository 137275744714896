/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {DashboardSmallCards} from './DashboardSmallCards'
import '../Logs/style.css'
import {DashboardOperationFilter} from '../DashboardAnalytics/DashboardOperationFilter'
import axios from 'axios'
import {CommonLoading} from '../../../../Utils/commonLoading'
import moment from 'moment'
import clsx from 'clsx'
import './style.css'
import toaster from '../../../../Utils/toaster'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

const DashboardOperation: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [alloperationDetails, setAlloperationDetails] = useState<any>({})
  const [serachQuery, setSearchQuery] = useState({})

  const fetchOperationDetails = async (serachQuery: any) => {
    try {
      let response = await axios.post(`${API_URL}/Dashboard/operations`, serachQuery)
      const {data, payload} = response.data
      setAlloperationDetails(data)
    } catch (error) {
      toaster('error', 'Loading failed!')
      console.log(error)
    }
  }

  useEffect(() => {
    fetchOperationDetails(serachQuery) // Initial fetch on mount

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(() => {
      fetchOperationDetails(serachQuery)
    }, 5000) // 5 seconds in milliseconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId)
  }, [serachQuery]) // Empty dependency array means this effect runs once on mount

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div className='py-2' style={{borderRadius: '6px'}}>
          <div className='d-flex align-items-center'>
            <div className='blinking-dot'></div>
            <h5 className='text-gray-800 fs-6 mb-0 fw-bold ms-4'>Live</h5>
          </div>
        </div>
        <div>
          <DashboardOperationFilter
            setAlloperationDetails={setAlloperationDetails}
            setIsLoading={setIsLoading}
            fetchOperationDetails={fetchOperationDetails}
            setSearchQuery={setSearchQuery}
          />
        </div>
      </div>

      <>
        <div className='row gy-4 mb-1'>
          <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <DashboardSmallCards
              container=''
              className1='bg-success text-white'
              className2='text-white'
              className3='text-white'
              number={alloperationDetails?.available}
              className='text-success'
              description='Available '
              url='bi bi-check-circle-fill'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3'>
            <DashboardSmallCards
              container=''
              className1='bg-danger text-white'
              className2='text-white'
              className3='text-white'
              number={alloperationDetails?.offline}
              className='text-danger'
              description='Offline '
              url='bi bi-wifi-off'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 position-relative'>
            <DashboardSmallCards
              container=''
              className1='bg-white'
              className2='text-gray-600 '
              className3='text-dark'
              number={alloperationDetails?.highHoldTime}
              className='text-warning'
              description='High Hold Time'
              url='bi bi-stopwatch-fill'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 position-relative'>
            <DashboardSmallCards
              container=''
              className1='bg-white'
              className2='text-gray-600 '
              className3='text-dark'
              number={alloperationDetails?.queues}
              className='text-info'
              description='Queues'
              url='bi bi-layer-forward'
            />
          </div>
        </div>
        <div className='row gy-4 gx-6 mb-2'>
          <div className='col-sm-12 col-xl-12  mb-2'>
            <div className='card shadow-custom'>
              <div className='card-header ribbon ribbon-end' style={{minHeight: '50px'}}>
                <div className='ribbon-label bg-info fs-5'>
                  <span className='badge badge-circle badge-light'>
                    {alloperationDetails?.callQueue?.length}
                  </span>
                </div>
                <h3 className='card-title fs-5'>Call Queue </h3>
              </div>
              <div className='card-body py-4'>
                <div className='table-responsive table-container tableFixHead'>
                  <table className='table table-row-dashed table-row-gray-300 gs-2 gy-2 table-responsive'>
                    <thead>
                      <tr className='fw-semibold text-muted'>
                        <th className='min-w-150px text-uppercase'>Call ID</th>
                        <th className='min-w-150px text-uppercase'>Requested Time</th>
                        <th className='min-w-200px text-uppercase'>Requester</th>
                        <th className='min-w-150px text-uppercase'>Customer</th>
                        <th className='min-w-150px text-uppercase '>Call Duration</th>
                        <th className='min-w-150px text-uppercase '>Languages From</th>
                        <th className='min-w-150px text-uppercase '>Languages To</th>
                        <th className='min-w-200px text-uppercase text-end'>Communication Type</th>
                      </tr>
                    </thead>

                    <tbody>
                      {alloperationDetails?.callQueue?.length > 0 ? (
                        alloperationDetails?.callQueue?.map((data: any, index: number) => (
                          <tr
                            key={index}
                            style={{backgroundColor: data?.isHighHoldTime ? '#ffc70099' : ''}}
                          >
                            <td>
                              <a className='text-gray-800 d-block fs-7'>{data?.code ?? 'N/A'}</a>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                {moment(data?.requestedTime).format('DD MMM YYYY hh:mm A')}
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7 d-flex align-items-center'>
                                {data?.profileImageReq !== '' ? (
                                  <div className='symbol symbol-circle symbol-30px mx-2'>
                                    <img
                                      src={
                                        process.env.REACT_APP_IMG_URL +
                                        '/profile/' +
                                        data.profileImageReq
                                      }
                                      alt=''
                                      className='rounded-circle'
                                    />
                                  </div>
                                ) : (
                                  <div className='symbol symbol-circle symbol-30px overflow-hidden mx-2'>
                                    <div
                                      className={clsx(
                                        'symbol-label fs-4',
                                        'badge-light-success',
                                        `bg-light-success`
                                      )}
                                    >
                                      <span style={{fontSize: '10x'}}>
                                        {(data?.requestor ?? '').charAt(0).toUpperCase() +
                                          (data?.requestor ?? '').charAt(1).toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {data?.requestor && data.requestor !== 'N/A' ? (
                                  data.requestor
                                ) : (
                                  <img
                                    src={toAbsoluteUrl('/media/other/DotLoading.gif')}
                                    alt='Loading...'
                                    style={{
                                      height: '24px',
                                      width: 'auto',
                                    }}
                                  />
                                )}
                              </a>
                            </td>

                            <td>
                              <a
                                className='text-gray-800 d-block fs-7'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {data?.accountName && data.accountName !== 'N/A' ? (
                                  data.accountName
                                ) : (
                                  <img
                                    src={toAbsoluteUrl('/media/other/DotLoading.gif')}
                                    alt='Loading...'
                                    style={{height: '24px', width: 'auto'}}
                                  />
                                )}
                              </a>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                {data?.holdTime ? data?.holdTime : 'N/A'}
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                <span className='badge badge-secondary me-1'>English</span>
                              </a>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                <span className='badge badge-secondary me-1'>
                                  {data?.languages ?? 'N/A'}
                                </span>
                              </a>
                            </td>

                            <td className='text-end'>
                              <a className='text-gray-800 d-block fs-7'>
                                {data?.communicationType ?? 'N/A'}
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-100px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-6 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='card-footer py-2 border-0'></div>
            </div>
          </div>
          <div className='col-sm-12 col-xl-12  mb-2'>
            <div className='card shadow-custom'>
              <div className='card-header ribbon ribbon-end' style={{minHeight: '50px'}}>
                <div className='ribbon-label bg-info fs-5'>
                  <span className='badge badge-circle badge-light'>
                    {alloperationDetails?.liveCalls?.length}
                  </span>
                </div>
                <h3 className='card-title fs-5'>Live Calls </h3>
              </div>
              <div className='card-body py-4'>
                <div className='table-responsive tableFixHead'>
                  <table className='table table-row-dashed table-row-gray-300 gx-3 gy-2 table-responsive'>
                    <thead>
                      <tr className='fw-semibold text-muted'>
                        <th className='min-w-150px text-uppercase'>Call ID</th>
                        <th className='min-w-200px text-uppercase'>Requested Time</th>
                        <th className='min-w-250px text-uppercase'>Requester</th>
                        <th className='min-w-150px text-uppercase'>Customer</th>
                        <th className='min-w-250px text-uppercase'>Interpreter</th>
                        <th className='min-w-150px text-uppercase '>Call Duration</th>
                        <th className='min-w-150px text-uppercase '>Languages From</th>
                        <th className='min-w-150px text-uppercase '>Languages To</th>
                        <th className='min-w-200px text-uppercase text-end'>Communication Type</th>
                      </tr>
                    </thead>

                    <tbody>
                      {alloperationDetails?.liveCalls?.length > 0 ? (
                        alloperationDetails?.liveCalls?.map((data: any, index: number) => (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='blinking-dot-small me-2'></div>
                                <a className='text-gray-800 d-block fs-7'>{data?.code ?? 'N/A'}</a>
                              </div>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                {' '}
                                {moment(data?.requestedTime).format('DD MMM YYYY hh:mm A')}
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7 d-flex align-items-center'>
                                {data?.profileImageReq ? (
                                  <div className='symbol symbol-circle symbol-30px mx-2'>
                                    <img
                                      src={
                                        process.env.REACT_APP_IMG_URL +
                                        '/profile/' +
                                        data.profileImageReq
                                      }
                                      alt=''
                                      className='rounded-circle'
                                    />
                                  </div>
                                ) : (
                                  <div className='symbol symbol-circle symbol-30px overflow-hidden mx-2'>
                                    <div
                                      className={clsx(
                                        'symbol-label fs-4',
                                        'badge-light-success',
                                        `bg-light-success`
                                      )}
                                    >
                                      <span style={{fontSize: '10x'}}>
                                        {(data?.requestor ?? '').charAt(0).toUpperCase() +
                                          (data?.requestor ?? '').charAt(1).toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {data?.requestor && data.requestor !== 'N/A' ? (
                                  data.requestor
                                ) : (
                                  <img
                                    src={toAbsoluteUrl('/media/other/DotLoading.gif')}
                                    alt='Loading...'
                                    style={{
                                      height: '24px',
                                      width: 'auto',
                                    }}
                                  />
                                )}
                              </a>
                            </td>

                            <td>
                              <a
                                className='text-gray-800 d-block fs-7'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {data?.accountName && data.accountName !== 'N/A' ? (
                                  data.accountName
                                ) : (
                                  <img
                                    src={toAbsoluteUrl('/media/other/DotLoading.gif')}
                                    alt='Loading...'
                                    style={{height: '24px', width: 'auto'}}
                                  />
                                )}
                              </a>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7 d-flex align-items-center'>
                                <div className='d-flex align-items-center '>
                                  {data.profileImage !== '' ? (
                                    <div className='symbol symbol-circle symbol-30px mx-2'>
                                      <img
                                        src={
                                          process.env.REACT_APP_IMG_URL +
                                          '/profile/' +
                                          data.profileImage
                                        }
                                        alt=''
                                        className='rounded-circle'
                                      />
                                    </div>
                                  ) : (
                                    <div className='symbol symbol-circle symbol-30px overflow-hidden mx-2'>
                                      <div
                                        className={clsx(
                                          'symbol-label fs-4',
                                          'badge-light-success',
                                          `bg-light-success`
                                        )}
                                      >
                                        <span style={{fontSize: '10x'}}>
                                          {(data?.interpreterName ?? '').charAt(0).toUpperCase() +
                                            (data?.interpreterName ?? '').charAt(1).toUpperCase()}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {data?.interpreterName ?? 'N/A'}
                                </div>
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                {data?.holdTime ? data?.holdTime : 'N/A'}
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                <span className='badge badge-secondary me-1'>English</span>
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                <span className='badge badge-secondary me-1'>
                                  {data?.languages ?? 'N/A'}
                                </span>
                              </a>
                            </td>

                            <td className='text-end'>
                              <a className='text-gray-800 d-block fs-7'>
                                {data?.communicationType ?? 'N/A'}
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-100px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-6 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='card-footer py-2 border-0'></div>
            </div>
          </div>
          <div className='col-sm-12 col-xl-12  mb-2'>
            <div className='card shadow-custom'>
              <div className='card-header ribbon ribbon-end' style={{minHeight: '50px'}}>
                <div className='ribbon-label bg-info fs-6'>
                  Last 24h{' '}
                  <span className='badge badge-circle badge-light fw-bold ms-2'>
                    {alloperationDetails?.abandonedCalls?.length}
                  </span>
                </div>
                <h3 className='card-title fs-5'>Abandoned Calls</h3>
              </div>
              <div className='card-body py-4'>
                <div className='table-responsive table-container tableFixHead'>
                  <table
                    className='table table-row-dashed table-row-gray-300 align-middle gx-3 gy-2'
                    id='stickys'
                  >
                    <thead className=''>
                      <tr className='fw-semibold text-muted '>
                        <th className='min-w-150px text-uppercase'>Call ID</th>
                        <th className='min-w-150px text-uppercase'>Requested Time</th>
                        <th className='min-w-150px text-uppercase'>Requester</th>
                        <th className='min-w-150px text-uppercase'>Customer</th>
                        <th className='min-w-150px text-uppercase '>Call Duration</th>
                        <th className='min-w-150px text-uppercase '>Language From</th>
                        <th className='min-w-150px text-uppercase '>Language To</th>
                        <th className='min-w-200px text-uppercase text-end'>Communication Type</th>
                      </tr>
                    </thead>

                    <tbody>
                      {alloperationDetails?.abandonedCalls?.length > 0 ? (
                        alloperationDetails?.abandonedCalls?.map((data: any) => (
                          <tr>
                            <td>
                              <a className='text-gray-800 d-block fs-7'>{data?.code ?? 'N/A'}</a>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                {' '}
                                {moment(data?.requestedTime).format('DD MMM YYYY hh:mm A')}
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7 d-flex align-items-center'>
                                {data?.profileImageReq ? (
                                  <div className='symbol symbol-circle symbol-30px mx-2'>
                                    <img
                                      src={
                                        process.env.REACT_APP_IMG_URL +
                                        '/profile/' +
                                        data.profileImageReq
                                      }
                                      alt=''
                                      className='rounded-circle'
                                    />
                                  </div>
                                ) : (
                                  <div className='symbol symbol-circle symbol-30px overflow-hidden mx-2'>
                                    <div
                                      className={clsx(
                                        'symbol-label fs-4',
                                        'badge-light-success',
                                        `bg-light-success`
                                      )}
                                    >
                                      <span style={{fontSize: '10x'}}>
                                        {(data?.requestor ?? '').charAt(0).toUpperCase() +
                                          (data?.requestor ?? '').charAt(1).toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {data?.requestor && data.requestor !== 'N/A' ? (
                                  data.requestor
                                ) : (
                                  <img
                                    src={toAbsoluteUrl('/media/other/DotLoading.gif')}
                                    alt='Loading...'
                                    style={{
                                      height: '24px',
                                      width: 'auto',
                                    }}
                                  />
                                )}
                              </a>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                {data?.accountName ?? 'N/A'}
                              </a>
                            </td>

                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                {data?.holdTime ? data?.holdTime : 'N/A'}
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                <span className='badge badge-secondary me-1'>English</span>
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 d-block fs-7'>
                                <span className='badge badge-secondary me-1'>
                                  {data?.languages ?? 'N/A'}
                                </span>
                              </a>
                            </td>

                            <td className='text-end'>
                              <a className='text-gray-800 d-block fs-7'>
                                {data?.communicationType ?? 'N/A'}
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-100px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-6 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='card-footer py-2 border-0'></div>
            </div>
          </div>
        </div>
      </>
      {isLoading && <CommonLoading />}
    </>
  )
}

export {DashboardOperation}
