/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../_metronic/helpers'
import {getFileType} from './commonData'

type Props = {
  fileName: string
  documentCode: string
}

const DocumentViewModal: React.FC<Props> = ({fileName, documentCode}) => {
  return (
    <>
      <div className='modal fade' tabIndex={1} id={`kt_modal_document_view_${documentCode}`}>
        <div className='modal-dialog '>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>View Document</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className=' w-100 position-relative text-center '>
              {getFileType(fileName) === 'pdf' ? (
                <iframe 
                  title='Document Viewer'
                  src={process.env.REACT_APP_IMG_URL + '/files/' + fileName}
                  frameBorder='0'
                  width={'100%'}
                  height={'700px'}
                />
              ) : fileName !== '' ? (
                //<PdfReader file={process.env.REACT_APP_IMG_URL + '/files/' + fileName} />
                <img
                  src={process.env.REACT_APP_IMG_URL + '/files/' + fileName}
                  alt='document'
                  className='w-100 h-auto'
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <a
        type='button'
        role='button'
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        data-bs-toggle='modal'
        data-bs-target={`#kt_modal_document_view_${documentCode}`}
      >
        <i className='bi bi-eye-fill'></i>
      </a>
    </>
  )
}

export {DocumentViewModal}
