import React, {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {Formik, Form} from 'formik'

const scope = [
  {value: 1, label: 'Today'},
  {value: 2, label: 'Yesterday'},
  {value: 3, label: 'Current Week'},
  {value: 4, label: 'Last Week'},
  {value: 5, label: 'Current Month'},
  {value: 6, label: 'Last Month'},
  {value: 7, label: 'Current Year'},
  {value: 8, label: 'Last Year'},
]
const communication = [
  {value: 'Voice', label: 'OPI'},
  {value: 'Video', label: 'VRI'},
  {value: 'OSI', label: 'OSI'},
]

export function OverviewFilterDropdown(props: any) {
  const {fetchDashBoardData, setselectedlabel} = props
  const [showDropdown, setShowDropdown] = useState(false) // State to manage dropdown visibility
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const handleApply = async (values: any) => {
    await fetchDashBoardData(values.filterBy, values.filterCommunicationTypes)
    setShowDropdown(false)
    setselectedlabel(values.labelselected)
    setIsFilterApplied(true)
  }

  const handleReset = async (resetForm: any) => {
    resetForm()
    await fetchDashBoardData(5, null)
    setShowDropdown(false)
    setselectedlabel('current month')
    setIsFilterApplied(false)
  }

  return (
    <>
      <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-muted' />
          </Dropdown.Toggle>
        </OverlayTrigger>

        <Dropdown.Menu className='p-4 max-w-300px w-300px' style={{minHeight: '100%'}}>
          <Formik
            initialValues={{
              filterBy: 5, // Default value is 'Current Month'
              filterCommunicationTypes: null,
              labelselected: 'current month',
            }}
            onSubmit={async (values) => {
              await handleApply(values)
            }}
          >
            {({values, setFieldValue, resetForm, dirty, isSubmitting, isValid}) => (
              <Form>
                <div className='px-4 pb-4'>
                  <div className='fs-4 text-dark fw-semibolder'>Filter</div>
                </div>

                <div className='separator border-gray-200'></div>
                <div className='px-4 mt-4'>
                  <div className='row flex-column'>
                    <div className='col-sm-12 col-md-12'>
                      <div className='mb-3'>
                        <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                          Chart Scope
                        </label>
                        <div className='row g-4 g-xl-4'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className='w-100'>
                              <Select
                                className='react-select-styled react-select-solid react-select-sm'
                                classNamePrefix='react-select'
                                options={scope}
                                value={scope.find((c) => c.value === values.filterBy)}
                                onChange={(option) => {
                                  setFieldValue('filterBy', option?.value)
                                  setFieldValue('labelselected', option?.label)
                                }}
                                placeholder='Select : All'
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    border: '1px solid #e4e6ef',
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12'>
                      <div className='mb-3'>
                        <div className='col-md-12'>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Communication Type
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                checked={(values.filterCommunicationTypes as unknown as string[])?.length === communication.length ? true : false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFieldValue('filterCommunicationTypes', communication)
                                  } else {
                                    setFieldValue('filterCommunicationTypes', null)
                                  }
                                }}
                              />
                              <label className='form-check-label' htmlFor='flexRadioLg1'>
                                All
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className='row g-4 g-xl-4'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className='w-100'>
                              <Select
                                className='react-select-styled react-select-solid react-select-sm'
                                classNamePrefix='react-select'
                                options={communication}
                                isMulti
                                value={values.filterCommunicationTypes}
                                onChange={(options) =>
                                  setFieldValue('filterCommunicationTypes', options)
                                }
                                placeholder='Select : All'
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    border: '1px solid #e4e6ef',
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-end mt-3 px-4'>
                  <button
                    type='reset'
                    className='btn btn-sm btn-light me-2'
                    onClick={() => handleReset(resetForm)}
                  >
                    Reset
                  </button>

                  <button
                    type='submit'
                    className='btn btn-sm btn-primary'
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Apply
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
