import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import axios from 'axios'
import moment from 'moment'
import clsx from 'clsx'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {getFileType, statusMapping} from '../../../../Utils/commonData'
import {useAuth} from '../../../modules/auth'
import toaster from '../../../../Utils/toaster'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {DocumentViewModal} from '../../../../Utils/documentViewModal'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {DeleteConfirmationModal} from '../../../common/Models/confirmation-modal/DeleteConfirmationModal'
import AddOSIDetails from './AddOSIDetails'
import {Modal} from 'bootstrap'
import {OSIDocumentViewModal} from '../../../../Utils/osiDocumentViewModal'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

const documentUploadSchemaSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  file: Yup.string().required('Required'),
})
const addnoteSchema = Yup.object().shape({
  note: Yup.string().required('Required'),
  chatwith: Yup.string().required('Required'),
})

const unAssignConfirmedInterpreterSchema = Yup.object().shape({
  reason: Yup.string().required('Required'),
})

const addRejectNoteSchema = Yup.object().shape({
  reason: Yup.string().required('Required'),
})

export function AssignAppointmentView() {
  const location = useLocation()
  const chat = [
    {value: 1, label: 'User'},
    {value: 2, label: 'Requester Content Admin'},
    {value: 3, label: 'Interpreter'},
    {value: 4, label: 'Manager'},
    {value: 5, label: 'Admin'},
    {value: 6, label: 'All'},
  ]

  const category = [
    {value: 'option1', label: 'Fuel/Mileage'},
    {value: 'option2', label: 'Parking/Tolls'},
    {value: 'option3', label: 'Public Transportation'},
    {value: 'option4', label: 'Taxi/Uber/Back car'},
    {value: 'option5', label: 'Internet/Phone'},
    {value: 'option6', label: 'Meals/Foods'},
    {value: 'option7', label: 'Hotel/Accommodation'},
    {value: 'option8', label: 'Airfare'},
    {value: 'option9', label: 'Travel Time'},
    {value: 'option10', label: 'Other'},
  ]

  const {currentUser} = useAuth()
  const [activeTab, setActiveTab] = useState(currentUser?.result?.userType === 'SYSTEM' ? 1 : 2)
  const initialValues = {
    name: '',
    description: '',
    file: '',
  }
  const initialValue = {
    chatwith: 0,
    note: '',
  }

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    navigate(`/appointmentViewer/${id}?tab=${tabNumber}`)
    //setActiveTab(tabNumber)
  }
  const [selectedRecurrence, setSelectedRecurrence] = useState('1')

  const handleRecurrenceChange = (event: any) => {
    setSelectedRecurrence(event.target.value)
  }
  const [rows, setRows] = useState([{}])

  const addRow = () => {
    if (rows.length < 2) {
      setRows([...rows, {}])
    }
  }
  const removeRow = (index: number) => {
    const newRows = rows.filter((_, i) => i !== index)
    setRows(newRows)
  }
  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2)
  }
  const [isRatePerMile, setIsRatePerMile] = useState(true)

  const [isTravelTime, setIsTravelTime] = useState(true)
  const [selectedOption, setSelectedOption] = useState('')

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value)
  }

  //use states
  const [appointmentDetails, setAppointmentDetails] = useState<any>({})
  const [interpreters, setInterpreters] = useState<any>([])
  const [notMatchInterpreters, setNotMatchInterpreters] = useState<any>([])
  const [activities, setActivities] = useState<any>([])
  const [notes, setNotes] = useState<any>([])
  const [osiNotes, setOSINotes] = useState<any>([])
  const [documents, setDocuments] = useState<any>([])
  const {id} = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
  const [message, setMessage] = useState('')
  const [isBulkCancalation, setIsBulkCancalation] = useState(false)
  const [meetingSummary, setMeetingSummary] = useState<any>({})
  const [initialValuesOSI, setInitialValuesOSI] = useState({
    code: '',
    startTime: '',
    endTime: '',
    travelHours: 0,
    travelMinutes: 0,
    isRoundTrip: false,
    milageOfDrive: '',
  })
  let [expenseList, setExpenseList] = useState<any>([])
  const [billingDetails, setBillingDetails] = useState<any>(null)
  const [
    forcefullyAssignInterpretersConfirmDetails,
    setForcefullyAssignInterpretersConfirmDetails,
  ] = useState<any>(null)

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)
  const [isLoadingAccept, setIsLoadingAccept] = useState<boolean>(false)
  const [initialValueUnassignConfirmedInterperter, setInitialValueUnassignConfirmedInterperter] =
    useState<any>({
      appointmentId: '',
      interpreterId: '',
      reason: '',
    })

  useEffect(() => {
    if (id) {
      fetchAppoinmentDetails()
    }
  }, [currentPage, rowsPerPage, id])

  // Set the active tab based on the current location
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    let tabNumber = params.get('tab') // Get the value of 'tab' from the query string

    if (currentUser?.result?.userType === 'SYSTEM') {
      switch (tabNumber) {
        case '1':
          tabNumber = '1'
          break
        case '2':
          tabNumber = '2'
          break
        case '3':
          if (appointmentDetails?.status !== 6) {
            tabNumber = '3'
          } else {
            tabNumber = '1'
          }
          break
        case '4':
          tabNumber = '4'
          break
        case '5':
          if (
            (appointmentDetails?.status === 2 || appointmentDetails?.status === 5) &&
            appointmentDetails?.communicationTypeIdentification === 'ON_SITE'
          ) {
            tabNumber = '5'
          } else {
            tabNumber = '1'
          }
          break
        default:
          tabNumber = '1'
      }
    }

    if (currentUser?.result?.userType === 'CONSUMER') {
      switch (tabNumber) {
        case '2':
          tabNumber = '2'
          break
        case '3':
          if (currentUser?.result?.role === 'User') {
            tabNumber = '2'
          } else {
            tabNumber = '3'
          }
          break
        case '4':
          tabNumber = '4'
          break
        case '5':
          if (
            (appointmentDetails?.status === 2 || appointmentDetails?.status === 5) &&
            appointmentDetails?.communicationTypeIdentification === 'ON_SITE'
          ) {
            tabNumber = '5'
          } else {
            tabNumber = '2'
          }
          break
        default:
          tabNumber = '2'
      }
    }

    if (currentUser?.result?.userType === 'INTERPRETER') {
      switch (tabNumber) {
        case '2':
          tabNumber = '2'
          break
        case '3':
          tabNumber = '3'
          break
        case '4':
          tabNumber = '4'
          break
        case '5':
          if (
            (appointmentDetails?.status === 2 || appointmentDetails?.status === 5) &&
            appointmentDetails?.communicationTypeIdentification === 'ON_SITE'
          ) {
            tabNumber = '5'
          } else {
            tabNumber = '2'
          }
          break
        default:
          tabNumber = '2'
      }
    }

    if (tabNumber && currentUser?.result?.userType) {
      setActiveTab(parseInt(tabNumber))
    }
  }, [location.search, appointmentDetails])

  const fetchAppoinmentDetails = async () => {
    try {
      setIsLoading(true)
      let response = await axios.get(`${API_URL}/Appoinment/${id}`)
      setAppointmentDetails(response.data)
      if (currentUser?.result?.userType === 'SYSTEM') {
        let response2 = await axios.get(`${API_URL}/Appoinment/interpreters/${id}`)
        setInterpreters(response2.data.result)
      }
      let response3 = await axios.get(`${API_URL}/Appoinment/${id}/activities`)
      setActivities(response3.data)
      if (currentUser?.result?.userType === 'SYSTEM') {
        let response4 = await axios.get(`${API_URL}/Appoinment/interpreters-not-match/${id}`)
        setNotMatchInterpreters(response4.data)
      }
      getDocuments()
      let response5 = await axios.get(`${API_URL}/Appoinment/${id}/notes`)
      setNotes(response5.data)
      // osi meeting summary get
      if (response?.data?.communicationTypeIdentification === 'ON_SITE') {
        let response6 = await axios.get(`${API_URL}/Appoinment/osi-submission-appointment/${id}`)
        if (response6?.data) {
          setMeetingSummary(response6.data)
          setInitialValuesOSI({
            code: response6.data.code ?? '',
            startTime: response6.data.startDate
              ? moment(response6.data.startDate).format('HH:mm')
              : '',
            endTime: response6.data.endDate ? moment(response6.data.endDate).format('HH:mm') : '',
            travelHours: response6.data.travalTimeH ?? 0,
            travelMinutes: response6.data.travalTimeM ?? 0,
            isRoundTrip: response6.data.isRoundTrip ?? false,
            milageOfDrive: response6.data.milage ?? '',
          })
          const newRecords = []
          for (let i = 0; i < response6.data.osiExpensesList?.length; i++) {
            var _item = response6.data.osiExpensesList[i]
            newRecords.push({
              description: _item.description,
              amount: _item.amount,
              file: _item.fileName,
              dataUrl: _item.fileName,
              code: _item.code,
            })
          }
          setExpenseList(newRecords)
          // osi notes
          let osiNoteResponse = await axios.get(
            `${API_URL}/Appoinment/osi-submission-activities/${response6?.data?.code}`
          )
          setOSINotes(osiNoteResponse?.data?.notes)
        }
      }
      // appointment billing data fetch
      if (response.data.status == 5) {
        let billingDetails = await axios.get(`${API_URL}/invoice/appointment-billing-summery/${id}`)
        setBillingDetails(billingDetails?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAfterSubmitOSISubmmission = async () => {
    try {
      setIsLoading(true)
      let response = await axios.get(`${API_URL}/Appoinment/${id}`)
      setAppointmentDetails(response.data)
      let response3 = await axios.get(`${API_URL}/Appoinment/${id}/activities`)
      setActivities(response3.data)
      let response6 = await axios.get(`${API_URL}/Appoinment/osi-submission-appointment/${id}`)
      setMeetingSummary(response6.data)
      let osiNoteResponse = await axios.get(
        `${API_URL}/Appoinment/osi-submission-activities/${response6?.data?.code}`
      )
      setOSINotes(osiNoteResponse?.data?.notes)
      // appointment billing data fetch
      if (response.data.status == 5) {
        let billingDetails = await axios.get(`${API_URL}/invoice/appointment-billing-summery/${id}`)
        setBillingDetails(billingDetails?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchNotes = async () => {
    try {
      setIsLoading(true)

      let response5 = await axios.get(`${API_URL}/Appoinment/${id}/notes`)
      setNotes(response5.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  function countInterpretersByStatus(assignStatus: number): number {
    return interpreters.filter((interpreter: any) => interpreter.assignStatus === assignStatus)
      .length
  }

  function countNoMatchInterpretersByStatus(assignStatus: number): number {
    return notMatchInterpreters.filter(
      (interpreter: any) => interpreter.assignStatus === assignStatus
    ).length
  }

  const handleAssign = async (status: number, id: number, userID: any) => {
    try {
      let response = await axios.put(
        `${API_URL}/Appoinment/interpreter-status/${status}/${id}/${userID}`
      )
      if (response.data.status === 'S') {
        toaster('success', 'Successfuly updated')
        fetchAppoinmentDetails()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleInviteInterpreter = async (id: number, userID: any) => {
    try {
      let response = await axios.post(`${API_URL}/Appoinment/interpreters-invite/${id}/${userID}`)
      if (response.data.status === 'S') {
        toaster('success', 'Successfuly updated')
        fetchAppoinmentDetails()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAppoinmentCancelation = async () => {
    try {
      let response = await axios.delete(`${API_URL}/Appoinment`, {
        data: {
          isBulk: isBulkCancalation,
          appointment: id,
        },
      })
      if (response.data.status === 'S') {
        setIsOpenConfirmationModal(false)
        toaster('success', 'Successfuly cancelled')
        fetchAppoinmentDetails()
      } else {
        setIsOpenConfirmationModal(false)
        toaster('error', 'Fail Cancellation')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckInterpreterAvalability: any = async (id: number, isBulk: boolean) => {
    try {
      let {data} = await axios.get(
        `${API_URL}/Appoinment/check-interpreter-available/${id}/${isBulk}`
      )
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectFile = (e: any, setFieldValue: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue('file', '')
      return
    }
    const file = e.target.files[0]
    if (file.type != 'application/pdf' && file.type != 'image/png' && file.type != 'image/jpeg') {
      setFieldValue('file', '')
      toaster('error', 'Please upload pdf , jpeg or png file')
      return
    }
    setFieldValue('file', e.currentTarget.files[0])
  }

  const getDocuments = async () => {
    try {
      let response = await axios.get(`${API_URL}/Appoinment/${id}/documents`, {
        params: {
          page: currentPage,
          items_per_page: rowsPerPage,
        },
      })
      const {data, payload} = response.data
      setDocuments(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    }
  }

  const AppointmentStatus = (status: string) => {
    let label, color
    switch (status) {
      case 'Open':
        label = 'Open'
        color = 'rgba(215, 56, 60, 1)'
        break
      case 'ReadyToAssign':
        label = 'Ready To Assign'
        color = 'rgba(163, 162, 66, 1)'
        break
      case 'Confirmed':
        label = 'Confirmed'
        color = 'rgba(96, 187, 26, 1)'
        break
      case 'NoInterpreter':
        label = 'No Interpreter'
        color = 'rgba(255, 199, 0, 1)'
        break
      case 'Abandoned':
        label = 'Abandoned'
        color = 'rgba(227, 117, 155, 1)'
        break
      case 'Completed':
        label = 'Completed'
        color = 'rgba(24, 28, 50, 1)'
        break
      case 'Canceled':
        label = 'Canceled'
        color = 'rgba(228, 230, 239, 1)'
        break
      default:
        label = 'Unknown'
        color = 'rgba(128, 128, 128, 1)'
    }
    return {label, color}
  }

  const handleChangeStatus = async (status: number) => {
    try {
      let response = await axios.put(`${API_URL}/Appoinment/osi-submission-admin-response`, {
        code: meetingSummary?.code,
        status: status,
      })
      if (response.data.status === 'S') {
        toaster('success', response.data.text ?? 'Successfuly updated')
        fetchAfterSubmitOSISubmmission()
      } else {
        toaster('error', response.data.text ?? 'Fail Actions')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAcceptReject = async (status: number, id: number, userID: string) => {
    setIsLoadingAccept(true)
    try {
      let response = await axios.put(
        `${API_URL}/Appoinment/interpreter-status/${status}/${id}/${userID}`
      )
      if (response.data.status === 'S') {
        toaster('success', 'Successfuly updated')
        if (status === 1) {
          fetchAppoinmentDetails()
        } else {
          navigate(-1)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingAccept(false)
    }
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <Formik
            initialValues={initialValue}
            validationSchema={addnoteSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const result: any = await axios.post(`${API_URL}/Appoinment/notes`, {
                  fK_Appoinment: id,
                  note: values?.note,
                  visibilityType: values?.chatwith,
                })
                if (result?.data?.status == 'S') {
                  setSubmitting(true)
                  toaster('success', result?.text ?? 'Successfully Inserted')
                }
                resetForm()
              } catch (ex) {
                console.error(ex)
              } finally {
                fetchNotes()
                //setSubmitting(true)
                //cancel(true)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Appointment Note</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Visible To
                          </label>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={chat}
                            // isMulti
                            placeholder='Select '
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#a4afc4',
                              }),
                            }}
                            onChange={(option: any) => setFieldValue('chatwith', option?.value)}
                            value={{
                              value: values.chatwith,
                              label:
                                chat?.find((c: any) => c.value === values.chatwith)?.label ||
                                'Select ',
                            }}
                            name='chatwith'
                            id='chatwith'
                          />
                          {errors.chatwith && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.chatwith}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Write a Note
                          </label>
                          <textarea
                            placeholder='write Note..'
                            name='note'
                            id='note'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.note}
                            className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0'
                          ></textarea>
                          {errors.note && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.note}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Close
                    </button>
                    <Link to='#'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-sm'
                        data-bs-dismiss='modal'
                        disabled={isSubmitting || !isValid || !dirty}
                        onClick={() => handleSubmit()}
                      >
                        Save Note
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='cancel-assign-interperter-modal'>
        <div className='modal-dialog'>
          <Formik
            enableReinitialize
            initialValues={initialValueUnassignConfirmedInterperter}
            validationSchema={unAssignConfirmedInterpreterSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const result: any = await axios.post(
                  `${API_URL}/Appoinment/interpreters-unassign`,
                  values
                )
                if (result?.data?.status == 'S') {
                  setSubmitting(true)
                  toaster('success', result?.text ?? 'Successfully unassigned')
                  resetForm()
                  fetchAppoinmentDetails()
                } else {
                  toaster('error', result?.text ?? 'Interperter unassign failed')
                }
              } catch (ex) {
                console.error(ex)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Confirmation</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <p>
                          {' '}
                          Are you sure you want to unassign this interpreter from the appointment?
                        </p>
                      </div>
                    </div>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Write a Note
                          </label>
                          <textarea
                            placeholder='write Note..'
                            name='reason'
                            id='reason'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.reason}
                            className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0'
                          ></textarea>
                          {errors.reason && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.reason.toString()}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Close
                    </button>
                    <Link to='#'>
                      <button
                        type='submit'
                        className='btn btn-danger btn-sm'
                        data-bs-dismiss='modal'
                        disabled={isSubmitting || !isValid || !dirty}
                        onClick={() => handleSubmit()}
                      >
                        Unassign
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_5'>
        <div className='modal-dialog'>
          <Formik
            initialValues={initialValue}
            validationSchema={addnoteSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const result: any = await axios.post(`${API_URL}/Appoinment/osi-submission-notes`, {
                  code: meetingSummary.code,
                  note: values.note,
                })
                if (result?.data?.status == 'S') {
                  setSubmitting(true)
                  toaster('success', result?.data?.text ?? 'Successfully Inserted')
                  let osiNoteResponse = await axios.get(
                    `${API_URL}/Appoinment/osi-submission-activities/${meetingSummary.code}`
                  )
                  setOSINotes(osiNoteResponse?.data?.notes)
                  const modal = document.getElementById('kt_modal_5')
                  if (modal) {
                    const modalInstance = Modal.getInstance(modal)
                    if (modalInstance) modalInstance.hide()
                  }
                } else {
                  toaster('error', result?.data?.text ?? 'Something went wrong')
                }
                resetForm()
              } catch (ex) {
                console.error(ex)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Add OSI Note</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Write a Note
                          </label>
                          <textarea
                            placeholder='write Note..'
                            name='note'
                            id='note'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.note}
                            className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0'
                          ></textarea>
                          {errors.note && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.note}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Close
                    </button>
                    <Link to='#'>
                      <button
                        className='btn btn-primary btn-sm'
                        data-bs-dismiss='modal'
                        disabled={isSubmitting || !isValid || !dirty}
                        onClick={() => handleSubmit()}
                      >
                        Save Note
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='document'>
        <div className='modal-dialog'>
          <Formik
            initialValues={initialValues}
            validationSchema={documentUploadSchemaSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                const formData = new FormData()
                formData.append('name', values?.name ?? '')
                formData.append('description', values?.description ?? '')
                formData.append('file', values?.file ?? '')
                formData.append('appoinmentId', id ?? '')

                const result: any = await axios.post(
                  `${API_URL}/Appoinment/documents/insert`,
                  formData
                )
                if (result?.data?.status == 'S') {
                  toaster('success', result?.text ?? 'Successfully Inserted')
                  getDocuments()
                  resetForm()
                }
              } catch (ex) {
                console.error(ex)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              values,
              touched,
              isValid,
              dirty,
              isSubmitting,
              handleSubmit,
              resetForm,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Appointment File</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => resetForm()}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Name
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-white form-select-sm custom-input-height   custom-input-height'
                            placeholder='Name'
                            name='name'
                            id='name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {errors.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.name}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            Description
                          </label>
                          <textarea
                            placeholder='Write Description..'
                            className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0 '
                            id='description'
                            name='description'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            rows={3}
                          ></textarea>
                          {errors.description && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.description}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1  fs-7 mb-1'
                          >
                            File Upload
                          </label>
                          <input
                            type='file'
                            className='form-control form-control-white form-select-sm custom-input-height  '
                            placeholder='Select File'
                            onChange={(e) => onSelectFile(e, setFieldValue)}
                            name='file'
                            id='file'
                            onBlur={handleBlur}
                          />
                          {errors.file && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.file}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => resetForm()}
                    >
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary btn-sm'
                      data-bs-dismiss='modal'
                      disabled={isSubmitting || !isValid || !dirty}
                      onClick={() => handleSubmit()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_new_expense_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Add Expense</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1 '
                    >
                      Category
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={category}
                      placeholder='Select '
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#a4afc4',
                        }),
                      }}
                    />
                  </div>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1 '
                    >
                      Amount
                    </label>
                    <input
                      type='number'
                      className='form-control form-control-white form-select-sm custom-input-height'
                      placeholder='Amount'
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Note
                    </label>
                    <textarea
                      placeholder='Write Note..'
                      name='Note'
                      className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0 custom-input-height'
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Create Expense
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_interpreter_expense'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Add Expense</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1 '
                    >
                      Category
                    </label>
                    <select
                      className='form-select form-select-sm custom-input-height   form-select-white'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                    >
                      <option> Select Category</option>
                      <option value='1'> Fuel/Mileage</option>
                      <option value='2'> Parking/Tolls</option>
                      <option value='3'> Public Transportation </option>
                      <option value='4'> Taxi/Uber/Back car</option>
                      <option value='5'> Internet/Phone </option>
                      <option value='6'> Meals/Foods</option>
                      <option value='7'> Hotel/Accommodation </option>
                      <option value='8'> Airfare</option>
                      <option value='9'> Travel Time</option>
                      <option value='10'> Other</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1 '
                    >
                      Amount
                    </label>
                    <input
                      type='number'
                      className='form-control form-control-white form-select-sm custom-input-height  '
                      placeholder='Amount'
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Vendor
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-white form-select-sm custom-input-height  '
                      placeholder='Enter Vendor'
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Note
                    </label>
                    <textarea
                      placeholder='Write Note..'
                      name='Note'
                      className='form-control form-select-white mb-lg-0'
                    ></textarea>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className=' form-label fs-7 mb-1 '>
                      Receipt
                    </label>
                    <input
                      type='file'
                      className='form-control form-control-white form-select-sm custom-input-height  '
                      placeholder='Select Receipt'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Create Expense
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_rate'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Edit Appointment Rate</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className=''>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12 '>
                        <div className='row g-4 mb-0'>
                          <div className='col-sm-12 col-md-12'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label fs-7 mb-1 '
                            >
                              Rate
                            </label>
                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                              placeholder='Enter Rate'
                            />
                          </div>
                          <div className='row mt-3'>
                            <div className=''>
                              {rows.map((_, index) => (
                                <div className='' key={index}>
                                  <div className='row g-4 g-xl-4 mb-2'>
                                    <div className='col-sm-12 col-md-6'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='form-label fs-7 mb-1 '
                                      >
                                        For
                                      </label>
                                      <div className='input-group input-group-sm'>
                                        <input
                                          type='number'
                                          className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                                          placeholder='No Threshold '
                                        />
                                        <span className='input-group-text'>Min(s)</span>
                                      </div>
                                    </div>
                                    <div className='col-sm-12 col-md-5'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='form-label fs-7 mb-1 '
                                      >
                                        Then
                                      </label>
                                      <div className='input-group input-group-sm'>
                                        <span className='input-group-text'>Minute</span>
                                        <span className='input-group-text'>$</span>
                                        <input
                                          type='number'
                                          className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                                          placeholder='None '
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12 col-md-1 d-flex align-items-end flex-end'>
                                      <a className='' onClick={() => removeRow(index)}>
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen040.svg'
                                          className='svg-icon-muted svg-icon-2hx'
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className='col-sm-12 col-md-12'>
                              <div>
                                <a
                                  type='button'
                                  className='btn btn-link btn-color-info btn-active-color-primary me-5 fs-6'
                                  onClick={addRow}
                                >
                                  <i className='bi bi-plus fs-2'></i>
                                  <span style={{fontSize: '12px'}}>
                                    Add Rate Threshold ({rows.length}/2)
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='mb-3 mt-0'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1 '
                          >
                            Per
                          </label>
                          <select
                            className='form-select form-select-sm custom-input-height   form-select-white'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            onChange={handleRecurrenceChange}
                          >
                            <option value='1'>Min</option>
                            <option value='2'>Hr</option>
                            <option value='3'>Fixed</option>
                          </select>
                        </div>
                        {selectedRecurrence === '1' && (
                          <div className='row g-4 mb-3 '>
                            <div className='col-sm-12 col-md-6 '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1 '
                              >
                                Rounding
                              </label>
                              <select
                                className='form-select form-select-sm custom-input-height   form-select-white'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                              >
                                <option value='1'>1 min</option>
                                <option value='2'>Pro-rata</option>
                                <option value='3'>5 min</option>
                                <option value='4'>15 min</option>
                                <option value='5'>30 min</option>
                                <option value='6'>1 Hr</option>
                              </select>
                            </div>
                            <div className='col-sm-12 col-md-6 '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1 '
                              >
                                Direction
                              </label>
                              <select
                                className='form-select form-select-sm custom-input-height   form-select-white'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                              >
                                <option value='1'>Up</option>
                                <option value='2'>Down</option>
                                <option value='3'>Up/Down</option>
                              </select>
                            </div>
                            <div className='col-sm-12 col-md-6 '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1 '
                              >
                                Minimum
                              </label>
                              <input
                                type='number'
                                className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                                placeholder='Enter Minimum'
                              />
                            </div>
                            <div className='col-sm-12 col-md-6 d-flex justify-content-start align-items-end'>
                              <a className='form-label fs-7 mb-1 '>Minute</a>
                            </div>
                          </div>
                        )}
                        {selectedRecurrence === '2' && (
                          <div className='row g-4 mb-3 '>
                            <div className='col-sm-12 col-md-6 '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1 '
                              >
                                Rounding
                              </label>
                              <select
                                className='form-select form-select-sm custom-input-height   form-select-white'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                              >
                                <option value='1'>1 min</option>
                                <option value='2'>Pro-rata</option>
                                <option value='3'>5 min</option>
                                <option value='4'>15 min</option>
                                <option value='5'>30 min</option>
                                <option value='6'>1 Hr</option>
                              </select>
                            </div>
                            <div className='col-sm-12 col-md-6 '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1 '
                              >
                                Direction
                              </label>
                              <select
                                className='form-select form-select-sm custom-input-height   form-select-white'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                              >
                                <option value='1'>Up</option>
                                <option value='2'>Down</option>
                                <option value='3'>Up/Down</option>
                              </select>
                            </div>
                            <div className='col-sm-12 col-md-6 '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1 '
                              >
                                Minimum
                              </label>
                              <input
                                type='number'
                                className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                                placeholder='Enter Minimum'
                              />
                            </div>
                            <div className='col-sm-12 col-md-6 d-flex justify-content-start align-items-end'>
                              <a className='form-label fs-7 mb-1 '>Hour</a>
                            </div>
                          </div>
                        )}

                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1 '
                          >
                            Priority (Fixed Amount){' '}
                            <i
                              className='bi bi-info-circle text-info'
                              data-bs-toggle='popover'
                              data-bs-custom-class='popover-inverse'
                              data-bs-placement='top'
                              title='Priority rate will be added in addition to base rate. For account charge it will also be multiplied by the number of participating interpreters.'
                            ></i>
                          </label>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text'>$</span>
                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                              placeholder='None'
                            />
                          </div>
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1 '
                          >
                            Priority (Add To Base Rate){' '}
                            <i
                              className='bi bi-info-circle text-info'
                              data-bs-toggle='popover'
                              data-bs-custom-class='popover-inverse'
                              data-bs-placement='top'
                              title='Priority rate will be added in addition to base rate. For account charge it will also be multiplied by the number of participating interpreters.'
                            ></i>
                          </label>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text p-0 '>
                              <select
                                className='form-select form-select-sm custom-input-height   form-select-white rounded-0'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                              >
                                <option value='1'>$</option>
                                <option value='2'>%</option>
                              </select>
                            </span>
                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                              placeholder='None'
                            />
                          </div>
                        </div>
                        <div className='row g-4 mb-3 '>
                          <div className='col-sm-12 col-md-6 '>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label fs-7 mb-1 '
                            >
                              Conference Rate{' '}
                              <i
                                className='bi bi-info-circle text-info'
                                data-bs-toggle='popover'
                                data-bs-custom-class='popover-inverse'
                                data-bs-placement='top'
                                title='Additional rate for having 3+ participants during audio or video call.'
                              ></i>
                            </label>
                            <select
                              className='form-select form-select-sm custom-input-height   form-select-white'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              value={selectedOption}
                              onChange={handleSelectChange}
                            >
                              <option value='1'>Add fixed amount</option>
                              <option value='2'>Add to a base rate</option>
                            </select>
                          </div>
                          <div className='col-sm-12 col-md-6 d-flex align-items-end'>
                            {selectedOption === '1' && (
                              <div className='input-group input-group-sm'>
                                <span className='input-group-text'>$</span>
                                <input
                                  type='number'
                                  className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                                  placeholder='None'
                                />
                              </div>
                            )}
                            {selectedOption === '2' && (
                              <div className='input-group input-group-sm'>
                                <span className='input-group-text'>Minute</span>
                                <span className='input-group-text'>$</span>
                                <input
                                  type='number'
                                  className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                                  placeholder='None'
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1 '
                          >
                            Account charged based on
                          </label>
                          <div className='row g-4 g-xl-6'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                <span className='form-check form-check-custom form-check-white me-3'>
                                  <input
                                    name='accountPlan'
                                    className='form-check-input'
                                    type='radio'
                                    value='1'
                                  />
                                </span>
                                <span className='d-flex align-items-center me-2'>
                                  <span
                                    className='text-gray-600 text-hover-primary '
                                    style={{fontSize: '12px'}}
                                  >
                                    Scheduled duration
                                  </span>
                                </span>
                              </label>
                              <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                                <span className='form-check form-check-custom form-check-white me-3'>
                                  <input
                                    name='accountPlan'
                                    className='form-check-input'
                                    type='radio'
                                    value='2'
                                    checked
                                  />
                                </span>
                                <span className='d-flex align-items-center me-2'>
                                  <span
                                    className='text-gray-800 text-hover-primary'
                                    style={{fontSize: '12px'}}
                                  >
                                    Actual duration
                                  </span>
                                </span>
                              </label>
                              <label className='d-flex flex-start mb-0 cursor-pointer'>
                                <span className='form-check form-check-custom form-check-white me-3'>
                                  <input
                                    name='accountPlan'
                                    className='form-check-input'
                                    type='radio'
                                    value='3'
                                    checked
                                  />
                                </span>
                                <span className='d-flex align-items-center me-2'>
                                  <span
                                    className='text-gray-800 text-hover-primary '
                                    style={{fontSize: '12px'}}
                                  >
                                    Actual with minimum scheduled duration
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_mileage_expense'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Distance Rule</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan1'
                        className='form-check-input'
                        type='radio'
                        value='1'
                        checked={isRatePerMile}
                        disabled={isRatePerMile}
                        onChange={() => setIsRatePerMile(true)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary '>Rate per Mile</span>
                    </span>
                  </label>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan1'
                        className='form-check-input'
                        type='radio'
                        value='2'
                        checked={!isRatePerMile}
                        disabled={!isRatePerMile}
                        onChange={() => setIsRatePerMile(false)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary'>Based on Rate</span>
                    </span>
                  </label>
                </div>
                {isRatePerMile ? (
                  <div className='col-sm-12 col-md-12 col-lg-12 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Rate per Mile
                    </label>
                    <div className='input-group input-group-sm'>
                      {/* <span className='input-group-text'>Hr</span> */}
                      <span className='input-group-text'>$</span>
                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                        placeholder='None'
                      />
                    </div>
                  </div>
                ) : (
                  <div className='col-sm-12 col-md-12 col-lg-12 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Base on Rate
                    </label>
                    <div className='input-group input-group-sm'>
                      {/* <span className='input-group-text'>Hr</span> */}

                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                        placeholder='None'
                      />
                      <span className='input-group-text'>miles equals to</span>
                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                        placeholder='None'
                      />
                      <span className='input-group-text'>hr of the rate</span>
                    </div>
                  </div>
                )}
                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Min Miles
                    </label>
                  </div>

                  <input
                    type='number'
                    className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                    placeholder='None'
                    disabled={!isChecked}
                  />
                </div>

                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked2}
                      onChange={handleCheckboxChange2}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Max Miles
                    </label>
                  </div>
                  <input
                    type='number'
                    className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                    placeholder='None'
                    disabled={!isChecked2}
                  />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg1'
                      checked
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg1'>
                      Automatically add to appointments for Interpreters
                    </label>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg2'
                      checked
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg2'>
                      Automatically add to appointments for Accounts
                    </label>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg3'
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Round-trip{' '}
                      <i
                        className='bi bi-info-circle text-info'
                        data-bs-toggle='popover'
                        data-bs-custom-class='popover-inverse'
                        data-bs-placement='top'
                        title='When selected, distance from interpreter address to appointment location will be automatically doubled.'
                      ></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save Distance Rule
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_travel_expense'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Travel Time Rule</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan2'
                        className='form-check-input'
                        type='radio'
                        value='3'
                        checked={isTravelTime}
                        disabled={isTravelTime}
                        onChange={() => setIsTravelTime(true)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary '>
                        Based on Interpreter/Account Rate{' '}
                        <i
                          className='bi bi-info-circle text-info'
                          data-bs-toggle='popover'
                          data-bs-custom-class='popover-inverse'
                          data-bs-placement='top'
                          title='1 hour/min of travel time equals to 1 hour/min of Account and Interpreter rate'
                        ></i>
                      </span>
                    </span>
                  </label>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan2'
                        className='form-check-input'
                        type='radio'
                        value='4'
                        checked={!isTravelTime}
                        disabled={!isTravelTime}
                        onChange={() => setIsTravelTime(false)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary'>New Rate</span>
                    </span>
                  </label>
                </div>
                {isTravelTime ? (
                  <></>
                ) : (
                  <>
                    <div className='col-sm-12 col-md-12 col-lg-12 '>
                      <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                        New Rate
                      </label>
                      <div className='input-group input-group-sm'>
                        <span className='input-group-text'>$</span>

                        <input
                          type='number'
                          className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                          placeholder='None'
                        />
                        <span className='input-group-text'>Per</span>
                        <select
                          className='form-select form-select-sm custom-input-height   form-select-white rounded-0'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                        >
                          <option value='1'>Hour</option>
                          <option value='2'>Minute</option>
                        </select>
                        <span className='input-group-text'>for interpreterse</span>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12 '>
                      <div className='input-group input-group-sm'>
                        <span className='input-group-text'>$</span>

                        <input
                          type='number'
                          className='form-control form-control-white form-control-sm custom-input-height  custom-input-height  custom-input-height'
                          placeholder='None'
                        />
                        <span className='input-group-text'>Per</span>
                        <select
                          className='form-select form-select-sm custom-input-height   form-select-white rounded-0'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                        >
                          <option value='1'>Hour</option>
                          <option value='2'>Minute</option>
                        </select>
                        <span className='input-group-text'>for accounts</span>
                      </div>
                    </div>
                  </>
                )}
                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Min Time
                    </label>
                  </div>
                  <div className='d-flex justify-content-amount align-items-center flex-nowrap'>
                    <button type='button' className='btn btn-light btn-sm me-2'>
                      -
                    </button>
                    <div className='d-flex'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm custom-input-height '
                        pattern='[0-9]{2}:[0-9]{2}'
                        title='Enter Duration in HH:MM format'
                        placeholder='(HH:MM)'
                        disabled={!isChecked}
                      />
                    </div>
                    <button type='button' className='btn btn-light btn-sm ms-2'>
                      +
                    </button>
                  </div>
                </div>

                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked2}
                      onChange={handleCheckboxChange2}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Max Time
                    </label>
                  </div>
                  <div className='d-flex justify-content-amount align-items-center flex-nowrap'>
                    <button type='button' className='btn btn-light btn-sm me-2'>
                      -
                    </button>
                    <div className='d-flex'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm custom-input-height '
                        pattern='[0-9]{2}:[0-9]{2}'
                        title='Enter Duration in HH:MM format'
                        placeholder='(HH:MM)'
                        disabled={!isChecked2}
                      />
                    </div>
                    <button type='button' className='btn btn-light btn-sm ms-2'>
                      +
                    </button>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg11'
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg11'>
                      Automatically add to Account expenses
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save Travel Time Rule
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_delete_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Delete Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/delete.gif' alt='' />
                </div>
              </div>
              <h4 style={{textAlign: 'center'}}>Are you sure you want to delete this?</h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-danger btn-sm' data-bs-dismiss='modal'>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_canceled_status_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5 me-3'>
                <div className='symbol symbol-90px '>
                  <img src='/media/other/warning.gif' alt='' />
                </div>
              </div>
              <>
                <p>Changing the status from Completed to Canceled might have rate implications:</p>
                <ul>
                  <li>Priority rate may be removed</li>
                  <li>Expenses may be excluded</li>
                  <li>
                    Previously applied standard / special rate may be substituted with cancellation
                    rate
                  </li>
                </ul>
                <p>
                  Please visit Billing tab after the change to make sure desirable rates are being
                  applied.
                </p>
              </>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button
                type='button'
                className='btn btn-success btn-sm'
                data-bs-toggle='modal'
                data-bs-target='#kt_canceled_reason'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_canceled_reason'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Cancel Appointment</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1 '
                    >
                      Reason
                    </label>
                    <select
                      className='form-select form-select-sm custom-input-height form-select-white'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                    >
                      <option>Select Reason</option>
                      <option value='1'>Did Not Show</option>
                      <option value='2'>Emergency</option>
                      <option value='3'>Other </option>
                      <option value='4'>Appointment made in error</option>
                      <option value='5'>Change of address</option>
                      <option value='6'>Declined, Unable to Cover</option>
                      <option value='7'>No-Show Consumer</option>
                      <option value='8'>No-Show Interpreter</option>
                      <option value='9'>Not necessary now</option>
                      <option value='10'>Same Day Cancellation</option>
                      <option value='11'>Time changed</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Other Reason
                    </label>
                    <textarea
                      placeholder='write Reason..'
                      name='reason'
                      className='form-control form-select-white mb-lg-0'
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                  Submit
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_archived_status_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5 me-3'>
                <div className='symbol symbol-90px '>
                  <img src='/media/other/warning.gif' alt='' />
                </div>
              </div>
              <>
                <p>
                  Changing the status from Completed to Archived will have no rate implications
                  until you change any fields or perform interpreter actions.
                </p>

                <p>
                  Please visit Billing tab after the change to make sure desirable rates are being
                  applied.
                </p>
              </>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-success btn-sm' data-bs-dismiss='modal'>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_complete_status_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5 me-3'>
                <div className='symbol symbol-90px '>
                  <img src='/media/other/warning.gif' alt='' />
                </div>
              </div>
              <>
                <p>
                  Changing the status from Archived to Completed will have no rate implications
                  until you change any fields or perform interpreter actions.
                </p>

                <p>
                  Please visit Billing tab after the change to make sure desirable rates are being
                  applied.
                </p>
              </>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-success btn-sm' data-bs-dismiss='modal'>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-3'>
        <div className='d-flex align-items-center '>
          <a
            className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-3'
            onClick={() => navigate(-1)}
          >
            <i className='bi bi-arrow-left-circle ' style={{fontSize: '28px'}}></i>
            {/* <i className='bi bi-arrow-left-circle-fill' s></i> */}
          </a>
          <div className=''>
            <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '36px'}}>
              Appointment Viewer
            </h1>

            <p className='text-gray-500 fs-5'>Manage Appointment and Notes</p>
          </div>
        </div>
      </div>

      {/* {currentUser?.result?.userType === 'SYSTEM' && (
        <div className='d-flex justify-content-between align-items-center  px-3 py-2 mb-2 rounded shadow-sm bg-white'>
          <div>
            <span className='text-dark fs-7 fw-semibold'>
            </span>
          </div>
          <div>
            <span className='badge badge-info me-2'>{countInterpretersByStatus(0)} Invited</span>
            <span className='badge badge-primary me-2'>
              {countInterpretersByStatus(1)} Accepted
            </span>
            <span className='badge  badge-success me-2'>
              {countInterpretersByStatus(3)} Confirmed
            </span>
            <span className='badge  badge-secondary me-2'>
              {countInterpretersByStatus(2)} Declined
            </span>
            <span className='badge  badge-danger me-2'>{countInterpretersByStatus(4)} Reject</span>
          </div>
        </div>
      )} */}

      <div className='card mb-5 mb-xl-5 shadow-sm' style={{borderTop: '3px solid #a3a242'}}>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-125px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl('../media/avatars/appointment.gif')}
                  alt='appointment'
                  className='rounded-sm'
                />
                {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
              </div>
              {currentUser?.result?.userType !== 'INTERPRETER' &&
                appointmentDetails?.status !== 5 &&
                appointmentDetails?.status !== 4 &&
                appointmentDetails?.status !== 6 && (
                  <div className='d-flex flex-center mt-2'>
                    <Dropdown>
                      <Dropdown.Toggle variant='primary' id='dropdown-basic' className='btn btn-sm'>
                        <i className='fa-solid fa-pen me-1 fs-8'></i>
                        Edit
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => navigate(`/edit-appointment/${id}?isBulk=false`)}
                        >
                          Edit occurrence
                        </Dropdown.Item>
                        {appointmentDetails?.isRecurring && (
                          <Dropdown.Item
                            onClick={() => navigate(`/edit-appointment/${id}?isBulk=true`)}
                          >
                            Edit series
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a
                      href='#'
                      className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 text-uppercase'
                    >
                      {appointmentDetails?.customerName ?? 'N/A'}
                    </a>
                    {appointmentDetails?.communicationTypeIdentification !== 'ON_SITE' && (
                      <span className='badge badge-dark ms-3 py-3 px-4 fs-8'>
                        {' '}
                        Meeting code : {appointmentDetails?.meetingPinCode}
                      </span>
                    )}
                    <span
                      className='badge badge-success ms-3 py-3 px-4 fs-8'
                      style={{
                        backgroundColor: AppointmentStatus(appointmentDetails?.statusName).color,
                        color: appointmentDetails?.statusName === 'Canceled' ? 'black' : 'white',
                      }}
                    >
                      Status : {AppointmentStatus(appointmentDetails?.statusName).label}
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2 '>
                    <a className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'>
                      <i className='bi bi-calendar3 me-2'></i>
                      {moment(appointmentDetails?.startTime).format('MM/DD/YYYY, dddd')}{' '}
                      {moment(appointmentDetails.startTime).format('h:mm A')} -{' '}
                      {moment(appointmentDetails?.endTime).format('MM/DD/YYYY, dddd')}{' '}
                      {moment(appointmentDetails.endTime).format('h:mm A')}
                    </a>
                    {/* <a className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'>
                      <i className='bi bi-building me-2'></i>
                      {appointmentDetails?.timeZoneName ?? 'N/A'}
                    </a> */}
                  </div>
                </div>

                <div className='d-flex flex-row'>
                  {currentUser?.result.userType !== 'INTERPRETER' &&
                    appointmentDetails?.status !== 5 &&
                    appointmentDetails?.status !== 4 &&
                    appointmentDetails?.status !== 6 && (
                      <div className='d-flex me-2'>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='danger'
                            id='dropdown-basic'
                            className='btn btn-sm'
                          >
                            <i className='bi bi-x-circle me-1 fs-6'></i>
                            Cancel
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={async () => {
                                const isInterpreterAvalability: any =
                                  await handleCheckInterpreterAvalability(
                                    appointmentDetails?.code,
                                    false
                                  )
                                setMessage(
                                  isInterpreterAvalability
                                    ? 'It looks like the interpreter you are about to remove is already assigned to this appointment. Are you sure you want to proceed? Removing the interpreter will require you to cancel the job assignment?'
                                    : 'Are you sure want to cancel this appoinment?'
                                )
                                setIsBulkCancalation(false)
                                setIsOpenConfirmationModal(true)
                              }}
                            >
                              Cancel occurrence
                            </Dropdown.Item>
                            {appointmentDetails?.isRecurring && (
                              <Dropdown.Item
                                onClick={async () => {
                                  const isInterpreterAvalability: any =
                                    await handleCheckInterpreterAvalability(
                                      appointmentDetails?.id,
                                      true
                                    )
                                  setMessage(
                                    isInterpreterAvalability
                                      ? 'It looks like the interpreter you are about to remove is already assigned to this appointment. Are you sure you want to proceed? Removing the interpreter will require you to cancel the job assignment series?'
                                      : 'Are you sure want to cancel this appoinment series?'
                                  )
                                  setIsBulkCancalation(true)
                                  setIsOpenConfirmationModal(true)
                                }}
                              >
                                Cancel series
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}

                  {currentUser?.result.userType === 'INTERPRETER' &&
                    appointmentDetails?.assignStatus !== 1 &&
                    appointmentDetails?.assignStatus !== 3 &&
                    appointmentDetails?.status !== 6 &&
                    appointmentDetails?.status !== 4 && (
                      <div className='d-flex justify-content-end'>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-filter'>Accept</Tooltip>}
                        >
                          <div className='d-flex justify-content-start flex-shrink-0 me-2'>
                            <button
                              className='btn btn-success btn-sm h-35px'
                              disabled={isLoadingAccept}
                              onClick={() =>
                                handleAcceptReject(
                                  1,
                                  appointmentDetails?.code,
                                  currentUser?.result?.code
                                )
                              }
                            >
                              Accept
                            </button>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-filter'>Decline</Tooltip>}
                        >
                          <div className='d-flex justify-content-end flex-shrink-0 me-2'>
                            <button
                              className='btn btn-danger btn-sm h-35px'
                              disabled={isLoadingAccept}
                              onClick={() =>
                                handleAcceptReject(
                                  2,
                                  appointmentDetails?.code,
                                  currentUser?.result?.code
                                )
                              }
                            >
                              Decline
                            </button>
                          </div>
                        </OverlayTrigger>
                      </div>
                    )}

                  {appointmentDetails?.status !== 6 && appointmentDetails?.status !== 5 && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip id='tooltip-filter'>Add Note</Tooltip>}
                    >
                      <div className='d-flex align-items-center mb-2 me-2'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary btn-icon px-4'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_2'
                          data-bs-backdrop='static'
                          data-bs-keyboard='false'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen005.svg'
                            className='svg-icon-muted'
                          />
                        </button>
                      </div>
                    </OverlayTrigger>
                  )}

                  {(appointmentDetails?.status === 2 || appointmentDetails?.status === 5) &&
                    currentUser?.result.userType === 'INTERPRETER' &&
                    appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                      <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip id='tooltip-filter'>Download VOS Form</Tooltip>}
                      >
                        <div className='d-flex align-items-center mb-2 me-2'>
                          <a
                            href={toAbsoluteUrl('/media/other/VOS_for_connect.pdf')}
                            download='VOS For Connect.pdf'
                            className='btn btn-sm btn-primary btn-icon px-4'
                          >
                            <KTSVG
                              path='/media/icons/duotune/files/fil017.svg'
                              className='svg-icon-muted'
                            />
                          </a>
                        </div>
                      </OverlayTrigger>
                    )}

                  {appointmentDetails?.status === 2 &&
                    currentUser?.result.userType === 'INTERPRETER' &&
                    !appointmentDetails?.isOSIMeetingSummerySubmitted &&
                    appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                      <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip id='tooltip-filter'>Add Complete Appointment</Tooltip>}
                      >
                        <div className='d-flex align-items-center mb-2 me-2'>
                          <button
                            type='button'
                            className='btn btn-sm btn-primary px-4'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_add_osi_details'
                            data-bs-backdrop='static'
                            data-bs-keyboard='false'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen016.svg'
                              className='svg-icon-muted '
                            />
                            Submit
                          </button>
                        </div>
                      </OverlayTrigger>
                    )}
                </div>
              </div>
              {!isLoading && (
                <AddOSIDetails
                  initialValues={initialValuesOSI}
                  fetchAfterSubmitOSISubmmission={fetchAfterSubmitOSISubmmission}
                  expenseList={expenseList}
                  setExpenseList={setExpenseList}
                />
              )}

              <div className='separator mt-0 mb-2'></div>
              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-column flex-grow-1 pe-4 '>
                  <div className='d-flex flex-wrap'>
                    <div className='card-body p-0'>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>ID </label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>: {appointmentDetails?.code}</span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>
                          Communication Type{' '}
                        </label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fs-6'>: {appointmentDetails?.communicationType}</span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Requirements</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span className=' fs-6 me-2'>
                            : {appointmentDetails?.languageFrom ?? '-'} -{' '}
                            {appointmentDetails?.languageTo ?? '-'} Interpreter
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Recurrence</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.recurrenceName ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      {/* <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Subject</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.subject ?? 'N/A'}
                          </span>
                        </div>
                      </div> */}
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Gender</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            : {appointmentDetails?.gender ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Consumer</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            :{' '}
                            {appointmentDetails?.consumer === '' ||
                            appointmentDetails?.consumer === null
                              ? 'N/A'
                              : appointmentDetails?.consumer}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Description</label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-gray-900'>
                            :{' '}
                            {appointmentDetails?.description === '' ||
                            appointmentDetails?.description === null
                              ? 'N/A'
                              : appointmentDetails?.description}
                          </span>
                        </div>
                      </div>
                      {appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Location</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.address === ''
                                ? 'N/A'
                                : appointmentDetails?.address}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Meeting Link</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span
                              className=' fs-6 me-2 text-nowrap'
                              style={{
                                textOverflow: 'ellipsis',
                                width: '200px',
                                overflow: 'hidden',
                                flexGrow: '1',
                              }}
                            >
                              :{' '}
                              {appointmentDetails?.virtualAddress === '' ? (
                                'N/A'
                              ) : (
                                <a
                                  href={appointmentDetails.virtualAddress}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{color: '#181C32', textDecoration: 'underline'}}
                                >
                                  {appointmentDetails.virtualAddress}
                                </a>
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Pin</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.pin === '' || appointmentDetails?.pin === null
                                ? 'N/A'
                                : appointmentDetails?.pin}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>
                            Meeting ID / Caller Number
                          </label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.callerNumber === '' ||
                              appointmentDetails?.callerNumber === null
                                ? 'N/A'
                                : appointmentDetails?.callerNumber}
                            </span>
                          </div>
                        </div>
                      )}
                      {appointmentDetails?.communicationTypeIdentification ===
                        'THIRD_PART_PLATFORM' && (
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Remarks</label>
                          <div className='col-lg-8 d-flex align-items-center'>
                            <span className=' fs-6 me-2'>
                              :{' '}
                              {appointmentDetails?.remarks === '' ||
                              appointmentDetails?.remarks === null
                                ? 'N/A'
                                : appointmentDetails?.remarks}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column flex-grow-1 pe-4 '>
                  <div className='d-flex flex-wrap'>
                    <div className='card-body p-0'>
                      {currentUser?.result.userType === 'SYSTEM' && (
                        <>
                          <div className='row mb-3'>
                            <label className='col-lg-4 fw-bold text-black fs-6'>
                              Account Priority{' '}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-gray-900'>
                                : {appointmentDetails?.accountPriorityName ?? 'N/A'}
                              </span>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-4 fw-bold text-black fs-6'>
                              Interpreter Priority{' '}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-gray-900'>
                                : {appointmentDetails?.interpreterPriorityName ?? 'N/A'}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>
                          Service Delivery{' '}
                        </label>
                        <div className='col-lg-8 fv-row'>
                          <span className='fs-6'>: {appointmentDetails?.serviceType ?? 'N/A'}</span>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-black fs-6'>Requester</label>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <span className=' fs-6 me-2'>
                            : {appointmentDetails?.requesterName ?? 'N/A'}
                          </span>
                        </div>
                      </div>
                      {/*<div className='card-body p-0'>
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>Notes for PM </label>
                          <div className='col-lg-8 fv-row'>
                            <span className='fs-6'>
                              :{' '}
                              {appointmentDetails?.notePM === '' ||
                              appointmentDetails?.notePM === null
                                ? 'N/A'
                                : appointmentDetails?.notePM}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='card-body p-0'>
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>
                            Notes for Interpreter{' '}
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <span className='fs-6'>
                              :{' '}
                              {appointmentDetails?.noteInterpreter === '' ||
                              appointmentDetails?.noteInterpreter === null
                                ? 'N/A'
                                : appointmentDetails?.noteInterpreter}
                            </span>
                          </div>
                        </div>
                      </div>*/}

                      <div className='card-body p-0'>
                        <div className='row mb-3'>
                          <label className='col-lg-4 fw-bold text-black fs-6'>
                            Appointed Interpreter{' '}
                          </label>
                          <div className='col-lg-8 fv-row'>
                            <span>
                              :{' '}
                              {appointmentDetails?.confirmedInterpreterName === 'Pending' ? (
                                <label className='badge badge-warning ms-3 py-3 px-4 fs-8'>
                                  Pending
                                </label>
                              ) : (
                                appointmentDetails?.confirmedInterpreterName
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '40px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            {currentUser?.result?.userType === 'SYSTEM' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400   ${
                    activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_1'
                  onClick={() => handleTabClick(1)}
                >
                  Overview
                </a>
              </li>
            )}
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
                onClick={() => handleTabClick(2)}
              >
                Activities
              </a>
            </li>
            {!(
              currentUser?.result?.userType === 'CONSUMER' && currentUser?.result?.role === 'User'
            ) &&
              appointmentDetails?.status != 6 && (
                <li className='nav-item'>
                  <a
                    className={`nav-link fs-7 text-gray-400 ${
                      activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                    }`}
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_3'
                    onClick={() => handleTabClick(3)}
                  >
                    Billing
                  </a>
                </li>
              )}

            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 4 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_4'
                onClick={() => handleTabClick(4)}
              >
                Documents
              </a>
            </li>

            {(appointmentDetails?.status === 2 || appointmentDetails?.status === 5) &&
              appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                <li className='nav-item'>
                  <a
                    className={`nav-link fs-7 text-gray-400 ${
                      activeTab === 5 ? 'text-active-dark fw-bold active show' : ''
                    }`}
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_5'
                    onClick={() => handleTabClick(5)}
                  >
                    Meeting Summary
                  </a>
                </li>
              )}
          </ul>
        </div>
        <div className='card-body pt-0'>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              {activeTab === 1 &&
                currentUser?.result?.userType === 'SYSTEM' &&
                interpreters?.some((item: {assignStatus: number}) => item?.assignStatus === 3) && (
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-semibold text-muted text-uppercase'>
                          <th className='min-w-150px text-uppercase' style={{width: '30%'}}>
                            Assigned Interpreter
                          </th>

                          <th className='min-w-100px '>Rate</th>

                          <th className='min-w-100px '>Status</th>
                          {/* {appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                            <th className='min-w-100px '>Distance</th>
                          )} */}

                          {appointmentDetails?.status === 2 &&
                          currentUser?.result.userType === 'SYSTEM' ? (
                            <>
                              <th className='min-w-100px'>Time Conflict</th>
                              <th className='min-w-100px text-end'>Action</th>
                            </>
                          ) : (
                            <th className='min-w-100px text-end'>Time Conflict</th>
                          )}
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {interpreters?.length > 0 ? (
                          interpreters
                            .filter((item: any) => item?.assignStatus === 3)
                            .map((item: any) => (
                              <tr>
                                <td>
                                  <Link to={`/profile/${item?.fK_Interpreter}`}>
                                    <a className='text-gray-800 text-hover-primary fs-6'>
                                      <div className='d-flex align-items-center'>
                                        {item.profileImage !== '' ? (
                                          <div className='symbol symbol-45px me-5 rounded-circle'>
                                            <img
                                              src={
                                                process.env.REACT_APP_IMG_URL +
                                                '/profile/' +
                                                item.profileImage
                                              }
                                              alt=''
                                              className='rounded-circle'
                                            />
                                          </div>
                                        ) : (
                                          <div className='symbol symbol-circle symbol-45px overflow-hidden me-3'>
                                            <div
                                              className={clsx(
                                                'symbol-label fs-4',
                                                'badge-light-success',
                                                `bg-light-success`
                                              )}
                                            >
                                              <span style={{fontSize: '20x'}}>
                                                {(item?.name ?? '').charAt(0).toUpperCase() +
                                                  (item?.name ?? '').charAt(1).toUpperCase()}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        <div className='d-flex justify-content-start '>
                                          <div className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                            {item?.name ?? 'N/A'}
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </Link>
                                </td>

                                {currentUser?.result.userType === 'SYSTEM' && (
                                  <td>
                                    <a className='text-gray-800 text-hover-primary fs-6'>
                                      ${item?.rate ?? 'N/A'}
                                    </a>
                                  </td>
                                )}

                                <td>{statusMapping[item?.assignStatus]}</td>

                                {/* {appointmentDetails?.communicationTypeIdentification ===
                                  'ON_SITE' && (
                                  <td>
                                    <a className='text-gray-800 text-hover-primary fs-6'>
                                      {item?.distance ?? 'N/A'}
                                    </a>
                                  </td>
                                )} */}

                                {currentUser?.result.userType === 'SYSTEM' &&
                                appointmentDetails?.status === 2 ? (
                                  <>
                                    <td>
                                      <div className='d-flex'>
                                        <a
                                          href='#'
                                          className={`btn btn-icon btn-bg-light btn-color-${
                                            item?.isTimeConflict ? 'danger' : 'primary'
                                          } btn-sm`}
                                        >
                                          <i className='bi bi-calendar'></i>
                                        </a>
                                      </div>
                                    </td>

                                    <td>
                                      <OverlayTrigger
                                        placement='bottom'
                                        overlay={<Tooltip id='tooltip-filter'>Unassign</Tooltip>}
                                      >
                                        <div className='d-flex justify-content-end '>
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-danger btn-icon px-4'
                                            data-bs-toggle='modal'
                                            data-bs-target='#cancel-assign-interperter-modal'
                                            data-bs-backdrop='static'
                                            data-bs-keyboard='false'
                                            onClick={() =>
                                              setInitialValueUnassignConfirmedInterperter({
                                                appointmentId: item?.fK_appoinment,
                                                interpreterId: item?.fK_Interpreter,
                                                reason: '',
                                              })
                                            }
                                          >
                                            <i className='bi bi-x-circle'></i>
                                          </button>
                                        </div>
                                      </OverlayTrigger>
                                    </td>
                                  </>
                                ) : (
                                  <td>
                                    <div className='d-flex justify-content-end '>
                                      <a
                                        href='#'
                                        className={`btn btn-icon btn-bg-light btn-color-${
                                          item?.isTimeConflict ? 'danger' : 'primary'
                                        } btn-sm`}
                                      >
                                        <i className='bi bi-calendar'></i>
                                      </a>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                <div className='text-center'>
                                  <div className='symbol symbol-200px '>
                                    <img src='/media/other/nodata.png' alt='' />
                                  </div>
                                </div>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                  No matching records found
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>

                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                )}
              {activeTab === 1 && currentUser?.result?.userType === 'SYSTEM' && (
                <>
                  {countInterpretersByStatus(3) > 0 ? (
                    <div className='accordion' id='kt_accordion_1'>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                          <button
                            className='accordion-button fs-4 fw-bold collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_accordion_1_body_1'
                            aria-expanded='false'
                            aria-controls='kt_accordion_1_body_1'
                          >
                            Invited & Rejected Interpreters (
                            {interpreters?.length - countInterpretersByStatus(3)})
                          </button>
                        </h2>
                        <div
                          id='kt_accordion_1_body_1'
                          className='accordion-collapse collapse'
                          aria-labelledby='kt_accordion_1_header_1'
                          data-bs-parent='#kt_accordion_1'
                        >
                          <div className='accordion-body'>
                            <div className='table-responsive'>
                              {/* Table Begin */}
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                                <thead>
                                  <tr className='fw-semibold text-muted text-uppercase'>
                                    <th
                                      className='min-w-150px text-uppercase'
                                      style={{width: '30%'}}
                                    >
                                      Invited & Rejected Interpreters (
                                      {interpreters?.length - countInterpretersByStatus(3)})
                                    </th>
                                    <th className='min-w-100px'>Rate</th>
                                    <th className='min-w-100px'>Status</th>
                                    {/* {appointmentDetails?.communicationTypeIdentification ===
                                      'ON_SITE' && <th className='min-w-100px'>Distance</th>} */}
                                    <th className='min-w-100px text-end'>Time Conflict</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {interpreters?.length > 0 ? (
                                    interpreters
                                      .filter((item: any) => item?.assignStatus !== 3)
                                      .map((item: any) => (
                                        <tr key={item?.fK_Interpreter}>
                                          <td>
                                            <Link to={`/profile/${item?.fK_Interpreter}`}>
                                              <a className='text-gray-800 text-hover-primary fs-6'>
                                                <div className='d-flex align-items-center'>
                                                  {item.profileImage ? (
                                                    <div className='symbol symbol-45px me-5 rounded-circle'>
                                                      <img
                                                        src={`${process.env.REACT_APP_IMG_URL}/profile/${item.profileImage}`}
                                                        alt=''
                                                        className='rounded-circle'
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div className='symbol symbol-circle symbol-45px overflow-hidden me-3'>
                                                      <div className='symbol-label fs-4 badge-light-success bg-light-success'>
                                                        <span style={{fontSize: '20px'}}>
                                                          {(item?.name ?? 'N/A')
                                                            .slice(0, 2)
                                                            .toUpperCase()}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                                  <div className='d-flex justify-content-start'>
                                                    <div className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                                      {item?.name ?? 'N/A'}
                                                    </div>
                                                  </div>
                                                </div>
                                              </a>
                                            </Link>
                                          </td>
                                          {currentUser?.result.userType === 'SYSTEM' && (
                                            <td>
                                              <a className='text-gray-800 text-hover-primary fs-6'>
                                                ${item?.rate ?? 'N/A'}
                                              </a>
                                            </td>
                                          )}
                                          <td>{statusMapping[item?.assignStatus]}</td>
                                          {/* {appointmentDetails?.communicationTypeIdentification ===
                                            'ON_SITE' && (
                                            <td>
                                              <a className='text-gray-800 text-hover-primary fs-6'>
                                                {item?.distance ?? 'N/A'}
                                              </a>
                                            </td>
                                          )} */}
                                          {currentUser?.result.userType === 'SYSTEM' && (
                                            <td className=''>
                                              <div className='d-flex justify-content-end'>
                                                <a
                                                  href='#'
                                                  className={`btn btn-icon btn-bg-light btn-color-${
                                                    item?.isTimeConflict ? 'danger' : 'primary'
                                                  } btn-sm`}
                                                >
                                                  <i className='bi bi-calendar'></i>
                                                </a>
                                              </div>
                                            </td>
                                          )}
                                        </tr>
                                      ))
                                  ) : (
                                    <tr>
                                      <td colSpan={7}>
                                        <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                          <div className='text-center'>
                                            <div className='symbol symbol-200px'>
                                              <img src='/media/other/nodata.png' alt='' />
                                            </div>
                                          </div>
                                          <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                            No matching records found
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              {/* Table End */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='table-responsive'>
                      {/* Table Begin */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                        <thead>
                          <tr className='fw-semibold text-muted text-uppercase'>
                            <th className='min-w-150px text-uppercase' style={{width: '30%'}}>
                              Invited & Rejected Interpreters (
                              {interpreters?.length - countInterpretersByStatus(3)})
                            </th>
                            <th className='min-w-100px'>Rate</th>
                            <th className='min-w-100px'>Status</th>
                            {/* {appointmentDetails?.communicationTypeIdentification === 'ON_SITE' && (
                              <th className='min-w-100px'>Distance</th>
                            )} */}
                            <th className='min-w-100px'>Time Conflict</th>
                            <th className='min-w-50px text-end'>Assign</th>
                          </tr>
                        </thead>
                        <tbody>
                          {interpreters?.length > 0 ? (
                            interpreters
                              .filter((item: any) => item?.assignStatus !== 3)
                              .sort((a: any, b: any) => (a.assignStatus === 1 ? -1 : 1))
                              .map((item: any) => (
                                <tr key={item?.fK_Interpreter}>
                                  <td>
                                    <Link to={`/profile/${item?.fK_Interpreter}`}>
                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                        <div className='d-flex align-items-center'>
                                          {item.profileImage ? (
                                            <div className='symbol symbol-45px me-5 rounded-circle'>
                                              <img
                                                src={`${process.env.REACT_APP_IMG_URL}/profile/${item.profileImage}`}
                                                alt=''
                                                className='rounded-circle'
                                              />
                                            </div>
                                          ) : (
                                            <div className='symbol symbol-circle symbol-45px overflow-hidden me-3'>
                                              <div className='symbol-label fs-4 badge-light-success bg-light-success'>
                                                <span style={{fontSize: '20px'}}>
                                                  {(item?.name ?? 'N/A').slice(0, 2).toUpperCase()}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          <div className='d-flex justify-content-start'>
                                            <div className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                              {item?.name ?? 'N/A'}
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </Link>
                                  </td>
                                  {currentUser?.result.userType === 'SYSTEM' && (
                                    <td>
                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                        ${item?.rate ?? 'N/A'}
                                      </a>
                                    </td>
                                  )}
                                  <td>{statusMapping[item?.assignStatus]}</td>
                                  {/* {appointmentDetails?.communicationTypeIdentification ===
                                    'ON_SITE' && (
                                    <td>
                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                        {item?.distance ?? 'N/A'}
                                      </a>
                                    </td>
                                  )} */}
                                  {currentUser?.result.userType === 'SYSTEM' && (
                                    <td>
                                      <div className='d-flex'>
                                        <a
                                          href='#'
                                          className={`btn btn-icon btn-bg-light btn-color-${
                                            item?.isTimeConflict ? 'danger' : 'primary'
                                          } btn-sm`}
                                        >
                                          <i className='bi bi-calendar'></i>
                                        </a>
                                      </div>
                                    </td>
                                  )}
                                  {currentUser?.result.userType === 'SYSTEM' &&
                                    (item?.assignStatus === 0 || item?.assignStatus === 1) &&
                                    (appointmentDetails?.status === 0 ||
                                      appointmentDetails?.status === 1) && (
                                      <td
                                        className='d-flex justify-content-end align-items-center border-bottom-0'
                                        style={{marginTop: '10px'}}
                                      >
                                        <OverlayTrigger
                                          placement='left'
                                          overlay={<Tooltip id='tooltip-filter'>Assign</Tooltip>}
                                        >
                                          <div className='d-flex justify-content-end'>
                                            <button
                                              className='btn btn-success btn-sm btn-icon'
                                              data-bs-toggle='modal'
                                              data-bs-target='#forcefully_assign_interpreters_confirm'
                                              onClick={() =>
                                                setForcefullyAssignInterpretersConfirmDetails({
                                                  statusId: 3,
                                                  appointmentId: item.code,
                                                  interpreterId: item?.fK_Interpreter,
                                                  currentStatus: item?.assignStatus,
                                                })
                                              }
                                            >
                                              <i className='bi bi-check-lg'></i>
                                            </button>
                                          </div>
                                        </OverlayTrigger>
                                      </td>
                                    )}
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                  <div className='text-center'>
                                    <div className='symbol symbol-200px'>
                                      <img src='/media/other/nodata.png' alt='' />
                                    </div>
                                  </div>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                    No matching records found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* Table End */}
                    </div>
                  )}
                </>
              )}

              {activeTab === 1 &&
                currentUser?.result?.userType !== 'INTERPRETER' &&
                appointmentDetails?.status !== 5 &&
                appointmentDetails?.status?.assignStatus !== 6 && (
                  <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                    <div className='accordion' id='kt_accordion_1'>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_2_header_1'>
                          <button
                            className='accordion-button fs-4 fw-bold collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_accordion_2_body_1'
                            aria-expanded='false'
                            aria-controls='kt_accordion_2_body_1'
                          >
                            Service type not matched Interpreters ( {notMatchInterpreters?.length} )
                          </button>
                        </h2>
                        <div
                          id='kt_accordion_2_body_1'
                          className='accordion-collapse collapse'
                          aria-labelledby='kt_accordion_2_header_1'
                          data-bs-parent='#kt_accordion_1'
                        >
                          <div className='accordion-body'>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                                <thead>
                                  <tr className='fw-semibold text-muted text-uppercase'>
                                    <th
                                      className='min-w-150px text-uppercase'
                                      style={{width: '30%'}}
                                    >
                                      Service type not matched Interpreters(
                                      {countNoMatchInterpretersByStatus(3)}/
                                      {notMatchInterpreters?.length})
                                    </th>
                                    {currentUser?.result.userType !== 'CONSUMER' && (
                                      <th className='min-w-100px'>Rate</th>
                                    )}
                                    <th className='min-w-100px'>Status</th>
                                    {/* {appointmentDetails?.communicationTypeIdentification ===
                                      'ON_SITE' && <th className='min-w-100px'>Distance</th>} */}
                                    {countInterpretersByStatus(3) > 0 && (
                                      <>
                                        {currentUser?.result.userType !== 'CONSUMER' && (
                                          <th className='min-w-100px text-end'>Time Conflict</th>
                                        )}
                                      </>
                                    )}
                                    {countInterpretersByStatus(3) <= 0 && (
                                      <>
                                        {currentUser?.result.userType !== 'CONSUMER' && (
                                          <th className='min-w-100px'>Time Conflict</th>
                                        )}
                                        {currentUser?.result.userType !== 'CONSUMER' && (
                                          <th className='min-w-50px text-end'>Invite</th>
                                        )}
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {notMatchInterpreters?.length > 0 ? (
                                    notMatchInterpreters?.map((item: any) => (
                                      <tr key={item?.fK_Interpreter}>
                                        <td>
                                          <Link to={`/profile/${item?.fK_Interpreter}`}>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              <div className='d-flex align-items-center'>
                                                {item.profileImage ? (
                                                  <div className='symbol symbol-45px me-5 rounded-circle'>
                                                    <img
                                                      src={`${process.env.REACT_APP_IMG_URL}/profile/${item.profileImage}`}
                                                      alt=''
                                                      className='rounded-circle'
                                                    />
                                                  </div>
                                                ) : (
                                                  <div className='symbol symbol-circle symbol-45px overflow-hidden me-3'>
                                                    <div className='symbol-label fs-4 badge-light-success bg-light-success'>
                                                      <span style={{fontSize: '20x'}}>
                                                        {(item?.name ?? '')
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          (item?.name ?? '')
                                                            .charAt(1)
                                                            .toUpperCase()}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                                <div className='text-gray-900 fw-bold text-hover-primary fs-6'>
                                                  {item?.name ?? 'N/A'}
                                                </div>
                                              </div>
                                            </a>
                                          </Link>
                                        </td>
                                        {currentUser?.result.userType !== 'CONSUMER' && (
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              ${item?.rate ?? 'N/A'}
                                            </a>
                                          </td>
                                        )}
                                        <td>{statusMapping[item?.assignStatus]}</td>
                                        {/* {appointmentDetails?.communicationTypeIdentification ===
                                          'ON_SITE' && (
                                          <td>
                                            <a className='text-gray-800 text-hover-primary fs-6'>
                                              {item?.distance ?? 'N/A'}
                                            </a>
                                          </td>
                                        )} */}
                                        {countInterpretersByStatus(3) > 0 && (
                                          <>
                                            {currentUser?.result.userType !== 'CONSUMER' && (
                                              <td>
                                                <div className='d-flex justify-content-end'>
                                                  <a
                                                    href='#'
                                                    className={`btn btn-icon btn-bg-light btn-color-${
                                                      item?.isTimeConflict ? 'danger' : 'primary'
                                                    } btn-sm`}
                                                  >
                                                    <i className='bi bi-calendar'></i>
                                                  </a>
                                                </div>
                                              </td>
                                            )}
                                          </>
                                        )}
                                        {countInterpretersByStatus(3) <= 0 && (
                                          <>
                                            {currentUser?.result.userType !== 'CONSUMER' && (
                                              <td>
                                                <div className='d-flex'>
                                                  <a
                                                    href='#'
                                                    className={`btn btn-icon btn-bg-light btn-color-${
                                                      item?.isTimeConflict ? 'danger' : 'primary'
                                                    } btn-sm`}
                                                  >
                                                    <i className='bi bi-calendar'></i>
                                                  </a>
                                                </div>
                                              </td>
                                            )}
                                            {currentUser?.result.userType !== 'CONSUMER' && (
                                              <td>
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                  <button
                                                    className='btn btn-success btn-sm btn-icon'
                                                    onClick={() =>
                                                      handleInviteInterpreter(
                                                        item.fK_appoinment,
                                                        item?.fK_Interpreter
                                                      )
                                                    }
                                                    disabled={
                                                      appointmentDetails?.status == 0 ||
                                                      appointmentDetails?.status == 1
                                                        ? false
                                                        : true
                                                    }
                                                  >
                                                    <KTSVG
                                                      path='/media/icons/duotune/general/gen016.svg'
                                                      className='svg-icon-muted svg-icon-1hx'
                                                    />
                                                  </button>
                                                </div>
                                              </td>
                                            )}
                                          </>
                                        )}
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={7}>
                                        <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                          <div className='text-center'>
                                            <div className='symbol symbol-200px'>
                                              <img src='/media/other/nodata.png' alt='' />
                                            </div>
                                          </div>
                                          <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                            No matching records found
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {activeTab === 2 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                  <div className='row g-4'>
                    <div className='col-md-6 col-sm-12 pt-5 '>
                      <h3 className='fs-6 fw-semibold text-gray-800 mb-5'>Appointment History</h3>
                      <div className='separator my-3'></div>
                      <div
                        className='scrollable-notes'
                        style={{maxHeight: '50vh', overflowY: 'auto'}}
                      >
                        {activities?.histories?.length > 0 ? (
                          activities?.histories?.map((item: any) => (
                            <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                              <div className='card-body p-4'>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <div className='d-flex align-items-center w-100'>
                                    <div className='symbol-group symbol-hover me-3'>
                                      {item.profileImages?.length > 0 ? (
                                        item.profileImages?.map((image: any) =>
                                          image ? (
                                            <div className='symbol symbol-50px symbol-circle d-flex align-items-start'>
                                              <img
                                                src={
                                                  process.env.REACT_APP_IMG_URL +
                                                  '/profile/' +
                                                  image
                                                }
                                                alt=''
                                                className=''
                                                style={{objectFit: 'cover'}}
                                              />
                                            </div>
                                          ) : (
                                            <div className='symbol symbol-50px symbol-circle overflow-hidden '>
                                              <div
                                                className={clsx(
                                                  'symbol-label fs-3',
                                                  'badge-light-success',
                                                  `bg-light-success`
                                                )}
                                              >
                                                <span style={{fontSize: '20x'}}>
                                                  {(item?.userName ?? '').charAt(0).toUpperCase() +
                                                    (item?.userName ?? '').charAt(1).toUpperCase()}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <div className='symbol symbol-100px symbol-circle overflow-hidden'>
                                          <div
                                            className={clsx(
                                              'symbol-label fs-3',
                                              'badge-light-success',
                                              `bg-light-success`
                                            )}
                                          >
                                            <span style={{fontSize: '20x'}}>
                                              {(item?.userName ?? '').charAt(0).toUpperCase() +
                                                (item?.userName ?? '').charAt(1).toUpperCase()}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='d-flex flex-column w-100'>
                                      <div className='d-flex justify-content-between w-100 flex-wrap'>
                                        <div className='d-flex flex-wrap'>
                                          <span className='fw-bold d-flex align-items-center fs-6 me-1 me-3'>
                                            {item?.name}
                                          </span>
                                          <div className='d-flex'>
                                            <span className='badge  badge-secondary px-3 fs-8'>
                                              {item?.userName ?? 'N/A'}
                                            </span>
                                          </div>
                                        </div>

                                        <div className='d-flex text-gray-600'>
                                          <span className='fs-8'>
                                            <i className='bi bi-calendar-check me-2'></i>
                                            {moment(item.insertedDateTime).format(
                                              'MM/DD/YY hh:mm A'
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className='d-flex flex-column flex-1 mt-1'>
                                        <span className='fs-7'>{item?.description ?? 'N/A'}</span>
                                      </div>
                                      {JSON.parse(item?.additionalInfo)?.length > 0 &&
                                        JSON.parse(item?.additionalInfo)?.map((x: any) => (
                                          <div className='d-flex flex-column flex-1 mt-1'>
                                            <span className='fs-7'>&#8226; {x ?? 'N/A'}</span>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-200px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 pt-5'>
                      <h3 className='fs-6 fw-semibold text-gray-800 mb-5'>Notes</h3>
                      <div className='separator my-3'></div>
                      <div
                        className='scrollable-notes'
                        style={{maxHeight: '50vh', overflowY: 'auto'}}
                      >
                        {notes?.length > 0 ? (
                          notes?.map((item: any) => (
                            <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                              <div className='card-body p-4'>
                                <div className='d-flex justify-content-between flex-nowrap w-100'>
                                  <div className='d-flex align-items-center w-100'>
                                    <div className='symbol-group symbol-hover me-3'>
                                      {item.avatar ? (
                                        <div className='symbol symbol-circle symbol-50px'>
                                          <img
                                            src={
                                              process.env.REACT_APP_IMG_URL +
                                              '/profile/' +
                                              item.avatar
                                            }
                                            alt=''
                                            className='rounded-circle'
                                            style={{objectFit: 'cover'}}
                                          />
                                        </div>
                                      ) : (
                                        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                          <div
                                            className={clsx(
                                              'symbol-label fs-3',
                                              'badge-light-success',
                                              `bg-light-success`
                                            )}
                                          >
                                            <span style={{fontSize: '20px'}}>
                                              {(item?.insertedBy ?? '').charAt(0).toUpperCase() +
                                                (item?.insertedBy ?? '').charAt(1).toUpperCase()}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className='d-flex flex-column w-100'>
                                      <div className='d-flex justify-content-between w-100 flex-wrap'>
                                        <div className='d-flex flex-wrap'>
                                          <span className='fw-bold d-flex align-items-center fs-6 me-1 me-3'>
                                            {item?.noteHeader}
                                          </span>
                                          <div className='d-flex'>
                                            <span className='badge  badge-secondary px-3 fs-8'>
                                              {item?.insertedByName ?? 'N/A'}
                                            </span>
                                          </div>
                                        </div>

                                        <div className='d-flex text-gray-600'>
                                          <span className='fs-8'>
                                            <i className='bi bi-calendar-check me-2'></i>
                                            {moment(item.insertedDateTime)
                                              .local()
                                              .format('MM/DD/YY hh:mm A')}
                                          </span>
                                        </div>
                                      </div>

                                      <div className='d-flex flex-column flex-1 mt-1'>
                                        <span className='fs-7'>{item?.note ?? 'N/A'}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                            <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                              <div className='text-center'>
                                <div className='symbol symbol-200px '>
                                  <img src='/media/other/nodata.png' alt='' />
                                </div>
                              </div>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                No matching records found
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                  <div className='row g-4'>
                    <div className='col-md-12 col-sm-12 pt-5 '>
                      {appointmentDetails?.status === 5 ? (
                        <>
                          {(currentUser?.result?.userType === 'SYSTEM' ||
                            currentUser?.result?.userType === 'CONSUMER') && (
                            <div className='card mb-2 shadow-sm'>
                              <div className='rounded-sm'>
                                {currentUser?.result?.userType === 'SYSTEM' && (
                                  <div className='card-header' style={{minHeight: '50px'}}>
                                    <div className='d-flex align-items-center'>
                                      <h3 className='card-title text-dark'>Requester Charges</h3>
                                    </div>
                                  </div>
                                )}
                                <div className='card-body py-2'>
                                  <div className='w-50 '>
                                    <div className='d-flex justify-content-between flex-nowrap w-100'>
                                      <span className='fs-4 fw-bold text-gray-600'>Summary</span>
                                    </div>
                                    <div className='d-flex flex-column mt-3'>
                                      <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                        <div className='d-flex align-items-center '>
                                          <div className='d-flex flex-column w-auto'>
                                            <span className='fs-6'>Rate</span>
                                          </div>
                                        </div>
                                        <div className='text-dark fw-semibold d-flex'>
                                          ${billingDetails?.invoiceDetail?.rate?.toFixed(2) ?? '0'}
                                        </div>
                                      </div>
                                      <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                        <div className='d-flex align-items-center '>
                                          <div className='d-flex flex-column w-auto'>
                                            <span className='fs-6'>Expenses</span>
                                          </div>
                                        </div>
                                        <div className='text-dark fw-semibold d-flex'>
                                          {' '}
                                          $
                                          {billingDetails?.invoiceDetail?.extras?.toFixed(2) ?? '0'}
                                        </div>
                                      </div>
                                      <div className='separator separator-dashed border-dark my-3'></div>
                                      <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                        <div className='d-flex align-items-center'>
                                          <div className='d-flex flex-column w-auto'>
                                            <span className='fs-5 fw-bold'>Estimated Total</span>
                                          </div>
                                        </div>
                                        <div className='text-dark fw-bold d-flex fs-5'>
                                          $
                                          {(
                                            billingDetails?.invoiceDetail?.extras +
                                            billingDetails?.invoiceDetail?.rate
                                          )?.toFixed(2) ?? '0'}
                                        </div>
                                      </div>
                                      <div className='d-flex text-gray-500'>
                                        <span className='text-danger me-2 fs-4'>*</span> Minimum
                                        and/or incremental charges may apply
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {billingDetails?.invoiceDetail?.expenses?.length > 0 && (
                                  <div className='py-2 card-footer'>
                                    <div className='row g-1'>
                                      <div className='d-flex justify-content-between flex-nowrap w-100'>
                                        <span className='fs-4 fw-bold text-gray-600'>
                                          Expense Details
                                        </span>
                                      </div>

                                      <div className='py-0 pt-3'>
                                        <div className='table-responsive'>
                                          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
                                            <thead>
                                              <tr className='fw-semibold text-muted text-uppercase'>
                                                <th className='min-w-100px '>Created By</th>
                                                <th className='min-w-100px '>Created</th>
                                                <th className='min-w-100px '>Description</th>
                                                <th className='min-w-100px text-end'>Amount</th>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              {billingDetails?.invoiceDetail?.expenses?.map(
                                                (x: any) => (
                                                  <tr>
                                                    <td>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        {x?.createdBy ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                    <td>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        {moment(x?.date).format(
                                                          'DD/MM/YYYY h:mm A'
                                                        ) ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                    <td>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        {x?.description ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                    <td className='text-end'>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        ${x?.amount?.toFixed(2) ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {(currentUser?.result?.userType === 'SYSTEM' ||
                            currentUser?.result?.userType === 'INTERPRETER') && (
                            <div className='card mb-2 shadow-sm'>
                              <div className='rounded-sm '>
                                {currentUser?.result?.userType === 'SYSTEM' && (
                                  <div className='card-header' style={{minHeight: '50px'}}>
                                    <div className='d-flex align-items-center'>
                                      <h3 className='card-title text-dark'>
                                        Interpreter Pay -{' '}
                                        {appointmentDetails?.confirmedInterpreterName}
                                      </h3>
                                    </div>
                                  </div>
                                )}
                                <div className='card-body py-2'>
                                  <div className='w-50 '>
                                    <div className='d-flex justify-content-between flex-nowrap w-100'>
                                      <span className='fs-4 fw-bold text-gray-600'>Summary</span>
                                    </div>
                                    <div className='d-flex flex-column mt-3'>
                                      <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                        <div className='d-flex align-items-center '>
                                          <div className='d-flex flex-column w-auto'>
                                            <span className='fs-6'>Rate</span>
                                          </div>
                                        </div>
                                        <div className='text-dark fw-semibold d-flex'>
                                          ${billingDetails?.voucherDetail?.rate?.toFixed(2) ?? '0'}
                                        </div>
                                      </div>
                                      <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                        <div className='d-flex align-items-center '>
                                          <div className='d-flex flex-column w-auto'>
                                            <span className='fs-6'>Expenses</span>
                                          </div>
                                        </div>
                                        <div className='text-dark fw-semibold d-flex'>
                                          {' '}
                                          $
                                          {billingDetails?.voucherDetail?.extras?.toFixed(2) ?? '0'}
                                        </div>
                                      </div>
                                      <div className='separator separator-dashed border-dark my-3'></div>
                                      <div className='d-flex justify-content-between flex-wrap w-100 mb-2'>
                                        <div className='d-flex align-items-center '>
                                          <div className='d-flex flex-column w-auto'>
                                            <span className='fs-5 fw-bold'>Estimated Total</span>
                                          </div>
                                        </div>
                                        <div className='text-dark fw-bold d-flex fs-5'>
                                          $
                                          {(
                                            billingDetails?.voucherDetail?.rate +
                                            billingDetails?.voucherDetail?.extras
                                          )?.toFixed(2) ?? '0'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {billingDetails?.voucherDetail?.expenses?.length > 0 && (
                                  <div className='py-2 card-footer'>
                                    <div className='row g-1'>
                                      <div className='d-flex justify-content-between flex-nowrap w-100'>
                                        <span className='fs-4 fw-bold text-gray-600'>
                                          Expense Details
                                        </span>
                                      </div>

                                      <div className='py-0 pt-3'>
                                        <div className='table-responsive'>
                                          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
                                            <thead>
                                              <tr className='fw-semibold text-muted text-uppercase'>
                                                <th className='min-w-100px '>Created By</th>
                                                <th className='min-w-100px '>Created</th>
                                                <th className='min-w-100px '>Description</th>
                                                <th className='min-w-100px text-end'>Amount</th>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              {billingDetails?.voucherDetail?.expenses?.map(
                                                (x: any) => (
                                                  <tr>
                                                    <td>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        {x?.createdBy ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                    <td>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        {moment(x?.date).format(
                                                          'DD/MM/YYYY h:mm A'
                                                        ) ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                    <td>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        {x?.description ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                    <td className='text-end'>
                                                      <a className='text-gray-800 text-hover-primary fs-6'>
                                                        ${x?.amount?.toFixed(2) ?? 'N/A'}
                                                      </a>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                          <div className='text-center'>
                            <div className='symbol symbol-150px '>
                              <img src='/media/other/work-in-progress.gif' alt='' />
                            </div>
                          </div>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                            Your bill will be ready once the appointment is complete!
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 4 && (
                <>
                  <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
                    <div className='card py-0 mb-xl-5 mt-3'>
                      <div className='d-flex align-items-center justify-content-end'>
                        {currentUser?.result.userType !== 'INTERPRETER' &&
                          appointmentDetails?.status !== 6 && (
                            <OverlayTrigger
                              placement='top'
                              overlay={<Tooltip id='tooltip-filter'>Add Document</Tooltip>}
                            >
                              <div>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-primary btn-icon'
                                  data-bs-toggle='modal'
                                  data-bs-target='#document'
                                >
                                  <i className='bi bi-plus fs-2'></i>
                                </button>
                              </div>
                            </OverlayTrigger>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='py-0'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-semibold text-muted text-uppercase'>
                            {/* <th className='min-w-150px '>Appointment ID</th> */}
                            <th className='min-w-250px '>Name</th>
                            <th className='min-w-150px '>Description</th>
                            <th className='min-w-150px '>Date</th>
                            <th className='min-w-100px'>Type</th>
                            <th className='min-w-200px text-end'>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {documents?.length > 0 ? (
                            documents.map((item: any, index: number) => (
                              <tr style={{cursor: 'pointer'}}>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {item?.name}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {item?.description ?? 'N/A'}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {moment(item?.createdDateTime).format('MM/DD/YYYY hh:mm A')}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-gray-800 text-hover-primary fs-6'>
                                    {getFileType(item?.fileName)}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <DocumentViewModal
                                    documentCode={item?.code}
                                    fileName={item?.fileName}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                  <div className='text-center'>
                                    <div className='symbol symbol-200px '>
                                      <img src='/media/other/nodata.png' alt='' />
                                    </div>
                                  </div>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                    No matching records found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <CommonPaginationModel
                      currentPage={currentPage}
                      totalPages={totalPages}
                      rowsPerPage={rowsPerPage}
                      total={totalItems}
                      onPageChange={setCurrentPage}
                      onRowsPerPageChange={(newRowsPerPage: number) =>
                        setRowsPerPage(newRowsPerPage)
                      }
                    />
                  </div>
                </>
              )}
              {activeTab === 5 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_5' role='tabpanel'>
                  <div className='row gx-6'>
                    {appointmentDetails?.isOSIMeetingSummerySubmitted ? (
                      <>
                        <div className='col-md-6 col-sm-12 pt-2 mb-3 min-h-620px'>
                          <div className='border border-gray-500 border-dotted rounded p-5'>
                            <div className='d-flex justify-content-between align-items-center mb-3 flex-wrap'>
                              <div className='d-flex flex-column'>
                                <h3 className='d-flex fs-3 fw-semibold mb-2'>
                                  OSI Meeting Deatils{' '}
                                </h3>
                                {meetingSummary.status == 1 && (
                                  <label className='badge badge-light-warning fs-8'>Pending</label>
                                )}
                                {meetingSummary.status == 2 && (
                                  <label className='badge badge-light-success fs-8'>Approved</label>
                                )}
                                {meetingSummary.status == 3 && (
                                  <label className='badge badge-light-danger fs-8'>Rejected</label>
                                )}
                              </div>

                              <div className='d-flex justify-content-end w-100 mt-1 align-items-start'>
                                {currentUser?.result.userType === 'SYSTEM' &&
                                  meetingSummary.status == 1 && (
                                    <>
                                      <button
                                        className='btn text-white btn-success btn-sm mx-1'
                                        style={{fontSize: '12px'}}
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_approve_confirm'
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className='btn text-white btn-danger btn-sm mx-1'
                                        style={{fontSize: '12px'}}
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_reject_confirm'
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                                {meetingSummary.status == 3 &&
                                  currentUser?.result.userType === 'INTERPRETER' && (
                                    <button
                                      className='btn text-white btn-primary btn-sm mx-1'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_add_osi_details'
                                      style={{fontSize: '12px'}}
                                    >
                                      <i
                                        className='fa-solid fa-pen me-1'
                                        style={{fontSize: '12px'}}
                                      ></i>
                                      Edit
                                    </button>
                                  )}
                              </div>
                            </div>
                            <div className='separator my-3'></div>
                            <div className='d-flex mb-3 flex-column'>
                              <span className='d-flex mb-2'>
                                <span className='me-2 fw-bold'>Start Time : </span>{' '}
                                {moment(meetingSummary.startDate).format('hh:mm A')}
                              </span>
                              <span className='d-flex mb-2'>
                                <span className='me-2 fw-bold'>End Time: </span>

                                {moment(meetingSummary.endDate).format('hh:mm A')}
                              </span>
                              <span className='d-flex mb-2'>
                                <span className='me-2 fw-bold'>Milage Of Drive(miles) : </span>
                                {meetingSummary.milage}
                              </span>
                              <span className='d-flex mb-2'>
                                <span className='me-2 fw-bold'>Travel Time : </span>
                                {meetingSummary?.travalTimeH ?? 0}h :{' '}
                                {meetingSummary?.travalTimeM ?? 0}m
                              </span>

                              <div className='mb-3'>
                                <span className='d-flex mb-2 flex-wrap'>
                                  <span className='me-2 fw-bold'>Round-trip : </span>Automatically
                                  double distance from interpreter address to appointment location.
                                </span>
                                <div className='d-flex'>
                                  <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        checked={meetingSummary?.isRoundTrip}
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2'>
                                      <span
                                        className='text-gray-600 text-hover-primary '
                                        style={{fontSize: '12px'}}
                                      >
                                        On
                                      </span>
                                    </span>
                                  </label>
                                  <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                                    <span className='form-check form-check-custom form-check-white me-3'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        checked={!meetingSummary?.isRoundTrip}
                                      />
                                    </span>
                                    <span className='d-flex align-items-center me-2'>
                                      <span
                                        className='text-gray-800 text-hover-primary'
                                        style={{fontSize: '12px'}}
                                      >
                                        Off
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className='w-100'>
                              <span className='me-2 fw-bold'>Additional Expenses</span>
                              {meetingSummary.osiExpensesList?.length > 0 ? (
                                <div
                                  className='table-responsive'
                                  style={{minHeight: '250px', overflowY: 'auto'}}
                                >
                                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase '>
                                        <th className='min-w-100px '>Description</th>
                                        <th className='min-w-100px '>Amonut</th>
                                        <th className='min-w-50px text-end'>View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {meetingSummary.osiExpensesList?.map(
                                        (item: any, index: any) => (
                                          <tr>
                                            <td>{item?.description} </td>
                                            <td>{item?.amount}</td>
                                            <td className='text-end'>
                                              <OSIDocumentViewModal
                                                osiCode={meetingSummary?.code}
                                                documentCode={item?.code}
                                                fileName={item?.fileName}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className='card bg-white mb-2 mx-2'>
                                  <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                    <div className='text-center'>
                                      <div className='symbol symbol-200px '>
                                        <img src='/media/other/nodata.png' alt='' />
                                      </div>
                                    </div>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                      No additional expenses added
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-12 pt-2 mb-3 border border-gray-500 rounded border-dotted p-5 mt-2'>
                          <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div className='d-flex'>
                              <h3 className='d-flex fs-3 fw-semibold mb-3'>Notes</h3>
                            </div>
                            {appointmentDetails?.status !== 5 && (
                              <div className='d-fex'>
                                <button
                                  className='btn text-white btn-primary btn-sm mx-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_5'
                                  style={{fontSize: '12px'}}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen005.svg'
                                    className='svg-icon-muted'
                                  />
                                  Add Note
                                </button>
                              </div>
                            )}
                          </div>
                          <div className='separator my-3'></div>
                          <div
                            className='scrollable-notes'
                            style={{maxHeight: '50vh', overflowY: 'auto'}}
                          >
                            {osiNotes?.length > 0 ? (
                              osiNotes?.map((item: any) => (
                                <div className='card bg-white mb-2 border border-dotted border-gray-400 border-1 mx-2'>
                                  <div className='card-body p-4'>
                                    <div className='d-flex justify-content-between flex-nowrap w-100'>
                                      <div className='d-flex align-items-center w-100'>
                                        <div className='symbol-group symbol-hover me-3'>
                                          {item.avatar ? (
                                            <div className='symbol symbol-circle symbol-50px'>
                                              <img
                                                src={
                                                  process.env.REACT_APP_IMG_URL +
                                                  '/profile/' +
                                                  item.avatar
                                                }
                                                alt=''
                                                className='rounded-circle'
                                                style={{objectFit: 'cover'}}
                                              />
                                            </div>
                                          ) : (
                                            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                              <div
                                                className={clsx(
                                                  'symbol-label fs-3',
                                                  'badge-light-success',
                                                  `bg-light-success`
                                                )}
                                              >
                                                <span style={{fontSize: '20px'}}>
                                                  {(item?.insertedBy ?? '')
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    (item?.insertedBy ?? '')
                                                      .charAt(1)
                                                      .toUpperCase()}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div className='d-flex flex-column w-100'>
                                          <div className='d-flex justify-content-between w-100 flex-wrap'>
                                            <div className='d-flex flex-wrap'>
                                              {/* <span className='fw-bold d-flex align-items-center fs-6 me-1 me-3'>
                                                {item?.note}
                                              </span> */}
                                              <div className='d-flex'>
                                                <span className='badge  badge-secondary px-3 fs-8'>
                                                  {item?.insertedBy ?? 'N/A'}
                                                </span>
                                              </div>
                                            </div>

                                            <div className='d-flex text-gray-600'>
                                              <span className='fs-8'>
                                                <i className='bi bi-calendar-check me-2'></i>
                                                {moment(item.insertedDateTime)
                                                  .local()
                                                  .format('MM/DD/YY hh:mm A')}
                                              </span>
                                            </div>
                                          </div>

                                          <div className='d-flex flex-column flex-1 mt-1'>
                                            <span className='fs-7'>{item?.note ?? 'N/A'}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className='card bg-white mb-2 mx-2'>
                                <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                                  <div className='text-center'>
                                    <div className='symbol symbol-200px '>
                                      <img src='/media/other/nodata.png' alt='' />
                                    </div>
                                  </div>
                                  <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                                    No matching records found
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                        <div className='text-center'>
                          <div className='symbol symbol-150px '>
                            <img src='/media/other/work-in-progress.gif' alt='' />
                          </div>
                        </div>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                          Appointment details have not been submitted yet.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isOpenConfirmationModal && (
              <DeleteConfirmationModal
                title='Confirmation'
                content={message}
                onSubmit={async () => await handleAppoinmentCancelation()}
                setOpen={setIsOpenConfirmationModal}
              />
            )}

            <div className='modal fade' tabIndex={-1} id='kt_approve_confirm'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Confirmation</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='text-center mb-5'>
                      <div className='symbol symbol-100px '>
                        <img src='/media/other/process.gif' alt='' />
                      </div>
                    </div>
                    <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                      Are you sure you would like to approve this meeting summary?
                    </h4>
                  </div>
                  <div className='modal-footer py-3'>
                    <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                      Close
                    </button>

                    <button
                      type='button'
                      className='btn btn-success btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => handleChangeStatus(2)}
                    >
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal fade' tabIndex={-1} id='kt_reject_confirm'>
              <div className='modal-dialog'>
                <Formik
                  initialValues={{reason: ''}}
                  validationSchema={addRejectNoteSchema}
                  onSubmit={async (values, {setSubmitting, resetForm}) => {
                    setSubmitting(true)
                    try {
                      try {
                        let response = await axios.put(
                          `${API_URL}/Appoinment/osi-submission-admin-response`,
                          {
                            code: meetingSummary?.code,
                            status: 3,
                            reason: values.reason,
                          }
                        )
                        if (response.data.status === 'S') {
                          resetForm()
                          toaster('success', response.data.text ?? 'Successfuly updated')
                          const modal = document.getElementById('kt_reject_confirm')
                          if (modal) {
                            const modalInstance = Modal.getInstance(modal)
                            if (modalInstance) modalInstance.hide()
                          }
                          await fetchAfterSubmitOSISubmmission()
                        } else {
                          toaster('error', response.data.text ?? 'Fail Actions')
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    } catch (ex) {
                      console.error(ex)
                    }
                  }}
                >
                  {({
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    values,
                    touched,
                    isValid,
                    dirty,
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                    ...formik
                  }) => (
                    <Form>
                      <div className='modal-content'>
                        <div className='modal-header py-2'>
                          <h4 className='modal-title'>Confirmation</h4>
                          <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => resetForm()}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </div>
                        <div className='modal-body'>
                          <div className='text-center mb-5'>
                            <div className='symbol symbol-100px '>
                              <img src='/media/other/rejected.gif' alt='' />
                            </div>
                          </div>
                          <div className='mb-3'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label fs-7 mb-1  fs-7 mb-1'
                            >
                              Write a reason
                            </label>
                            <textarea
                              placeholder='write reason..'
                              name='reason'
                              id='reason'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.reason}
                              className='form-control form-control-sm custom-input-height  custom-input-height  form-select-white mb-lg-0'
                            ></textarea>
                            {errors.reason && touched.reason && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.reason}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='modal-footer py-3'>
                          <button
                            type='button'
                            className='btn btn-light btn-sm'
                            data-bs-dismiss='modal'
                            onClick={() => resetForm()}
                          >
                            Close
                          </button>

                          <button
                            type='button'
                            className='btn btn-danger btn-sm'
                            disabled={isSubmitting || !isValid}
                            onClick={() => handleSubmit()}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='forcefully_assign_interpreters_confirm'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Confirmation</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => setForcefullyAssignInterpretersConfirmDetails(null)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='text-center mb-5'>
                      <div className='symbol symbol-100px '>
                        <img src='/media/other/process.gif' alt='' />
                      </div>
                    </div>
                    {forcefullyAssignInterpretersConfirmDetails?.currentStatus === 0 ? (
                      <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                        Interpreter has not accepted the job, are you sure you want to assign this
                        interpreter for the appointment?
                      </h4>
                    ) : (
                      <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                        Are you sure you want to assign this interpreter for the appointment?
                      </h4>
                    )}
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => setForcefullyAssignInterpretersConfirmDetails(null)}
                    >
                      Close
                    </button>

                    <button
                      type='button'
                      className='btn btn-success btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() =>
                        handleAssign(
                          forcefullyAssignInterpretersConfirmDetails?.statusId,
                          forcefullyAssignInterpretersConfirmDetails?.appointmentId,
                          forcefullyAssignInterpretersConfirmDetails?.interpreterId
                        )
                      }
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <CommonLoading />}
      </div>
    </>
  )
}
