// LineChart.js
import { FC } from 'react'
import {Line} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

type Props = {
  details?: any
  label ?: string
}

const LineChart: FC<Props> = ({details, label}) => {
  // Data for the chart
  const data = {
    labels: details?.dateList,
    datasets: [
      {
        label: label,
        data: details?.callCountList,
        fill: true, // Enable the filling of the area under the line
        borderColor: '#e3759b',
        backgroundColor: '#ffdee9', // Change color of the area under the curve
        tension: 0.4, // Set tension to create a curve
      },
    ],
  }

  return <Line data={data} />
}

export default LineChart
