/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import AvarageLinechart from './AvarageLinechart'
import { Spinner } from 'react-bootstrap'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  title: string
  aggregateRateData?: any
  label ?: string
  loading?: boolean
}

export const AvarageOverviewChart: React.FC<Props> = ({className, title,aggregateRateData,label, loading}) => {
  return (
    <div className='card card-lg-stretch mb-5 mb-xl-8 shadow-custom'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-semibold text-dark fs-6'>{title}</h3>
      </div>

      {loading ? (
        <div
          className='card-body pt-0 d-flex align-items-center justify-content-center'
          style={{height: '300px'}}
        >
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className='card-body pt-0'>
          <AvarageLinechart details={aggregateRateData} label={label}/>
        </div>
      )}
    </div>
  )
}

