import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

// Define the API endpoint
const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`

// Define interfaces/types for your state structure
interface Customer {
  key: string
  value: string
}

interface CustomerState {
  items: Customer[]
  loading: boolean
  error: string | null
}

// Define the initial state using the CustomerState interface
const initialState: CustomerState = {
  items: [],
  loading: false,
  error: null,
}

// Async thunk for fetching customers - if needed
export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_CUSTOMER_NAME)
      return response.data.data as Customer[]
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

// Create the slice
const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // Reducer to set customer data directly from an external dispatch
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.items = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCustomers.fulfilled, (state, action: PayloadAction<Customer[]>) => {
        state.loading = false
        state.items = action.payload
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  },
})

// Export actions and reducer
export const { setCustomers } = customerSlice.actions
export default customerSlice.reducer
