import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_SERVICETYPES = `${API_URL}/master/getall/SERVICE_TYPE`


interface ServiceType {
    key: string
  value: string
}

interface ServiceTypeState {
  items: ServiceType[]
  loading: boolean
  error: string | null
}

const initialState: ServiceTypeState = {
  items: [],
  loading: false,
  error: null,
}

export const fetchServicetypes = createAsyncThunk(
  'service/fetchServicetypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_SERVICETYPES)
      return response.data.data as ServiceType[]
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

const serviceSlice = createSlice({
  name: 'Servicestype',
  initialState,
  reducers: {
    setservicetypes: (state, action: PayloadAction<ServiceType[]>) => {
        state.items = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServicetypes.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchServicetypes.fulfilled, (state, action: PayloadAction<ServiceType[]>) => {
        state.loading = false
        state.items = action.payload
      })
      .addCase(fetchServicetypes.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  },
})

export const { setservicetypes } = serviceSlice.actions
export default serviceSlice.reducer
