import {useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import moment from 'moment'
import {Form, Formik} from 'formik'
import {useSelector} from 'react-redux'
import {RootState} from '../../../redux/store'

type Props = {
  fetchAnalytics: any
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
}

export function DashboardAnalyticsFilter({
  fetchAnalytics,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: Props) {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  useEffect(() => {
    const defaultStartDate = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
    const defaultEndDate = moment().endOf('day').format('YYYY-MM-DD')

    setStartDate(defaultStartDate)
    setEndDate(defaultEndDate)
  }, [])

  const {items: responseCustomer, loading: isFetchingCustomer} = useSelector(
    (state: RootState) => state.customers
  )

  const customerOptions =
    responseCustomer?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  // Communication type options
  const CommunicationTypeOptions = [
    {value: 'Voice', label: 'OPI'},
    {value: 'Vedio', label: 'VRI'},
    {value: 'OSI', label: 'OSI'},
  ]
  const [data, setdata] = useState({
    customers: [],
    startDate: undefined,
    endDate: undefined,
    communicationTypes: [],
  })
  const handleSubmit = async (values: any) => {
    await fetchAnalytics(values)
    setShowDropdownMenu(false)
    setIsFilterApplied(true)
  }

  const handleReset = async () => {
    setdata({
      startDate: undefined,
      endDate: undefined,
      communicationTypes: [],
      customers: [],
    })
    setIsFilterApplied(false)
    setShowDropdownMenu(false)
    await fetchAnalytics({})
  }

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-350px' style={{minHeight: '100%'}}>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='d-flex justify-content-between'>
                            <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                              Customer(s)
                            </label>
                            <div className='col-md-2 d-flex justify-content-end align-items-end  mb-1'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='flexCustomers'
                                  checked={
                                    values.customers?.length === customerOptions?.length
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      'customers',
                                      values.customers?.length === customerOptions?.length
                                        ? []
                                        : customerOptions?.map((option: any) => option.value)
                                    )
                                  }
                                  onBlur={() => setFieldTouched('customers', true)}
                                />
                                <label className='form-check-label fs-8' htmlFor='flexCustomers'>
                                  All
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className='w-100 '>
                            <Select
                              {...formik.getFieldProps('customers')}
                              className='react-select-styled react-select-solid react-select-sm'
                              classNamePrefix='react-select'
                              options={!isFetchingCustomer ? customerOptions : []}
                              placeholder='Select Customer'
                              isMulti
                              value={
                                customerOptions?.filter(
                                  (option: any) =>
                                    Array.isArray(values.customers) &&
                                    (values.customers as string[]).includes(option.value)
                                ) || []
                              }
                              onChange={(selectedOptions) => {
                                setFieldValue(
                                  'customers',
                                  selectedOptions
                                    ? selectedOptions.map((option) => option.value)
                                    : []
                                )
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                  maxHeight: '300px',
                                  overflow: 'auto',
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <div className='d-flex justify-content-between'>
                          <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                            Communication Type(s)
                          </label>
                          <div className='col-md-2 d-flex justify-content-end align-items-end  mb-1'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='flexCommunicationType'
                                checked={
                                  values.communicationTypes?.length === CommunicationTypeOptions?.length
                                    ? true : false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    'communicationTypes',
                                    values.communicationTypes?.length === CommunicationTypeOptions?.length
                                      ? []
                                      : CommunicationTypeOptions?.map((option: any) => option.value)
                                  )
                                }
                                onBlur={() => setFieldTouched('communicationTypes', true)}
                              />
                              <label className='form-check-label fs-8' htmlFor='flexCommunicationType'>
                                All
                              </label>
                            </div>
                          </div>
                          </div>
                          
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={CommunicationTypeOptions}
                            placeholder='Select Communication Type(s)'
                            isMulti
                            value={
                              CommunicationTypeOptions?.filter(
                                (option: any) =>
                                  Array.isArray(values.communicationTypes) &&
                                  (values.communicationTypes as string[]).includes(option.value)
                              ) || []
                            }
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                'communicationTypes',
                                selectedOptions ? selectedOptions.map((option) => option.value) : []
                              )
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                                maxHeight: '300px',
                                overflow: 'auto',
                              }),
                            }}
                          />
                        </div>
                      </div>

                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                              Start Date
                            </label>
                            <input
                              {...formik.getFieldProps('startDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.startDate}
                              onChange={(e) => setFieldValue('startDate', e.target.value)}
                            />
                          </div>
                          <div className='col-sm-6'>
                            <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                              End Date
                            </label>
                            <input
                              {...formik.getFieldProps('endDate')}
                              type='date'
                              className='form-control form-control-white form-select-sm custom-input-height'
                              value={values.endDate}
                              onChange={(e) => setFieldValue('endDate', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={handleReset}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
