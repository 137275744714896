import {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../../UI/members-management/users-list/core/_models'
import {Form, Formik} from 'formik'
import {useSelector} from 'react-redux'
import {RootState} from '../../../redux/store'

const API_URL = process.env.REACT_APP_API_URL
// const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`

const communication = [
  {value: 'OPI', label: 'OPI'},
  {value: 'VRI', label: 'VRI'},
  {value: 'OSI', label: 'OSI'},
]

type Props = {
  setAlloperationDetails: any
  setIsLoading: any
  fetchOperationDetails: any
  setSearchQuery: any
}
export function DashboardOperationFilter({
  setAlloperationDetails,
  setIsLoading,
  fetchOperationDetails,
  setSearchQuery,
}: Props) {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [data, setdata] = useState({
    customer: undefined,
    langs: undefined,
    communicationTypes: undefined,
  })

  // const getcommunicationtypes = (): Promise<DropdownResponse> => {
  //   return axios
  //     .get(`${GET_ALL_COMMUNICATIONTYPES}`)
  //     .then((d: AxiosResponse<DropdownResponse>) => d.data)
  // }

  const {items: responseCustomer, loading: isFetchingCustomer} = useSelector(
    (state: RootState) => state.customers
  )

  const {items: responseLanguage, loading: isFetchingLanguage} = useSelector(
    (state: RootState) => state.languages
  )

  // const {isFetching: isFetchingcommunication, data: communicationTypeList} = useQuery(
  //   `Query.communicationTypeList`,
  //   getcommunicationtypes,
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )

  const languageOptions =
    responseLanguage?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  // const communicationTypeOptions =
  //   communicationTypeList?.data?.map((d) => ({
  //     value: d.key ?? '',
  //     label: d.value ?? '',
  //   })) ?? []

  const customerOptions =
    responseCustomer?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleSubmit = (data: any) => {
    setIsLoading(true)
    const SUBMIT_FILTERS_URL = `${API_URL}/Dashboard/operations`

    setSearchQuery(data)
    axios
      .post(SUBMIT_FILTERS_URL, data)
      .then((response) => {
        const {data: appointments, payload} = response.data
        setAlloperationDetails(appointments)
        setShowDropdownMenu(false)
        setIsFilterApplied(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-light-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-300px' style={{minHeight: '100%'}}>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <Formik
              enableReinitialize
              initialValues={data}
              onSubmit={async (values, {setSubmitting, resetForm}) => {
                setSubmitting(true)
                setdata(values)
                handleSubmit(values)
                setShowDropdownMenu(false)
              }}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                resetForm,
                values,
                errors,
                touched,
                isValid,
                dirty,
                ...formik
              }) => (
                <Form>
                  <div className='separator border-gray-200'></div>
                  <div className='card px-4 mt-4'>
                    <div className='row flex-column'>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                            Customer Name
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('customer')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingCustomer ? customerOptions : []}
                                  placeholder='Select Customer'
                                  value={
                                    customerOptions?.find(
                                      (option: any) => option.value === values.customer
                                    ) || null
                                  }
                                  onChange={(option) =>
                                    setFieldValue('customer', option?.value || '')
                                  }
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='languages' className='form-label fs-7 mb-1'>
                            Language
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('langs')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={!isFetchingLanguage ? languageOptions : []}
                                  placeholder='Select Language'
                                  value={
                                    languageOptions?.find(
                                      (option: any) => option.value === values.langs
                                    ) || null
                                  }
                                  onChange={(option) => setFieldValue('langs', option?.value || '')}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-12'>
                        <div className='mb-3'>
                          <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                            Communication Type
                          </label>
                          <div className='row g-4 g-xl-4'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='w-100'>
                                <Select
                                  {...formik.getFieldProps('communicationTypes')}
                                  className='react-select-styled react-select-solid react-select-sm'
                                  classNamePrefix='react-select'
                                  options={communication}
                                  placeholder='Select Communication Type'
                                  value={
                                    communication.find(
                                      (option) => option.value === values.communicationTypes
                                    ) || null
                                  } 
                                  onChange={(option) =>
                                    setFieldValue('communicationTypes', option?.value || '')
                                  }
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      width: '100%',
                                      border: '1px solid #e4e6ef',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end pt-5 px-4'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      data-kt-menu-dismiss='true'
                      onClick={() => {
                        resetForm()
                        setdata({
                          customer: undefined,
                          langs: undefined,
                          communicationTypes: undefined,
                        })
                        setSearchQuery({})
                        fetchOperationDetails({})
                        setShowDropdownMenu(false)
                        setIsFilterApplied(false)
                      }}
                    >
                      Reset
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                      onClick={(e) => handleSubmit}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Apply
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}
