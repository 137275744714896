import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_COMMUNICATION_TYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`

interface Communication {
    key: string
  value: string
}

interface CommunicationState {
  items: Communication[]
  loading: boolean
  error: string | null
}

const initialState: CommunicationState = {
  items: [],
  loading: false,
  error: null,
}

export const fetchCommunication = createAsyncThunk(
  'Communication/fetchCommunication',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_COMMUNICATION_TYPES)
      return response.data.data as Communication[]
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

const communicationSlice = createSlice({
  name: 'Communication',
  initialState,
  reducers: {
    setCommunication: (state, action: PayloadAction<Communication[]>) => {
        state.items = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunication.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCommunication.fulfilled, (state, action: PayloadAction<Communication[]>) => {
        state.loading = false
        state.items = action.payload
      })
      .addCase(fetchCommunication.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  },
})

export const { setCommunication } = communicationSlice.actions
export default communicationSlice.reducer
