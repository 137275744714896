import React, {useEffect, useState} from 'react'
import {HubConnectionBuilder, HubConnection} from '@microsoft/signalr'
import {RemindarScheduleAppointmentModal} from '../../../../app/common/Models/remindar-schedule-appointment-modal/RemindarScheduleAppointmentModal'
import {useAuth} from '../../../../app/modules/auth'

const RemindarSignalRHub: React.FC = () => {
  const {auth} = useAuth()
  const [isOpenRemindarScheduleAppointmentModal, setIsOpenRemindarScheduleAppointmentModal] =
    useState(false)
  const [signalRConnection, setSignalRConnection] = useState<HubConnection | null>(null)
  const [remindarMessage, setRemindarMessage] = useState<string>('')
  const [appointmentIds, setAppointmentIds] = useState<number[]>([]) // Using number type for IDs
  const [latestAppointmentId, setLatestAppointmentId] = useState<number>(0)
  const [results, setResults] = useState<{message: string; appointmentId: number}[] | []>([])
  const [viewResults, setViewResults] = useState<{message: string; appointmentId: number}[]>([])

  useEffect(() => {
    if (results) {
      // Filter out the elements from `results` that are already in `viewResults`
      const filteredResults = results.filter((item: any) => {
        return !viewResults.some((view: any) => view.appointmentId === item.appointmentId)
      })
      //Direct check without state mutation race condition
      if (filteredResults?.length > 0) {
        setIsOpenRemindarScheduleAppointmentModal(true)
        setRemindarMessage(filteredResults[0].message)
        setLatestAppointmentId(filteredResults[0].appointmentId)
      }
    }
  }, [results])

  const handleCloseModal = () => {
    setViewResults([...viewResults, {message: remindarMessage, appointmentId: latestAppointmentId}])
    setIsOpenRemindarScheduleAppointmentModal(false)
    setLatestAppointmentId(0)
  }

  useEffect(() => {
    // Create the SignalR connection
    if (!auth?.token) return
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_WEB_SOCKET_URL}/reminderHub`, {
        accessTokenFactory: () => auth?.token ?? '',
      }) // Make sure the URL matches the SignalR hub on the server
      .build()

    newConnection
      .start()
      .then(() => {
        console.log('SignalR Connected')
      })
      .catch((err: any) => console.log('SignalR Connection Error: ', err))

    setSignalRConnection(newConnection)

    // Listen for messages from the server
    newConnection.on('ReceiveReminder', async (message: string, user: string) => {
      const result = JSON.parse(message)
      if (result?.length > 0) setResults(result)
    })

    // Cleanup connection when the component is unmounted
    return () => {
      newConnection.stop()
    }
  }, [auth])

  return (
    <RemindarScheduleAppointmentModal
      show={isOpenRemindarScheduleAppointmentModal}
      onHide={() => handleCloseModal()}
      //onConfirm={() => handleChangeTimeZone()}
      onClose={() => handleCloseModal()}
      title={remindarMessage}
      appointmentId={latestAppointmentId}
    />
  )
}

export {RemindarSignalRHub}
