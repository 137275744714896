/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, sendLoginDetails} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useDeviceInfo} from './DeviceInfo'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const deviceInfo = useDeviceInfo()
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        if (auth.isAuthenticated === false) {
          setLoading(false)
          await sendLoginDetails(
            deviceInfo.deviceId,
            deviceInfo.deviceType,
            values.email,
            deviceInfo.ipAddress,
            deviceInfo.browser,
            deviceInfo.longitude,
            deviceInfo.latitude,
            deviceInfo.loginTimestamp,
            auth.isAuthenticated
          )
          setStatus(auth.message)
        } else {
          saveAuth(auth)
          const {data: user} = await getUserByToken()
          await sendLoginDetails(
            deviceInfo.deviceId,
            deviceInfo.deviceType,
            values.email,
            deviceInfo.ipAddress,
            deviceInfo.browser,
            deviceInfo.longitude,
            deviceInfo.latitude,
            deviceInfo.loginTimestamp,
            auth.isAuthenticated
          )
          setCurrentUser({...user, notNowTimeZoneChanging: false})
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='mb-11 mt-10'>
        <div className='d-flex align-items-end flex-warp'>
          <span
            className='text-dark fw-bold d-flex align-items-end'
            style={{fontSize: '28px', lineHeight: '30px'}}
          >
            Sign in to{' '}
          </span>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/signlogo.png')}
            className='ms-2 h-40px'
          />{' '}
        </div>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-semibold text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-semibold text-dark fs-6 mb-0'>Password</label>
        <div className='position-relative'>
          <input
            placeholder='Password'
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control  bg-transparent pr-10',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            style={{paddingRight: '2.5rem'}}
          />

          <i
            className={clsx('bi', showPassword ? 'bi-eye-slash' : 'bi-eye', 'position-absolute', {
              'd-none': formik.values.password === '',
            })}
            onClick={togglePasswordVisibility}
            style={{
              right: '35px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              fontSize: '1.25rem',
              color: '#555',
            }}
          ></i>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-dark'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn mt-3'
          style={{backgroundColor: '#D7383C', color: '#ffffff'}}
          disabled={formik.isSubmitting}
        >
          {!loading && <span className='indicator-label fw-bold fs-4'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/*  
      <Link to='/auth/registration'>
        <div className='d-flex justify-content-center align-items-center'>
          <p className='mb-0 text-gray-500'>Don't have an account?</p>
          <a
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='fw-bold ms-2'
            style={{ color: '#D7383C' }}
          >
            Sign Up
          </a>
        </div>
      </Link>*/}
    </form>
  )
}
