import React, {useEffect, useState} from 'react'
import {DashboardOverview} from './DashboardOverview'
import {useAuth} from '../../../app/modules/auth'
import {DashboardLogs} from './Logs/DashboardLogs'
import {DashboardAnalytics} from './DashboardAnalytics/DashboardAnalytics'
import {DashboardOperation} from './DashboardOperation/DashboardOperation'
import {useQuery} from 'react-query'
import {getTimeZone} from '../../UI/Common/components/core/_requests'
import {TimezoneConfirmationModal} from '../../common/Models/timezone-confirmation-modal/TimezoneConfirmationModal'
import axios from 'axios'
import toaster from '../../../Utils/toaster'
import {getUserByToken} from '../../modules/auth/core/_requests'
import {useDispatch} from 'react-redux'
import {fetchCustomers} from '../../redux/customerSlice'
import {fetchLanguages} from '../../redux/languagesSlice'
import {AnyAction, ThunkDispatch} from '@reduxjs/toolkit'
import {useLocation, useNavigate} from 'react-router-dom'

const moment = require('moment-timezone')
const API_URL = process.env.REACT_APP_API_URL

const DashboardMain: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {currentUser, setCurrentUser} = useAuth()
  const [activeTab, setActiveTab] = useState(currentUser?.result.userType === 'SYSTEM' ? 1 : 3)
  const [isOpenTimezoneConfirmationModal, setIsOpenTimezoneConfirmationModal] = useState(false)
  const [description, setDescription] = useState('')
  const [changeTimeZoneId, setChangeTimeZoneId] = useState(null)
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch()

  useEffect(() => {
    if (currentUser?.result?.userType === 'SYSTEM') {
      dispatch(fetchCustomers())
    }
    dispatch(fetchLanguages())
  }, [dispatch])

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    navigate(`/dashboard?tab=${tabNumber}`)
  }

  // Set the active tab based on the current location
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    let tabNumber = params.get('tab') // Get the value of 'tab' from the query string

    if (currentUser?.result?.userType === 'SYSTEM') {
      switch (tabNumber) {
        case '1':
          tabNumber = '1'
          break
        case '2':
          tabNumber = '2'
          break
        case '3':
          tabNumber = '3'
          break
        case '4':
          tabNumber = '4'
          break
        default:
          tabNumber = '1'
      }
    }

    if (currentUser?.result?.userType === 'CONSUMER') {
      switch (tabNumber) {
        case '3':
          tabNumber = '3'
          break
        case '4':
          tabNumber = '4'
          break
        default:
          tabNumber = '3'
      }
    }

    if (currentUser?.result?.userType === 'INTERPRETER') {
      switch (tabNumber) {
        case '3':
          tabNumber = '3'
          break
        case '4':
          tabNumber = '4'
          break
        default:
          tabNumber = '3'
      }
    }

    if (tabNumber && currentUser?.result?.userType) {
      setActiveTab(parseInt(tabNumber))
    }
  }, [location.search])

  // Get the current time with the system's time zone
  const currentTime = moment()

  // Get the time zone offset in minutes
  const offsetMinutes = currentTime.utcOffset()

  // Convert the offset to hours and minutes
  const hours = Math.floor(Math.abs(offsetMinutes) / 60)
  const minutes = Math.abs(offsetMinutes) % 60
  const sign = offsetMinutes >= 0 ? '+' : '-'

  // Format the offset as +HH:mm with leading zero for hours if needed
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedOffset = `UTC${sign}${formattedHours}:${formattedMinutes}`

  const {isFetching: isFetchingTimeZone, data: timezoneList} = useQuery(
    `Query.timezoneList`,
    () => {
      return getTimeZone()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  function extractUtcOffset(description: string | undefined) {
    // Define a regular expression to extract the UTC offset
    const regex = /\(UTC([+-]\d{2}:\d{2})\)/
    const match = description?.match(regex)

    if (match) {
      // Return the formatted UTC offset
      return `UTC${match[1]}`
    } else {
      // Handle the case where the offset is not found
      return 'Invalid format'
    }
  }

  const defaultTimeZone = extractUtcOffset(currentUser?.result?.defaultTimeZone)

  useEffect(() => {
    if (formattedOffset && defaultTimeZone && timezoneList?.data) {
      if (formattedOffset !== defaultTimeZone && currentUser?.notNowTimeZoneChanging === false) {
        const currentTimeZone: any = timezoneList?.data?.find(
          (x: any) => x.identification == formattedOffset
        )
        setChangeTimeZoneId(currentTimeZone?.code)
        setDescription(
          `We noticed your computer to ${currentTimeZone?.name}.Would you like to update your Adastra time zone and meeting hours to match?`
        )
        setIsOpenTimezoneConfirmationModal(true)
      }
    }
  }, [formattedOffset, defaultTimeZone, timezoneList])

  const handleChangeTimeZone = async () => {
    try {
      const {data} = await axios.put(
        `${API_URL}/accounts/update-default-timezone/${changeTimeZoneId}`
      )
      if (data?.status == 'S') {
        toaster('success', data?.text)
        const {data: user} = await getUserByToken()
        setCurrentUser({...user, notNowTimeZoneChanging: false})
      } else if (data?.status == 'E') {
        toaster('error', data?.text)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsOpenTimezoneConfirmationModal(false)
    }
  }

  const handleNotNowButton = async () => {
    const {data: user} = await getUserByToken()
    setCurrentUser({...user, notNowTimeZoneChanging: true})
  }

  return (
    <>
      <div className='mb-5'>
        <p className='text-gray-500 fs-5 mb-2'>Welcome back, {currentUser?.result.firstName}</p>
        <h1 className='mb-0 fw-bold' style={{fontSize: '25px', lineHeight: '32px'}}>
          Dashboard
        </h1>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            {currentUser?.result.userType == 'SYSTEM' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400   ${
                    activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_1'
                  onClick={() => handleTabClick(1)}
                >
                  Operations
                </a>
              </li>
            )}
            {currentUser?.result.userType == 'SYSTEM' && (
              <li className='nav-item'>
                <a
                  className={`nav-link fs-7 text-gray-400   ${
                    activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                  }`}
                  data-bs-toggle='tab'
                  href='#kt_tab_pane_2'
                  onClick={() => handleTabClick(2)}
                >
                  Analytics
                </a>
              </li>
            )}

            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400   ${
                  activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_3'
                onClick={() => handleTabClick(3)}
              >
                Overview
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 4 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_4'
                onClick={() => handleTabClick(4)}
              >
                Logs
              </a>
            </li>
          </ul>
        </div>
        <div className=''>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              {activeTab === 1 && currentUser?.result.userType == 'SYSTEM' && (
                <div className='card-body pt-2'>
                  <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                    <DashboardOperation />
                  </div>
                </div>
              )}
              {activeTab === 2 && currentUser?.result.userType == 'SYSTEM' && (
                <div className='card-body pt-2'>
                  <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                    <DashboardAnalytics />
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className='card-body pt-2'>
                  <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                    <DashboardOverview />
                  </div>
                </div>
              )}
              {activeTab === 4 && (
                <div className='card-body pt-2'>
                  <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
                    <DashboardLogs className={''} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <TimezoneConfirmationModal
          show={isOpenTimezoneConfirmationModal}
          onHide={() => setIsOpenTimezoneConfirmationModal(false)}
          onConfirm={() => handleChangeTimeZone()}
          onClose={() => handleNotNowButton()}
          title={description}
        />
      </div>
    </>
  )
}

export {DashboardMain}
