import React, {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import './style.css'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'

interface Props {
  languages: any
  serviceTypes?: any
  communicationTypes?: any
  fetchAllCustomerRate?: any
  filterValues?: any
  setFilterValues?: any
}

const RateCustomerFilterDropdown: React.FC<Props> = ({
  languages,
  serviceTypes,
  communicationTypes,
  fetchAllCustomerRate,
  setFilterValues,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const initialValues = {
    fK_LanguageFrom: 'EN',
    fK_LanguageTo: [],
    fK_ServiceType: [],
    fK_CommunicationType: [],
  }

  const createRateSchema = Yup.object().shape({
    fK_LanguageFrom: Yup.string(),
    fK_LanguageTo: Yup.array().test(
      'not-same',
      'Language from and language to must be different',
      function (value) {
        const {fK_LanguageFrom} = this.parent
        return value !== fK_LanguageFrom
      }
    ),
    fK_ServiceType: Yup.array(),
    fK_CommunicationType: Yup.array(),
  })

  return (
    <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
      <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
        <Dropdown.Toggle
          variant='primary'
          id='dropdown-basic'
          className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-danger' : ''}`}
        >
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
        </Dropdown.Toggle>
      </OverlayTrigger>

      <Dropdown.Menu className='p-4 w-400px' style={{minHeight: '100px'}}>
        <div className='px-4 pb-4'>
          <div className='fs-4 text-dark fw-semibolder'>Filter</div>
        </div>

        <div className='separator border-gray-200'></div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={createRateSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true)
            try {
              setFilterValues(values)
              fetchAllCustomerRate(values)
              setShowDropdown(false)
              setIsFilterApplied(true)
            } catch (ex) {
              console.error(ex)
            } finally {
              setSubmitting(true)
            }
          }}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            resetForm,
            values,
            errors,
            touched,
            isValid,
            dirty,
            ...formik
          }) => (
            <Form>
              <div className='px-4 mt-4'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='mb-3'>
                      <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                        From Language
                      </label>
                      <Select
                        {...formik.getFieldProps('fK_LanguageFrom')}
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={languages.map((item: any) => ({
                          value: item.key,
                          label: item.value,
                        }))}
                        placeholder='Select'
                        value={{
                          value: values.fK_LanguageFrom,
                          label:
                            languages.find((x: any) => x.key === values.fK_LanguageFrom)?.value ||
                            'Select From Language',
                        }}
                        onChange={(selectedOption: any) => {
                          setFieldValue('fK_LanguageFrom', selectedOption?.value)
                        }}
                        onBlur={(e) => setFieldTouched('fK_LanguageFrom', true)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                      {errors.fK_LanguageFrom && touched?.fK_LanguageFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{String(errors.fK_LanguageFrom ?? '')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='mb-3'>
                      <div className='d-flex justify-content-between'>
                        <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                          To Language(s)
                        </label>
                        <div className='col-md-2 d-flex justify-content-end align-items-end mb-1'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexRadioLg0'
                              checked={
                                values.fK_LanguageTo?.length === languages?.length ? true : false
                              }
                              onChange={() =>
                                setFieldValue(
                                  'fK_LanguageTo',
                                  values.fK_LanguageTo?.length !== languages?.length
                                    ? languages?.map((x: any) => ({
                                        value: x.key,
                                        label: x.value,
                                      })) || []
                                    : []
                                )
                              }
                              onBlur={(e) => setFieldTouched('fK_LanguageTo', true)}
                            />
                            <label className='form-check-label fs-8' htmlFor='flexRadioLg0'>
                              All
                            </label>
                          </div>
                        </div>
                      </div>

                      <Select
                        {...formik.getFieldProps('fK_LanguageTo')}
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={languages.map((item: any) => ({
                          value: item.key,
                          label: item.value,
                        }))}
                        placeholder='Select To Language(s)'
                        value={values.fK_LanguageTo}
                        onChange={(selectedOption: any) => {
                          setFieldValue('fK_LanguageTo', selectedOption)
                        }}
                        onBlur={(e) => setFieldTouched('fK_LanguageTo', true)}
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            maxHeight: '300px',
                            overflow: 'auto',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                      {errors.fK_LanguageTo && touched?.fK_LanguageTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.fK_LanguageTo}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='mb-3'>
                      <div className='d-flex justify-content-between'>
                        <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                          Communication Type(s)
                        </label>
                        <div className='col-md-2 d-flex justify-content-end align-items-end  mb-1'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexRadioLg1'
                              checked={
                                values.fK_CommunicationType?.length === communicationTypes?.length
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                setFieldValue(
                                  'fK_CommunicationType',
                                  values.fK_CommunicationType?.length !== communicationTypes?.length
                                    ? communicationTypes?.map((x: any) => ({
                                        value: x.key,
                                        label: x.value,
                                      })) || []
                                    : []
                                )
                              }
                              onBlur={(e) => setFieldTouched('fK_CommunicationType', true)}
                            />
                            <label className='form-check-label fs-8' htmlFor='flexRadioLg1'>
                              All
                            </label>
                          </div>
                        </div>
                      </div>

                      <Select
                        {...formik.getFieldProps('fK_CommunicationType')}
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        placeholder='Select Communication Type(s)'
                        isMulti
                        options={communicationTypes.map((item: any) => ({
                          value: item.key,
                          label: item.value,
                        }))}
                        value={values.fK_CommunicationType}
                        onChange={(selectedOption: any) => {
                          setFieldValue('fK_CommunicationType', selectedOption)
                        }}
                        onBlur={(e) => setFieldTouched('fK_CommunicationType', true)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            maxHeight: '300px',
                            overflow: 'auto',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                      {errors.fK_CommunicationType && touched?.fK_CommunicationType && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.fK_CommunicationType}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='mb-3'>
                      <div className='d-flex justify-content-between'>
                        <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                          Service Type(s)
                        </label>
                        <div className='col-md-2 d-flex justify-content-end align-items-end mb-1'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm '>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexRadioLg2'
                              checked={
                                values.fK_ServiceType?.length === serviceTypes?.length
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                setFieldValue(
                                  'fK_ServiceType',
                                  values.fK_ServiceType?.length !== serviceTypes?.length
                                    ? serviceTypes?.map((x: any) => ({
                                        value: x.key,
                                        label: x.value,
                                      })) || []
                                    : []
                                )
                              }
                            />
                            <label className='form-check-label fs-8' htmlFor='flexRadioLg2'>
                              All
                            </label>
                          </div>
                        </div>
                      </div>

                      <Select
                        {...formik.getFieldProps('fK_ServiceType')}
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={serviceTypes.map((item: any) => ({
                          value: item.key,
                          label: item.value,
                        }))}
                        placeholder='Select Service Type(s)'
                        value={values.fK_ServiceType}
                        onChange={(selectedOption: any) => {
                          setFieldValue('fK_ServiceType', selectedOption)
                        }}
                        onBlur={(e) => setFieldTouched('fK_ServiceType', true)}
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            maxHeight: '300px',
                            overflow: 'auto',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                      {errors.fK_ServiceType && touched?.fK_ServiceType && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{errors.fK_ServiceType}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-end pt-3 px-4'>
                <button
                  type='reset'
                  className='btn btn-sm btn-light me-2'
                  data-kt-menu-dismiss='true'
                  onClick={() => {
                    resetForm()
                    fetchAllCustomerRate({
                      fK_LanguageFrom: null,
                      fK_LanguageTo: [],
                      fK_ServiceType: [],
                      fK_CommunicationType: [],
                    })
                    setShowDropdown(false)
                    setIsFilterApplied(false)
                  }}
                >
                  Reset
                </button>

                <button
                  className='btn btn-sm btn-primary'
                  data-kt-menu-dismiss='true'
                  disabled={isSubmitting}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Apply
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default RateCustomerFilterDropdown
