import {Form, Formik} from 'formik'
import {useRef} from 'react'
import * as Yup from 'yup'
import {KTSVG} from '../_metronic/helpers'

const uploadFile = Yup.object().shape({
  file: Yup.mixed().nullable().required('required'),
})

export const BulkRateUpload = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add_bulk_acc_rate'>
        <div className='modal-dialog' role='dialog'>
          <Formik
            initialValues={{file: null}}
            validationSchema={uploadFile}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                console.log('File uploaded:', values.file)
              } catch (error) {
                console.error('Error uploading file:', error)
              } finally {
                resetForm()
                if (fileInputRef.current) {
                  fileInputRef.current.value = ''
                }
                setSubmitting(false)
              }
            }}
          >
            {({setFieldValue, values, errors, isValid, touched, isSubmitting, resetForm}) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Bulk Rate Upload</h4>
                    <button
                      type='button'
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        resetForm()
                        if (fileInputRef.current) {
                          fileInputRef.current.value = ''
                        }
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </button>
                  </div>

                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-12 mb-2'>
                        <label className='required form-label'>Upload CSV file</label>
                        <label
                          htmlFor='upload-import-order-file'
                          style={{width: '100%', height: '100%'}}
                        >
                          <div className='fv-row'>
                            <div
                              className='dropzone d-flex flex-column align-items-center justify-content-center'
                              id='kt_dropzonejs_example_1'
                              style={{minHeight: '80px'}}
                            >
                              <div className='dz-message needsclick'>
                                <i className='ki-duotone ki-file-up fs-3x text-primary'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>

                                <div className='ms-4 d-flex flex-column align-items-center justify-content-center'>
                                  <KTSVG
                                    path='/media/icons/duotune/files/fil022.svg'
                                    className='svg-icon svg-icon-3x svg-icon-primary'
                                  />
                                  <h3 className='fs-6 fw-semibold text-gray-600 mb-1'>
                                    Drop CSV file here or click to upload.
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input
                            hidden
                            id='upload-import-order-file'
                            aria-label="Upload CSV file"
                            name='file'
                            ref={fileInputRef}
                            type='file'
                            accept='.csv'
                            onChange={(e) => {
                              const file = e.target.files?.[0]
                              setFieldValue('file', file)
                            }}
                          />
                          {values.file && <p>{(values.file as File).name}</p>}
                          {errors.file && touched.file && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.file}</span>
                              </div>
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='modal-footer py-3'>
                    <button
                      type='button'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => {
                        resetForm()
                        if (fileInputRef.current) {
                          fileInputRef.current.value = ''
                        }
                      }}
                    >
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary btn-sm'
                      disabled={isSubmitting || !isValid}
                    >
                      Upload File
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
